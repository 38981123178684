import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import BackgroundImage from "../../assets/images/login-background.png";
import { baseConfig } from "./../../axiosConfig";
import * as base from "./../../axiosConfig";
import axios from "axios";
import { Context } from "./../../contexts/Store";
import AlertBox from "../includes/AlertBox";

export default function New() {
	const [hide, setHide] = useState(false);
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const { dispatch } = useContext(Context);

	//alert states
	const [alertStatus, setAlertStatus] = useState("");
	const [alertMessage, setAlertMessage] = useState("");
	const [isAlert, setIsAlert] = useState(false);

	// error props object
	let propsObject = {
		status: alertStatus,
		isAlert: isAlert,
		setIsAlert: setIsAlert,
		setAlertStatus: setAlertStatus,
		alertMessage: alertMessage,
	};

	//active errors
	const activeError = (status, message) => {
		setAlertStatus(status);
		setAlertMessage(message);
		setIsAlert(true);
	};

	const handlePasswordShow = () => {
		setHide(!hide);
	};

	// submit login api
	const handleLogin = () => {
		baseConfig
			.post("/auth/login/", {
				username: username,
				password: password,
			})
			.then((response) => {
				let { StatusCode, message, data } = response.data;
				if (StatusCode == 6000) {
					const user_details = {
						is_verified: true,
						access_token: data.access,
						role: data.role,
					};
					dispatch({
						type: "UPDATE_USER",
						user_details,
					});
				} else {
					// alert(message);
					activeError("error", message);
				}
			})
			.catch((error) => {});
	};

	const handleKeyDown = (event) => {
		if (event.key === "Enter") {
			// handleLogin();
		}
	};

	const handleEnter = (event) => {
		if (event.key.toLowerCase() === "enter") {
			const form = event.target.form;
			const index = [...form].indexOf(event.target);
			form.elements[index + 1].focus();
			event.preventDefault();
		}
	};

	return (
		<>
			<AlertBox propsObject={propsObject} />
			<Container>
				<LoginContainer>
					<LogoImage
						src={require("../../assets/images/logo.svg").default}
					/>
					<Heading>Login to Your Account</Heading>
					{/* <UsernameContainer>
					<InputField
						placeholder="User Name"
						font-family="poppinsregular"
						onChange={(value) => {
							setUsername(value.target.value);
						}}
					/>
				</UsernameContainer>
				<PasswordContainer>
					<InputField
						placeholder="Password"
						type={hide ? "password" : "text"}
						onChange={(value) => {
							setPassword(value.target.value);
						}}
					/>
					<ShowIcon
						src={require("../../assets/icons/showIcon.png").default}
						onClick={() => {
							handlePasswordShow();
						}}
					/>
				</PasswordContainer> */}
					<form>
						<InputField
							placeholder="User Name"
							font-family="poppinsregular"
							onChange={(value) => {
								setUsername(value.target.value);
							}}
							onKeyDown={handleEnter}
							autoFocus
						/>
						<InputField
							placeholder="Password"
							type={hide ? "text" : "password"}
							font-family="poppinsregular"
							onChange={(value) => {
								setPassword(value.target.value);
							}}
						/>
						<CheckContainer>
							<Checkbox
								type="checkbox"
								onChange={() => setHide(!hide)}
								defaultChecked={hide}
								// inputProps={{ 'aria-label': 'Checkbox A' }}
							/>
							<ReminderText>Show password</ReminderText>
						</CheckContainer>
						<Button
							onClick={(e) => {
								e.preventDefault();
								if (username === "") {
									activeError("error", "Enter Username");
								} else if (password === "") {
									activeError("error", "Enter Password");
								} else {
									handleLogin();
								}
							}}
						>
							Login
						</Button>
					</form>
				</LoginContainer>
			</Container>
		</>
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 60px 0;
	height: 100%;
	min-height: 100vh;
	background-image: url(${BackgroundImage});
	justify-content: center;
	background-size: 100% 100%;
	background-repeat: no-repeat;
`;
const LoginContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
`;
const LogoImage = styled.img`
	width: 100px;
	height: 100px;
	margin-bottom: 50px;
`;
const Heading = styled.h2`
	font-size: 22px;
	font-family: "product_sansbold";
	margin-bottom: 20px;
	color: #fff;
`;
const UsernameContainer = styled.span`
	background-color: #222222;
	width: 100%;
	// padding: 20px 20px;
	margin-bottom: 15px;
	border-radius: 10px;
`;
const PasswordContainer = styled.span`
	background-color: #222222;
	width: 100%;
	// padding: 20px 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
	border-radius: 10px;
`;
const InputField = styled.input`
	font-family: "poppinsregular";
	border: none;
	outline: none;
	width: 100%;
	background-color: #222222;
	color: #fbfbfb;
	// background: red;
	height: 60px;
	margin-bottom: 20px;
	border-radius: 6px;
	padding: 0 10px;
	border: 1px solid #222222;
	font-size: 16px;
	line-height: 70px;
	&:focus {
		border: 1px solid #4e4e4e;
	}
`;
const ShowIcon = styled.img`
	height: 10px;
	cursor: pointer;
`;
const CheckContainer = styled.span`
	width: 100%;
	display: flex;
	align-items: center;
	margin-bottom: 10px;
`;
const Checkbox = styled.input`
	margin-right: 10px;
`;
const ReminderText = styled.h6`
	font-family: "poppinsregular";
	margin-bottom: -3px;
	font-size: 14px;
	color: #fff;
`;
const Button = styled.button`
	font-family: "poppinsmedium";
	width: 100%;
	background-color: black;
	text-align: center;
	padding: 20px 30px;
	border-radius: 10px;
	text-decoration: none;
	background-image: linear-gradient(to right, #00efd1, #00acea);
	font-weight: 500;
	font-size: 14px;
	cursor: pointer;
`;
