import React, { useState } from "react";
import styled from "styled-components";
import onClickOutside from "react-onclickoutside";

function LateralityDropDown({
	title,
	items,
	multiSelect = false,
	setSelectedValue,
	selectedValue,
	selectedModality,
	activeError,
}) {
	const [open, setOpen] = useState(false);
	const toggle = () => setOpen(!open);
	LateralityDropDown.handleClickOutside = () => setOpen(false);

	return (
		<>
			{/* <Top style={{ marginBottom: "10px" }}>
				<Modality
					onClick={() => {
						toggle(!open);
					}}
				>
					<Text>
						{selectedLaterality?.name
							? selectedLaterality.name
							: `Select Modality`}
					</Text>
					<Arrow
						src={
							require("./../../assets/icons/arrow-down.svg")
								.default
						}
						alt="arrow"
					/>
				</Modality>
				{open && (
					<ModalityOption active={open}>
						{items.map((data) => (
							<Option
								key={data.id}
								onClick={() => {
									setSelectedModality(data);
									setOpen(false);
								}}
							>
								{data.name}
							</Option>
						))}
					</ModalityOption>
				)}
			</Top> */}

			<Top>
				<Modality
					// style={{
					// 	cursor: isLaterality ? "pointer" : "not-allowed",
					// }}
					onClick={() => {
						if (selectedModality.id === 0) {
							activeError("warning", "Select Modality");
						} else {
							toggle(!open);
						}
					}}
				>
					{selectedValue.category === "unilateral" ? (
						<Text>Unilateral :{selectedValue.value}</Text>
					) : selectedValue.category === "bilateral" ? (
						<Text>{selectedValue.category}</Text>
					) : (
						<Text>Select Laterality</Text>
					)}
					<Arrow
						src={
							require("./../../../assets/icons/arrow-down.svg")
								.default
						}
						alt="arrow"
						active={open}
					/>
				</Modality>

				{open && (
					<LateralityOption active={open}>
						{items.map((data) => (
							<div key={data.id}>
								<SelectLateralityContainer
									onClick={() => {
										// setSelectedLateralityName(
										// 	`${data.option}`
										// );
										// if (data.option === "bilateral") {
										// 	setSelectedUnilateral("");
										// }
									}}
								>
									<div
										style={{
											display: "flex",
											marginTop: "15px",
											alignItems: "center",
										}}
										onClick={() => {
											// if (
											// 	selectedLateralityName ===
											// 	"unilateral"
											// ) {
											// 	// toggleActiveLaterality();
											// }

											setSelectedValue((prevState) => ({
												...prevState,
												value: selectedValue.value
													? selectedValue.value
													: "left",
												category: data.option,
											}));
										}}
									>
										<RadioButtonChecked>
											{selectedValue.category ===
												data.option && <InnerCircle />}
										</RadioButtonChecked>
										<OptionText>{data.option}</OptionText>
									</div>
									{selectedValue.category ===
										"unilateral" && (
										<InnerDiv>
											{data.inner?.map((item, i) => (
												<InnerSubDiv
													key={i}
													onClick={() => {
														setSelectedValue(
															(prevState) => ({
																...prevState,
																value: item.option,
															})
														);
													}}
												>
													<RadioButtonChecked>
														{selectedValue.value ===
															item.option && (
															<InnerCircle />
														)}
													</RadioButtonChecked>
													<OptionText>
														{item.option}
													</OptionText>
												</InnerSubDiv>
											))}
										</InnerDiv>
									)}
									{selectedValue.category === data.option && (
										<TextArea
											value={selectedValue.text}
											onChange={(e) => {
												setSelectedValue(
													(prevState) => ({
														...prevState,
														text: e.target.value,
													})
												);
											}}
											placeholder="Write Here..."
										/>
									)}
								</SelectLateralityContainer>
							</div>
						))}

						{/* <AdditionalView></AdditionalView> */}
					</LateralityOption>
				)}
			</Top>
		</>
	);
}

const clickOutsideConfig = {
	handleClickOutside: () => LateralityDropDown.handleClickOutside,
};

export default onClickOutside(LateralityDropDown, clickOutsideConfig);

const Top = styled.div`
	display: flex;
	flex-direction: Column;
	/* padding: 0 10px; */
	background: #171617;
	border-radius: 8px;
	position: relative;
`;
const Modality = styled.div`
	height: 50px;
	background: #1c1c1c;
	color: #a1a1a1;
	font-size: 14px;
	border: none;
	border-radius: 8px;
	margin-bottom: 10px;
	padding: 10px;
	align-items: center;
	display: flex;
	justify-content: space-between;
	cursor: pointer;
	@media (max-width: 1280px) {
		font-size: 13px;
	}
`;
const Text = styled.h4`
	font-size: 15px;
	font-family: "poppinsregular";
	text-transform: capitalize;
	@media (max-width: 1280px) {
		font-size: 14px;
	}
`;
const Arrow = styled.img`
	transform: ${(props) => (props.active ? `rotate(180deg)` : `rotate(0deg)`)};
`;

const ModalityOption = styled.div`
	background-color: #333333;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: absolute;
	top: 60px;
	width: 100%;
	z-index: 60;
	visibility: ${(props) => (props.active ? "visible" : "hidden")};
`;
const Option = styled.h4`
	color: #fff;
	font-size: 15px;
	font-family: "poppinsregular";
	height: 50px;
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 0 15px;
	:hover {
		background-color: #424242;
	}
	:first-child {
		border-top-left-radius: 12px;
		border-top-right-radius: 12px;
	}
	:last-child {
		border-bottom-left-radius: 12px;
		border-bottom-right-radius: 12px;
	}
	@media (max-width: 1280px) {
		font-size: 14px;
	}
`;

const LateralityOption = styled.div`
	padding: 10px 20px;
	background-color: #333333;
	border-radius: 12px;
	margin-bottom: 10px;
	user-select: none;
	position: absolute;
	width: 100%;
	z-index: 10;
	top: 60px;
	visibility: ${(props) => (props.active ? "visible" : "hidden")};
`;
const SelectLateralityContainer = styled.div`
	// display: flex;
	margin-bottom: 10px;
	cursor: pointer;
	:last-child {
		margin-bottom: 0;
	}
`;
const RadioButtonChecked = styled.div`
	background-color: #203035;
	width: 16px;
	height: 16px;
	min-width: 16px;
	min-height: 16px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
`;
const InnerCircle = styled.div`
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: #00ace2;
`;
const OptionText = styled.h6`
	color: #a3a3a3;
	font-size: 15px;
	text-transform: capitalize;
	margin-bottom: -3px;
`;

const TextArea = styled.textarea`
	border: 1px solid #5d5c5d;
	border-radius: 8px;
	padding: 10px;
	width: 100%;
	resize: vertical;
	margin-bottom: 10px;
	color: #fff;
	min-height: 100px;
	::placeholder {
		font-family: "poppinsregular";
		color: #4d595d;
	}
`;

const InnerDiv = styled.div`
	display: flex;
	margin-left: 30px;
	/* margin-top: 5px; */
	align-items: center;
	margin-bottom: 5px;
`;
const InnerSubDiv = styled.div`
	display: flex;
	margin-left: 30px;
	margin-top: 5px;
	align-items: center;
	margin-bottom: 5px;
`;
