import React, { useState, useRef, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OptionModal from "../../includes/OptionModal";
import { baseConfig } from "./../../../axiosConfig";
import { Context } from "./../../../contexts/Store";
import styles from "./styles.css";
import LateralityModal from "../../includes/LateralityModal";
import { useDetectOutsideClick } from "./../../includes/functions/DetectOutsideClick";
import DropIndex from "../../includes/DropIndex";
import Dropdown from "../../includes/Dropdown";
import LateralityDropDown from "../../includes/home/LateralityDropDown";

export default function PrimaryLeftContainer({
	report_Id,
	patientPersonalData,
	activeError,
}) {
	//ref
	const lateralityRef = useRef(null);
	const protocolRef = useRef(null);
	const pathologyRef = useRef(null);

	//modality
	const [modalityItem, setModalityItem] = useState([]);
	const [selectedModality, setSelectedModality] = useState({
		name: "Select Modality",
		id: 0,
	});

	//laterality
	const [selectedLaterality, setSelectedLaterality] = useState({
		name: "Select Laterality",
		id: 0,
		value: "",
		category: "",
		text: "",
	});

	const history = useHistory();
	const [activeSingleImage, setActiveSingleImage] = useState(true);
	const [selectedSingleImage, setSelectedSingleImage] = useState();
	const [activeIndex, setActiveIndex] = useState(0);
	const { state } = useContext(Context);
	const [protocolItem, setProtocolItem] = useState([]);
	const [lateralityItem, setLateralityItem] = useState([]);
	const [pathologyItem, setPathologyItem] = useState([]);
	const [bodyPart, setBodyPart] = useState([]);

	//is condition

	const [isLaterality, setLaterality] = useState(false);
	const [isProtocol, setProtocol] = useState(false);
	const [isPathology, setPathology] = useState(false);

	//

	const [selectedModalityID, setSelectedModalityID] = useState("");
	const [activeOption, setActiveOption] = useState(false);

	const [selectedProtocol, setSelectedProtocol] = useState(
		"Select Study Protocol"
	);
	const [selectedProtocolID, setSelectedProtocolID] = useState("");
	const [selectedBodyPartID, setSelectedBodyPartID] = useState("");
	const [selectedLateralityID, setSelectedLateralityID] = useState("");
	const [selectedPathologyID, setSelectedPathologyID] = useState("");
	const [activeProtocol, setActiveProtocol] = useState(false);
	const [selectedBodyPart, setSelectedBodyPart] = useState({
		id: 0,
	});

	// const [selectedLaterality, setSelectedLaterality] =
	// 	useState("Select Laterality");
	const [activeLaterality, setActiveLaterality] = useState(false);

	const [selectedPathology, setSelectedPathology] =
		useState("Select Pathology");
	const [activePathology, setActivePathology] = useState(false);

	const toggleActiveOption = () => {
		// setActiveOption(!activeOption);
		setActiveOption((prevState) => !prevState);
	};
	const toggleActiveProtocol = () => {
		setActiveProtocol((prevState) => !prevState);
	};
	const toggleActiveLaterality = () => {
		setActiveLaterality((prevState) => !prevState);
	};
	const toggleActivePathology = () => {
		setActivePathology((prevState) => !prevState);
	};

	//Laterality
	const [selectedLateralityName, setSelectedLateralityName] =
		useState("Select Laterality");
	const [selectedUnilateral, setSelectedUnilateral] = useState("");
	const [lateralityText, setLateralityText] = useState("");

	//modality drop down

	const handleClickOutside = (event) => {
		// if (
		// 	modalityRef.current &&
		// 	!modalityRef.current.contains(event.target)
		// ) {
		// 	toggleActiveOption();
		// 	// setActiveOption(true)
		// }

		if (
			lateralityRef.current &&
			!lateralityRef.current.contains(event.target)
		) {
			toggleActiveLaterality();
		}
		if (
			protocolRef.current &&
			!protocolRef.current.contains(event.target)
		) {
			toggleActiveProtocol();
		}
		if (
			pathologyRef.current &&
			!pathologyRef.current.contains(event.target)
		) {
			toggleActivePathology();
		}
	};

	useEffect(() => {
		// document.addEventListener("mouseup", handleClickOutside);
		// document.addEventListener("touchend", handleClickOutside);
		// return () => {
		// 	document.removeEventListener("mouseup", handleClickOutside);
		// 	document.removeEventListener("touchend", handleClickOutside);
		// };
	}, []);

	const toggleActiveBatch = () => {
		// setActiveBatch(!activeBatch);
		// setActiveBatch((prevState) => !prevState);
	};

	//Api

	const getModality = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.get("/dashboard/modality/", {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setModalityItem(data);
				} else {
				}
			})
			.catch((error) => {});
	};

	const getBodyParts = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`/dashboard/body-parts/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
				params: {
					m_pk: selectedModality.id,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setBodyPart(data);
				} else {
				}
			})
			.catch((error) => {});
	};

	const getLaterality = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.get("/dashboard/laterality/", {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setLateralityItem(data);
				} else {
				}
			})
			.catch((error) => {});
	};

	const handleStudyProtocol = (bodyPk) => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.post(
				"/dashboard/protocols/",
				{
					b_pk: bodyPk,
					m_pk: selectedModality?.id,
				},
				{
					headers: {
						Authorization: "Bearer " + accessToken,
					},
				}
			)
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setProtocolItem(data);
				} else {
				}
			})
			.catch((error) => {});
	};

	const handlePathology = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.post(
				"/dashboard/pathology/",
				{
					b_pk: selectedBodyPartID,
					p_pk: selectedProtocolID,
				},
				{
					headers: {
						Authorization: "Bearer " + accessToken,
					},
				}
			)
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setPathologyItem(data);
				} else {
				}
			})
			.catch((error) => {});
	};

	const loadSynopticTemplate = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.post(
				"/dashboard/synoptic-template/",
				{
					b_pk: selectedBodyPartID,
					p_pk: selectedProtocolID,
					report_id: report_Id,
					m_pk: selectedModality?.id,
					pathology_pk: selectedPathologyID,
					laterality_option:
						selectedLaterality.category === "unilateral"
							? selectedLaterality.value
							: selectedLaterality.category,
					laterality_text: selectedLaterality.text,
					// protocol_text:
				},
				{
					headers: {
						Authorization: "Bearer " + accessToken,
					},
				}
			)
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					// setPathologyItem(data);
					// history.push(`/synoptic-report/${report_Id}/${data.mammogram_id}/`, {
					// 	state: {
					// 		report: report_Id,
					// 		data: response.data.data,
					// 		patientPersonalData: patientPersonalData,
					// 	},
					// });
					history.push(
						`/synoptic-report/${data.template}/${report_Id}/${data.mammogram_id}/`
					);
				} else {
				}
			})
			.catch((error) => {});
	};

	useEffect(() => {
		getModality();
		getBodyParts();
		getLaterality();

		// handlePathology();
	}, []);

	const arraySize = 9;
	const bodyPartGroups = bodyPart
		.map((e, i) => {
			return i % arraySize === 0
				? bodyPart.slice(i, i + arraySize)
				: null;
		})
		.filter((e) => {
			return e;
		});

	// slick

	let slider = useRef();

	const next = () => {
		slider.slickNext();
	};
	const previous = () => {
		slider.slickPrev();
	};

	var settings = {
		// dots: true,
		infinite: true,
		speed: 800,
		slidesToShow: 1,
		slidesToScroll: 1,
		afterChange: (indexOfCurrentSlide) => {
			setActiveIndex(indexOfCurrentSlide);
		},
	};

	useEffect(() => {
		if (selectedModality?.id) {
			getBodyParts();
		}
		if (selectedBodyPartID !== "" && selectedProtocolID !== "") {
			handlePathology();
		}
	}, [selectedModality, selectedBodyPartID, selectedProtocolID]);

	useEffect(() => {
		if (bodyPart.length === 1) {
			bodyPart.map(
				(data) => (
					setSelectedSingleImage(data.image),
					setActiveSingleImage(false),
					setLaterality(data.is_laterality),
					handleStudyProtocol(data.id),
					setSelectedBodyPartID(data.id),
					setSelectedBodyPart(data)
				)
			);
		}
	}, [bodyPart]);

	const [primaryLaterality, setPrimaryLaterality] = useState([
		{
			id: 1,
			option: "unilateral",
			inner: [
				{
					id: 4,
					option: "left",
				},
				{
					id: 5,
					option: "right",
				},
			],
		},
		{
			id: 2,
			option: "bilateral",
		},
	]);

	return (
		<LeftDetailsContainer>
			{/* <DropIndex /> */}
			<Dropdown
				items={modalityItem}
				setSelectedModality={setSelectedModality}
				selectedModality={selectedModality}
			/>
			{/* <Top>
				<Modality
					onClick={() => {
						toggleActiveOption();
					}}
				>
					<Text>{selectedModality}</Text>
					<Arrow
						src={
							require("./../../../assets/icons/arrow-down.svg")
								.default
						}
						alt="arrow"
					/>
				</Modality>
				{activeOption && (
					<ModalityOption active={activeOption} ref={modalityRef}>
						{modalityItem.map((data) => (
							<Option
								key={data.id}
								onClick={() => {
									// toggleActiveOption();
									setSelectedModality(
										`Modality : ${data.name}`
									);
									setSelectedModalityID(data.id);
									setSelectedProtocol(
										"Select Study Protocol"
									);
									setSelectedPathology("Select Pathology");
									setProtocol(data.is_protocol);
									setSelectedLateralityName(
										"Select Laterality"
									);
								}}
							>
								{data.name}
							</Option>
						))}
					</ModalityOption>
				)}
			</Top> */}
			{/* <ModalityDiv>
				<SelectModality>
					<OptionModality>haaia</OptionModality>
					<OptionModality>jjjjj</OptionModality>
				</SelectModality>
			</ModalityDiv> */}
			<Middle>
				<BodyPartSlider>
					<BodyTitle>
						<TemplateType>SELECT BODY PART</TemplateType>
						{activeSingleImage ? (
							<SpanBox>
								{bodyPartGroups.map((item, i) => (
									<Span
										style={{
											width: activeIndex === i ? 25 : 10,
											background:
												activeIndex === i &&
												"linear-gradient(0deg,rgba(0, 239, 209, 1),rgba(0, 172, 234, 1)",
										}}
									/>
								))}
							</SpanBox>
						) : (
							<ChangeText
								onClick={() => {
									setActiveSingleImage(true);
								}}
							>
								Change
							</ChangeText>
						)}
					</BodyTitle>
					{activeSingleImage ? (
						<SliderDiv>
							<BackArrow onClick={previous}>
								<img
									src={
										require("./../../../assets/icons/left-arrow.svg")
											.default
									}
									alt="Arrow"
								/>
							</BackArrow>
							<Slider
								{...settings}
								ref={(c) => {
									slider = c;
								}}
							>
								{bodyPartGroups.map((items, index) => (
									<Body>
										{items.map((data,i) => (
											<ImageCard key={i}
												onClick={() => {
													if (selectedModality?.id) {
														setSelectedSingleImage(
															data.image
														);
														setActiveSingleImage(
															false
														);
														setLaterality(
															data.is_laterality
														);
														handleStudyProtocol(
															data.id
														);
														setSelectedBodyPartID(
															data.id
														);
														setSelectedBodyPart(
															data
														);
													} else {
														activeError(
															"warning",
															"Select Modality"
														);
													}
												}}
											>
												<Images src={data.image} />
											</ImageCard>
										))}
									</Body>
								))}
							</Slider>
							<NextArrow onClick={next}>
								<img
									src={
										require("./../../../assets/icons/right-arrow.svg")
											.default
									}
									alt="Arrow"
								/>
							</NextArrow>
						</SliderDiv>
					) : (
						<SingleImageDiv>
							<SingleImage src={selectedSingleImage} />
						</SingleImageDiv>
					)}
				</BodyPartSlider>
				<SelectorBox>
					{/* <Top>
						<Laterality
							style={{
								cursor: isLaterality
									? "pointer"
									: "not-allowed",
							}}
							onClick={() => {
								if (isLaterality) {
									toggleActiveLaterality();
								} else {
									activeError("warning", "Select Modality");
								}
							}}
						>
							{selectedLateralityName === "unilateral" ? (
								<Text>
									{selectedLateralityName} :{" "}
									{selectedUnilateral}
								</Text>
							) : (
								<Text>{selectedLateralityName}</Text>
							)}
							<Arrow
								src={
									require("./../../../assets/icons/arrow-down.svg")
										.default
								}
								alt="arrow"
							/>
						</Laterality>

						{activeLaterality && (
							// <LateralityOption>
							// 	{lateralityItem.map((data) => (
							// 		<Option
							// 			onClick={() => {
							// 				toggleActiveLaterality();
							// 				setSelectedLaterality(data.title);
							// 			}}
							// 		>
							// 			{data.title}
							// 		</Option>
							// 	))}
							// </LateralityOption>
							// <LateralityModal
							// 	selectedLateralityName={selectedLateralityName}
							// 	setSelectedLateralityName={
							// 		setSelectedLateralityName
							// 	}
							// 	selectedUnilateral={selectedUnilateral}
							// 	setSelectedUnilateral={setSelectedUnilateral}
							// 	optionsItem={primaryLaterality}
							// 	toggleActiveOption={toggleActiveLaterality}
							// 	setSelectedOption={setSelectedLaterality}
							// 	setSelectedOptionId={setSelectedLateralityID}
							// 	top="58%"
							// />
							<LateralityOption ref={lateralityRef}>
								{primaryLaterality.map((data) => (
									<>
										<SelectLateralityContainer
											onClick={() => {
												setSelectedLateralityName(
													`${data.option}`
												);
												if (
													data.option === "bilateral"
												) {
													setSelectedUnilateral("");
												}
											}}
										>
											<div
												style={{
													display: "flex",
													marginTop: "15px",
												}}
												onClick={() => {
													if (
														selectedLateralityName ===
														"unilateral"
													) {
														// toggleActiveLaterality();
													}
												}}
											>
												<RadioButtonChecked>
													{selectedLateralityName ===
														data.option && (
														<InnerCircle />
													)}
												</RadioButtonChecked>
												<OptionText>
													{data.option}
												</OptionText>
											</div>
											{selectedLateralityName ===
												"unilateral" && (
												<div
													style={{
														display: "flex",
														marginLeft: "30px",
														marginTop: "10px",
													}}
												>
													{data.inner?.map((item) => (
														<div
															onClick={() => {
																setSelectedUnilateral(
																	item.option
																);
																// toggleActiveLaterality();
															}}
															style={{
																display: "flex",
																marginLeft:
																	"30px",
																marginTop:
																	"5px",
															}}
														>
															<RadioButtonChecked>
																{selectedUnilateral ===
																	item.option && (
																	<InnerCircle />
																)}
															</RadioButtonChecked>
															<OptionText>
																{item.option}
															</OptionText>
														</div>
													))}
												</div>
											)}
										</SelectLateralityContainer>
										{selectedLateralityName ===
											data.option && (
											<TextArea
												value={lateralityText}
												onChange={(e) => {
													setLateralityText(
														e.target.value
													);
												}}
												placeholder="Write Here..."
											/>
										)}
									</>
								))}

								{/* <AdditionalView></AdditionalView> */}
					{/* </LateralityOption>
						)}
					</Top> */}
					<LateralityDropDown
						items={primaryLaterality}
						setSelectedValue={setSelectedLaterality}
						selectedValue={selectedLaterality}
						selectedModality={selectedModality}
						activeError={activeError}
					/>
					<Top>
						<StudyProtocol
							style={{
								cursor: isProtocol ? "pointer" : "not-allowed",
							}}
							onClick={() => {
								if (isProtocol) {
									toggleActiveProtocol();
								}
							}}
						>
							<Text>{selectedProtocol}</Text>
							<Arrow
								src={
									require("./../../../assets/icons/arrow-down.svg")
										.default
								}
								alt="arrow"
							/>
						</StudyProtocol>
						{activeProtocol && (
							// <StudyProtocolOption>
							// 	{protocolItem.map((data) => (
							// 		<Option
							// 			onClick={() => {
							// 				toggleActiveProtocol();
							// 				setSelectedProtocol(data.title);
							// 			}}
							// 		>
							// 			{data.title}
							// 		</Option>
							// 	))}
							// </StudyProtocolOption>
							// <OptionModal
							// 	optionsItem={protocolItem}
							// 	toggleActiveOption={toggleActiveProtocol}
							// 	setSelectedOption={setSelectedProtocol}
							// 	setSelectedOptionId={setSelectedProtocolID}
							// 	top="63.5%"
							// 	title={"Protocol"}
							// />

							<ModalityOption ref={protocolRef}>
								{protocolItem.map((data) => (
									<Option
										key={data.id}
										onClick={() => {
											toggleActiveProtocol();
											setSelectedProtocol(
												`Protocol : ${data.name}`
											);
											setSelectedProtocolID(data.id);
											setPathology(data.is_pathology);
										}}
									>
										{data.name}
									</Option>
								))}
							</ModalityOption>
						)}
					</Top>
					<Top>
						<Pathology
							style={{
								cursor: isPathology ? "pointer" : "not-allowed",
							}}
							onClick={() => {
								if (isPathology) {
									toggleActivePathology();
								}
							}}
						>
							<Text>{selectedPathology}</Text>
							<Arrow
								src={
									require("./../../../assets/icons/arrow-down.svg")
										.default
								}
								alt="arrow"
							/>
						</Pathology>
						{activePathology && (
							// <PathologyOption>
							// 	{pathologyItem.map((data) => (
							// 		<Option
							// 			onClick={() => {
							// 				toggleActivePathology();
							// 				setSelectedPathology(data.title);
							// 			}}
							// 		>
							// 			{data.title}
							// 		</Option>
							// 	))}
							// </PathologyOption>
							// <OptionModal
							// 	optionsItem={pathologyItem}
							// 	toggleActiveOption={toggleActivePathology}
							// 	setSelectedOption={setSelectedPathology}
							// 	setSelectedOptionId={setSelectedPathologyID}
							// 	top="70%"
							// 	title={"Pathology"}
							// />

							<ModalityOption ref={pathologyRef}>
								{pathologyItem.map((data) => (
									<Option
										key={data.id}
										onClick={() => {
											toggleActivePathology();
											setSelectedPathology(
												`Pathology : ${data.name}`
											);
											setSelectedPathologyID(data.id);
										}}
									>
										{data.name}
									</Option>
								))}
							</ModalityOption>
						)}
					</Top>
				</SelectorBox>
			</Middle>

			<Bottom>
				<SynopticTemplate
					onClick={() => {
						// history.push("/synoptic-report/", report_Id);
						// if (selectedModality?.id) {
						// 	loadSynopticTemplate();
						// 	// } else if (selectedProtocolID === "") {
						// 	// 	alert("Select a protocol");
						// 	// } else if (selectedPathologyID === "") {
						// 	// 	alert("Select a pathology");
						// } else {
						// 	activeError("warning", "Select Modality");
						// }

						if (selectedModality.id === 0) {
							activeError("warning", "Select Modality");
						} else if (selectedBodyPart.is_laterality) {
							if (selectedLaterality.value) {
								loadSynopticTemplate();
							} else {
								activeError("warning", "Select Laterality");
							}
						} else {
							loadSynopticTemplate();
						}
					}}
				>
					<SynopticImage
						src={
							require("../../../assets/images/Synoptic.svg")
								.default
						}
					/>
					<TemplateType>
						Load Synoptic Reporting Template
					</TemplateType>
				</SynopticTemplate>

				<SynopticTemplate>
					<OtherImage
						src={
							require("../../../assets/images/Other.svg").default
						}
					/>
					<TemplateType>Other Reporting System</TemplateType>
				</SynopticTemplate>
			</Bottom>
		</LeftDetailsContainer>
	);
}

const LeftDetailsContainer = styled.div`
	width: 25%;
	background: #222222;
	height: 100vh;
	position: fixed;
	padding: 10px;
	left: 0px;
	overflow-y: scroll;
	::-webkit-scrollbar {
		display: none;
	}
	::-webkit-scrollbar-track {
		display: none;
	}
	::-webkit-scrollbar-thumb {
		display: none;
	}
`;
const ModalityDiv = styled.div`
	padding: 0 10px;
	background: #171617;
	border-radius: 8px;
`;
const SelectModality = styled.select`
	height: 50px;
	width: 100%;
	color: #a1a1a1;
`;
const OptionModality = styled.option`
	background: #333333;
	:hover {
		background-color: #424242;
	}
`;

const Top = styled.div`
	display: flex;
	flex-direction: Column;
	/* padding: 0 10px; */
	background: #171617;
	border-radius: 8px;
	position: relative;
`;
const Modality = styled.div`
	height: 50px;
	background: #1c1c1c;
	color: #a1a1a1;
	font-size: 14px;
	border: none;
	border-radius: 8px;
	/* // margin-bottom: 10px; */
	padding: 10px;
	align-items: center;
	display: flex;
	justify-content: space-between;
	cursor: pointer;
	@media (max-width: 1280px) {
		font-size: 13px;
	}
`;
const Text = styled.h4`
	font-size: 15px;
	font-family: "poppinsregular";
	text-transform: capitalize;
	@media (max-width: 1280px) {
		font-size: 14px;
	}
`;
const Arrow = styled.img``;

const StudyProtocol = styled(Modality)`
	margin-bottom: 10px;
`;
const Laterality = styled(Modality)`
	margin-bottom: 10px;
`;
const Middle = styled.div``;
const BodyPartSlider = styled.div`
	background-color: #171617;
	margin: 10px 0;
	border-radius: 8px;
	padding: 10px;
`;
const BodyTitle = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
`;
const Body = styled.div`
	display: grid !important;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 10px;
	@media only screen and (max-width: 1480px) {
		grid-template-columns: 1fr 1fr;
	}
`;
const SingleImageDiv = styled.div`
	height: 330px;
	align-items: center;
	display: flex;
	justify-content: center;
	padding: 10px;
	border-radius: 8px;
`;
const SingleImage = styled.img`
	width: 150px;
	border-radius: 5px;
`;
const SliderDiv = styled.div`
	position: relative;
`;

const NextArrow = styled.div`
	position: absolute;
	right: -20px;
	top: 50%;
	transform: translateY(-50%);
	z-index: 50;
	cursor: pointer;
	@media (max-width: 980px) {
		display: none;
	}
`;
const BackArrow = styled.div`
	position: absolute;
	left: -20px;
	top: 50%;
	transform: translateY(-50%);
	z-index: 50;
	cursor: pointer;
	@media (max-width: 980px) {
		display: none;
	}
`;
const ImageCard = styled.div`
	height: 100px;
	// width: 31.5%;
	background-color: #222222;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	cursor: pointer;
	border: 3px solid #222222;
`;
const Images = styled.img`
	height: 70px;
	width: 70px;
	border-radius: 5px;
`;
const SelectorBox = styled(Top)`
	padding: 10px;
`;

const LateralityBox = styled.div``;
const Pathology = styled(Modality)`
	margin-bottom: 0;
`;
const Bottom = styled(Top)`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 10px;
	padding: 10px;
`;
const SynopticTemplate = styled.div`
	background: #1c1c1c;
	border-radius: 8px;
	padding: 20px 10px;
	width: 49%;
	cursor: pointer;
`;
const SynopticImage = styled.img`
	width: 50px;
	height: 50px;
	margin-bottom: 20px;
`;
const OtherImage = styled(SynopticImage)``;
const TemplateType = styled.p`
	color: #22ade5;
	max-width: 150px;
	font-size: 15px;
	@media (max-width: 1280px) {
		font-size: 14px;
	}
`;

const SpanBox = styled.div`
	display: flex;
`;
const Span = styled.div`
	height: 5px;
	background: linear-gradient(
		0deg,
		rgba(0, 239, 209, 0.5),
		rgba(0, 172, 234, 0.5)
	);
	border-radius: 5px;
	margin-right: 3px;
`;
const ChangeText = styled(TemplateType)`
	cursor: pointer;
`;
const ModalityOption = styled.div`
	background-color: #333333;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: absolute;
	top: 60px;
	width: 100%;
	z-index: 60;
	visibility: ${(props) => (props.active ? "visible" : "hidden")};
`;
const Option = styled.h4`
	color: #fff;
	font-size: 15px;
	font-family: "poppinsregular";
	height: 50px;
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 0 15px;
	:hover {
		background-color: #424242;
	}
	:first-child {
		border-top-left-radius: 12px;
		border-top-right-radius: 12px;
	}
	:last-child {
		border-bottom-left-radius: 12px;
		border-bottom-right-radius: 12px;
	}
	@media (max-width: 1280px) {
		font-size: 14px;
	}
`;
///radio laterality

const LateralityOption = styled.div`
	padding: 10px 20px;
	background: #222222 !important;
	border-radius: 12px;
	margin-bottom: 10px;
`;
const SelectLateralityContainer = styled.div`
	// display: flex;
	margin-bottom: 10px;
	cursor: pointer;
	:last-child {
		margin-bottom: 0;
	}
`;
const RadioButtonChecked = styled.div`
	background-color: #203035;
	width: 16px;
	height: 16px;
	min-width: 16px;
	min-height: 16px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
`;
const InnerCircle = styled.div`
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: #00ace2;
`;
const OptionText = styled.h6`
	color: #a3a3a3;
	font-size: 15px;
	text-transform: capitalize;
	// margin-bottom: 10px;
`;

const TextArea = styled.textarea`
	border: 1px solid #5d5c5d;
	border-radius: 8px;
	padding: 10px;
	width: 100%;
	resize: vertical;
	margin-bottom: 10px;
	color: #fff;
	::placeholder {
		font-family: "poppinsregular";
		color: #4d595d;
	}
`;
