import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { baseConfig } from "./../../axiosConfig";
import { Context } from "./../../contexts/Store";

export default function LymphNodesPopup({
	setSelectedModal,
	selectedFindings,
	setSelectedFindings,
	selectedFindingsID,
	report_id,
	getFindingResults,
	lymphComment,
	setLymphComment,
	getFindings,
	assessmentPositions,
	setSelectedLesionDetails,
	selectedLesionDetails,
}) {
	const { state } = useContext(Context);

	const handleFindings = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.post(
				"/mammogram/findings-text-format/",
				{
					findings_id: selectedFindingsID,
					report_id: report_id,
					comment: lymphComment,
					position: assessmentPositions,
					patient_finding_id: selectedLesionDetails?.id
						? selectedLesionDetails?.id
						: "",
				},
				{
					headers: {
						Authorization: "Bearer " + accessToken,
					},
				}
			)
			.then((response) => {
				const { StatusCode, data, message } = response.data;
				if (StatusCode === 6000) {
					setSelectedModal("");
					getFindingResults();
					setLymphComment("");
					getFindings();
				} else {
					alert(message);
				}
			})
			.catch((error) => {});
	};

	useEffect(() => {
		if (selectedLesionDetails?.id) {
			setLymphComment(selectedLesionDetails?.comment);
		} else {
			setLymphComment("");
		}
		return () => {
			setSelectedLesionDetails({});
		};
	}, []);

	return (
		<BackContainer>
			<Overlay
				onClick={() => {
					setSelectedModal("");
				}}
			></Overlay>
			<Modal>
				<Container>
					<Head>
						<MainHeading>Lymph Nodes</MainHeading>
						<ButtonDiv>
							<Cancel
								onClick={() => {
									setSelectedFindings(
										selectedFindings.filter(
											(data) =>
												data !== selectedFindingsID
										)
									);
									setSelectedModal("");
								}}
							>
								Cancel
							</Cancel>
							<SaveButton
								onClick={() => {
									handleFindings();
								}}
							>
								Save
							</SaveButton>
						</ButtonDiv>
					</Head>
					<Comment>
						<Line></Line>
						<SubHeading>1. Comment</SubHeading>
						<TextArea
							placeholder="Type Here..."
							onChange={(e) => {
								setLymphComment(e.target.value);
							}}
							value={lymphComment}
						/>
					</Comment>
				</Container>
			</Modal>
		</BackContainer>
	);
}

const BackContainer = styled.div`
	position: fixed;
	transition: 0.3s;
	width: 100%;
	height: 100vh;
	z-index: 1000;
	left: 0;
	top: 0px;
	background: rgba(0, 0, 0, 0.4);
`;
const Overlay = styled.div`
	position: fixed;
	left: 0;
	top: 0px;
	width: 100%;
	cursor: pointer;
	height: 100vh;
`;
const Modal = styled.div`
	// margin: 0 auto;
	background: #222222;
	width: calc(25% - 40px);
	left: 20%;
	top: 50%;
	position: absolute;
	border-radius: 12px;
	transition: 0.5s;
	z-index: 101;
	width: 400px;
	// padding: 20px;
	// height: 500px;
	overflow-y: auto;
`;
const Container = styled.div`
	width: 400px;
	background: #222222;
	border-radius: 8px;
	padding: 20px;
`;
const Head = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const Line = styled.div`
	height: 1px;
	background: #555555;
	width: 100%;
	margin: 20px 0;
`;
const MainHeading = styled.h3`
	font-family: "poppinsmedium";
	color: #ff4c51;
	font-size: 18px;
`;
const ButtonDiv = styled.div`
	display: flex;
`;
const Cancel = styled.div`
	color: #00ace2;
	font-size: 13px;
	// background: #203035;
	padding: 8px 10px;
	border-radius: 8px;
	cursor: pointer;
	margin-right: 10px;
`;
const SaveButton = styled.div`
	color: #00ace2;
	font-size: 13px;
	background: #203035;
	padding: 8px 10px;
	border-radius: 8px;
	cursor: pointer;
`;
const SubHeading = styled.h4`
	font-family: "poppinsmedium";
	color: #00ace2;
	font-size: 14px;
	margin-bottom: 15px;
`;
const Comment = styled.div``;
const TextArea = styled.textarea`
	border: 1px solid #2d4349;
	border-radius: 8px;
	padding: 10px;
	width: 100%;
	resize: vertical;
	height: 100px;
	color: #fff;
	background: #203035;
	::placeholder {
		font-family: "poppinsregular";
		color: #4d595d;
	}
	&:focus {
		border-color: #aaa;
	}
`;
