import React from "react";
import styled from "styled-components";

function NoDataFound() {
	return (
		<Div
			className="no-result"
			style={{
				minHeight: "calc(70vh - 200px)",
				width: "100%",
				// background: "red",
			}}
		>
			<Text className="no-result-text">No results found </Text>
		</Div>
	);
}

export default NoDataFound;

const Div = styled.div`
	&.no-result {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 30%;
		padding: 0 15px;
		margin-top: 10px;
		width: 100%;
	}
`;
const Text = styled.text`
	&.no-result-text {
		font-family: "poppinsregular";
		font-size: 24px;
	}
`;
