import React from "react";
import Login from "./../../components/screens/Login";
import { Route, Switch, Redirect } from "react-router-dom";

function LoginRouter() {
	return (
		<Switch>
			<Route exact path="/login/" component={Login} />
		</Switch>
	);
}

export default LoginRouter;
