import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import SynopticUnitDropDown from "../synoptic-templates/SynopticUnitDropDown";

export default function SizeInput({
	choice,
	handleFindingFormValues,
	findingFormValues,
	i,
}) {
	return (
		<>
			{choice.field_type === "text" ? (
				<DimensionDiv>
					<DimensionBox
						type={"number"}
						value={findingFormValues[choice.key_name]}
						onChange={(e) => {
							handleFindingFormValues(
								choice.key_name,
								e.target.value
							);
						}}
						onWheel={(e) => e.target.blur()}
					/>
					<Cross className={i === 0 ? "" : "none"}>x</Cross>
				</DimensionDiv>
			) : choice.field_type === "unit" ? (
				<SynopticUnitDropDown
					items={choice.choices}
					selectedValue={findingFormValues[choice.key_name]}
					setSelectedValue={handleFindingFormValues}
					label={choice.key_name}
				/>
			) : null}
		</>
	);
}

const DimensionDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;
const Cross = styled.div`
	margin-right: 10px;
	&.none {
		display: none;
	}
`;

const DimensionBox = styled.input`
	padding: 0px 10px;
	width: 60px;
	background: #223035;
	border: 1px solid #30434a;
	border-radius: 5px;
	color: #fff;
	font-size: 14px;
	height: 40px;
	margin-right: 10px;
	&:after {
		content: "x";
	}
	&:focus {
		border-color: #aaa;
	}
`;
