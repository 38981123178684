// import React from "react";
// // import { Editor } from "draft-js";
// import { Editor, EditorState } from "draft-js";
// // import { Editor, EditorState } from "draft-js-plugins-editor";

// import "draft-js/dist/Draft.css";
// import jsPDF from "jspdf";
// import styled from "styled-components";

// export default function Draftjs({ setPDFText }) {
// 	const [editorState, setEditorState] = React.useState(
// 		EditorState.createEmpty()
// 	);

// 	const editor = React.useRef(null);

// 	function focusEditor() {
// 		editor.current.focus();
// 	}

// 	React.useEffect(() => {
// 		focusEditor();
// 	}, []);

// 	const contentState = editorState.getCurrentContent();
// 	setPDFText(contentState.getPlainText());
// 	// const plainText = contentState.getPlainText();

// 	// console.log(contentState, "contentState", plainText, "plainText");

// 	const handleBoldClick = () => {
// 		// Implement bold functionality here
// 	};

// 	const handleItalicClick = () => {
// 		// Implement italic functionality here
// 	};

// 	const handleHeaderClick = () => {
// 		// Implement header functionality here
// 	};

// 	// const toolbar = {
// 	// 	bold: handleBoldClick,
// 	// 	italic: handleItalicClick,
// 	// 	header: handleHeaderClick,
// 	// };

// 	const toolbar = {
// 		buttons: [
// 			{ label: "B", style: "BOLD", onClick: handleBoldClick },
// 			{ label: "I", style: "ITALIC", onClick: handleItalicClick },
// 		],
// 	};

// 	return (
// 		<>
// 			<Wrapper onClick={focusEditor}>
// 				<Editor
// 					ref={editor}
// 					editorState={editorState}
// 					onChange={(editorState) => setEditorState(editorState)}
// 					// toolbar={toolbar}
// 				/>
// 			</Wrapper>
// 			{/* <button onClick={exportToPDF}>Convert to PDF</button> */}
// 		</>
// 	);
// }

// const Wrapper = styled.div`
// 	color: #fff;
// 	padding: 12px;
// 	min-height: calc(100vh - 170px);
// 	cursor: text;
// `;

import React, { useState, useRef, useEffect } from "react";
import { EditorState, convertToRaw } from "draft-js";
import { Editor, FaImage } from "react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import styled from "styled-components";
import "./SimpleDraft.css";

import jsPDF from "jspdf";

import htmlPdf from "html-pdf";

import html2canvas from "html2canvas";
import ICON from "./../../../assets/icons/bold.svg";

// import { Toolbar, BoldButton, ItalicButton } from "draft-js-plugins-editor";s

// import { usePlugins } from "draft-js-plugins-editor";

import { Toolbar, BlockTypeSelect } from "draft-js-plugins-editor";

export default function SimpleDraftjs({ setPDFText }) {
	const editor = useRef(null);

	function focusEditor() {
		editor.current.focus();
	}
	const [editorState, setEditorState] = useState(EditorState.createEmpty());

	const contentState = editorState.getCurrentContent();
	// setPDFText(contentState.getPlainText());

	useEffect(() => {
		// focusEditor();
	}, []);

	const editorRef = useRef(null);

	const html = stateToHTML(editorState.getCurrentContent());
	// console.log(html);
	setPDFText(html);

	const handleConversion = () => {
		html2canvas(editorRef.current).then((canvas) => {
			// console.log(canvas, "canvas");
			// Do something with the canvas object, such as generating a PDF
		});
	};

	const focusEditorNew = () => {
		editorRef.current.focusEditor();
	};

	const downloadPDF = () => {
		const html = stateToHTML(editorState.getCurrentContent());

		const element = document.querySelector(".editor-class");
		element.style.color = "#000";

		html2canvas(element).then((newCanvas) => {
			const doc = new jsPDF();
			doc.addImage(newCanvas, "PNG", 0, 0);
			doc.save("document.pdf");
			element.style.color = "#fff";
		});

		// const test = "<body><div><h1>haaaai</h1></div></body>";

		// html2canvas(document.body).then(function (canvas) {
		// 	// document.body.classList.appendChild(canvas);
		// 	const doc = new jsPDF();
		// 	doc.addImage(canvas, "PNG", 0, 0);
		// 	doc.save("document.pdf");
		// });

		// html2canvas(test)
		// 	.then((canvas) => {
		// 		console.log(canvas, "rtttttt");

		// 		const doc = new jsPDF();
		// 		doc.addImage(canvas, "PNG", 0, 0);
		// 		doc.save("document.pdf");
		// 	})
		// 	.catch((error) => {
		// 		console.log(error, "rtttttt");
		// 	});
	};

	// const downloadPDF = () => {
	// 	const html = stateToHTML(editorState.getCurrentContent(), {
	// 		elementStyles: {
	// 			p: {
	// 				color: "black",
	// 			},
	// 		},
	// 	});
	// 	const doc = new jsPDF();
	// 	doc.html(html, {
	// 		callback: (doc) => {
	// 			doc.save("document.pdf");
	// 		},
	// 	});
	// };

	const uploadImage = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = (event) => {
				resolve({ data: { link: event.target.result } });
			};
			reader.onerror = (error) => {
				reject(error);
			};
			reader.readAsDataURL(file);
		});
	};

	//new

	return (
		<div className="editor">
			{/* <button onClick={createPdf}>Download PDF</button> */}

			<Wrapper
			// onClick={focusEditor}
			>
				{/* <Toolbar> */}
				{/* <BlockTypeSelect
					options={[
						{ label: "H1", style: "header-one" },
						{ label: "H2", style: "header-two" },
						{ label: "H3", style: "header-three" },
						{ label: "Normal", style: "unstyled" },
						{ label: "Paragraph", style: "blockquote" },
					]}
				/> */}
				{/* </Toolbar> */}
				<Editor
					ref={editorRef}
					// toolbarOnFocus
					// toolbarClassName="toolbar-class"
					editorState={editorState}
					onEditorStateChange={setEditorState}
					wrapperClassName="wrapper-class"
					editorClassName="editor-class"
					toolbarClassName="toolbar-class"
					toolbar={{
						image: true,
						blockType: true,
						options: ["inline", "blockType"],
						inline: {
							options: ["bold", "italic", "underline"],
						},
						blockType: {
							options: ["Normal", "H1"],
						},
					}}

					// toolbar={{
					// 	options: ["inline", "blockType"],
					// 	inline: {
					// 		bold: {
					// 			icon: ICON,
					// 			className: "demo-option-custom",
					// 		},
					// 		italic: {
					// 			icon: ICON,
					// 			className: "demo-option-custom",
					// 		},
					// 		underline: {
					// 			icon: ICON,
					// 			className: "demo-option-custom",
					// 		},
					// 		strikethrough: {
					// 			icon: ICON,
					// 			className: "demo-option-custom-hide",
					// 		},
					// 		monospace: { className: "demo-option-custom-hide" },
					// 		superscript: {
					// 			icon: ICON,
					// 			className: "demo-option-custom-hide",
					// 		},
					// 		subscript: {
					// 			icon: ICON,
					// 			className: "demo-option-custom-hide",
					// 		},
					// 	},
					// 	blockType: {
					// 		className: "demo-option-custom-wide",
					// 		dropdownClassName: "demo-dropdown-custom",
					// 	},
					// }}
					// editorProps={{
					// 	uploadCallback: uploadImage,
					// }}
				/>
			</Wrapper>
		</div>
	);
}

const Wrapper = styled.div`
	/* color: #fff; */
	/* padding: 12px; */
	/* min-height: calc(100vh - 170px); */
	cursor: text;
	min-height: calc(100vh - 260px);
	height: calc(100vh - 260px);
	overflow-y: scroll;
`;
