import React, { lazy, Suspense, useContext, useEffect, useState } from "react";
import { Switch } from "react-router-dom";
import Store, { Context } from "../../contexts/Store";
import { AuthRoute } from "./../routes/AuthRoute";
import { PrivateRoute } from "../routes/PrivateRoute";
import LoginRouter from "./LoginRouter";
import AppRouter from "./AppRouter";

// const AppRouter = lazy(() => import("./AppRouter"));
// const LoginRouter = lazy(() => import("./LoginRouter"));

export default function MainRouter() {
	const [isLoading, setLoading] = useState(true);
	const { dispatch } = useContext(Context);

	async function fetchUserData() {
		let promise = new Promise((resolve, reject) => {
			let user_details = localStorage.getItem("user_details");
			if (!user_details) {
				localStorage.setItem(
					"user_details",
					JSON.stringify(user_details)
				);
				user_details = localStorage.getItem("user_details");
			}
			let user_details_stored = JSON.parse(user_details);
			dispatch({
				type: "UPDATE_USER",
				user_details: user_details_stored,
			});
			setTimeout(() => {
				resolve("done!");
				setLoading(false);
			}, 500);
		});

		let result = await promise;
	}

	useEffect(() => {
		fetchUserData();
	}, []);

	//  isLoading ? null :

	return isLoading ? null : ( // <h1 style={{ color: "red" }}>Loading...22.</h1>
		<Suspense fallback={<h1 style={{ color: "red" }}>Loading....</h1>}>
			<Switch>
				<AuthRoute path="/login/" component={LoginRouter} />
				<PrivateRoute path="/" component={AppRouter} />
			</Switch>
		</Suspense>
	);
}
