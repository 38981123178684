import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function CategoryCommonPopup({
	setCategoryModal,
	categoryData,
	setCategoryData,
	setAssessmentData,
	categorySave,
	selectedCategory,
	setSelectedCategory,
	assessmentPositions,
}) {
	// const [selectedCategory, setSelectedCategory] = useState(
	// 	categoryData?.assessment_data[0]
	// );

	useEffect(() => {
		setSelectedCategory(categoryData?.assessment_data[0]);
	}, []);

	return (
		<BackContainer>
			<Overlay
				onClick={() => {
					setCategoryModal(false);
					setCategoryData({});
				}}
			></Overlay>
			<Modal>
				<Container>
					<Head>
						<MainHeading>{categoryData.category}</MainHeading>
						<SaveButton
							onClick={() => {
								// setAssessmentData(categoryData);
								categorySave(assessmentPositions);
							}}
						>
							Save
						</SaveButton>
					</Head>
					<TitleContainer>
						{categoryData?.assessment_data?.map((data) => (
							// <h4
							// 	onClick={() => {
							// 		setSelectedCategory(data);
							// 	}}
							// >
							// 	a
							// </h4>
							<>
								{data.title !== null && (
									<TitleDiv
										onClick={() => {
											setSelectedCategory(data);
										}}
									>
										<RadioButtonChecked>
											{selectedCategory?.title ===
												data.title && <InnerCircle />}
										</RadioButtonChecked>
										<Title>{data.title}</Title>
									</TitleDiv>
								)}
							</>
						))}
					</TitleContainer>
					<Line></Line>
					<TextContainer>
						<SubHeading>Assessment</SubHeading>
						<Content>{selectedCategory?.assessment}</Content>
					</TextContainer>
					<TextContainer>
						<SubHeading>Management</SubHeading>
						<Content>{selectedCategory?.management}</Content>
					</TextContainer>
					<TextContainer>
						<SubHeading>Likelihood of Cancer</SubHeading>
						<Content>
							{selectedCategory?.likelihood_of_cancer}
						</Content>
					</TextContainer>
				</Container>
			</Modal>
		</BackContainer>
	);
}

const BackContainer = styled.div`
	position: fixed;
	transition: 0.3s;
	width: 100%;
	height: 100vh;
	z-index: 1000;
	left: 0;
	top: 0px;
	background: rgba(0, 0, 0, 0);
`;
const Overlay = styled.div`
	position: fixed;
	left: 0;
	top: 0px;
	width: 100%;
	cursor: pointer;
	height: 100vh;
`;
const Modal = styled.div`
	margin: 0 auto;
	background: #333333;
	width: calc(25% - 40px);
	left: 20%;
	top: 50%;
	position: absolute;
	border-radius: 12px;
	transition: 0.5s;
	z-index: 101;
`;
const Container = styled.div`
	width: 100%;
	background: #222222;
	border-radius: 8px;
	padding: 20px;
`;
const Head = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const Line = styled.div`
	height: 1px;
	background: #555555;
	width: 100%;
	margin: 10px 0 20px 0;
`;
const MainHeading = styled.h3`
	font-family: "poppinsmedium";
	color: #ff4c51;
	font-size: 18px;
`;
const SaveButton = styled.div`
	color: #00ace2;
	font-size: 13px;
	background: #203035;
	padding: 8px 10px;
	border-radius: 8px;
	cursor: pointer;
`;
const TitleContainer = styled.div`
	display: flex;
	align-items: center;
`;
const RadioButtonChecked = styled.div`
	background-color: #203035;
	width: 16px;
	height: 16px;
	min-width: 16px;
	min-height: 16px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
`;
const InnerCircle = styled.div`
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: #00ace2;
`;
const TitleDiv = styled.div`
	background-color: #21292c;
	border-radius: 5px;
	display: flex;
	align-items: center;
	margin-right: 10px;
	height: 30px;
	padding: 0 5px;
	border: 1px solid #21292c;
	cursor: pointer;
`;
const Title = styled.h4`
	color: #a3a3a3;
	font-size: 16px;
`;
const SubHeading = styled.h4`
	font-family: "poppinsmedium";
	color: #00ace2;
	font-size: 15px;
	margin-bottom: 10px;
`;
const TextContainer = styled.div`
	margin-bottom: 25px;
	:last-child {
		margin-bottom: 0;
	}
`;
const Content = styled.p`
	color: #a3a3a3;
	font-size: 14px;
`;
