import React, { useState } from "react";
import styled from "styled-components";
import onClickOutside from "react-onclickoutside";

function Dropdown({
	title,
	items,
	multiSelect = false,
	setSelectedModality,
	selectedModality,
}) {
	const [open, setOpen] = useState(false);
	const [selection, setSelection] = useState([]);
	const toggle = () => setOpen(!open);
	Dropdown.handleClickOutside = () => setOpen(false);

	function handleOnClick(item) {
		if (!selection.some((current) => current.id === item.id)) {
			if (!multiSelect) {
				setSelection([item]);
			} else if (multiSelect) {
				setSelection([...selection, item]);
			}
		} else {
			let selectionAfterRemoval = selection;
			selectionAfterRemoval = selectionAfterRemoval.filter(
				(current) => current.id !== item.id
			);
			setSelection([...selectionAfterRemoval]);
		}
	}

	function isItemInSelection(item) {
		if (selection.some((current) => current.id === item.id)) {
			return true;
		}
		return false;
	}

	return (
		<>
			<Top style={{ marginBottom: "10px" }}>
				<Modality
					onClick={() => {
						toggle(!open);
					}}
				>
					<Text>
						{selectedModality?.name
							? selectedModality.name
							: `Select Modality`}
					</Text>
					<Arrow
						src={
							require("./../../assets/icons/arrow-down.svg")
								.default
						}
						alt="arrow"
						active={open}
					/>
				</Modality>
				{open && (
					<ModalityOption active={open}>
						{items.map((data) => (
							<Option
								key={data.id}
								onClick={() => {
									setSelectedModality(data);
									setOpen(false);
								}}
							>
								{data.name}
							</Option>
						))}
					</ModalityOption>
				)}
			</Top>
		</>
	);
}

const clickOutsideConfig = {
	handleClickOutside: () => Dropdown.handleClickOutside,
};

export default onClickOutside(Dropdown, clickOutsideConfig);

const Top = styled.div`
	display: flex;
	flex-direction: Column;
	/* padding: 0 10px; */
	background: #171617;
	border-radius: 8px;
	position: relative;
`;
const Modality = styled.div`
	height: 50px;
	background: #1c1c1c;
	color: #a1a1a1;
	font-size: 14px;
	border: none;
	border-radius: 8px;
	/* // margin-bottom: 10px; */
	padding: 10px;
	align-items: center;
	display: flex;
	justify-content: space-between;
	cursor: pointer;
	@media (max-width: 1280px) {
		font-size: 13px;
	}
`;
const Text = styled.h4`
	font-size: 15px;
	font-family: "poppinsregular";
	text-transform: capitalize;
	@media (max-width: 1280px) {
		font-size: 14px;
	}
`;
const Arrow = styled.img`
	transform: ${(props) => (props.active ? `rotate(180deg)` : `rotate(0deg)`)};
`;

const ModalityOption = styled.div`
	background-color: #333333;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: absolute;
	top: 60px;
	width: 100%;
	z-index: 60;
	visibility: ${(props) => (props.active ? "visible" : "hidden")};
`;
const Option = styled.h4`
	color: #fff;
	font-size: 15px;
	font-family: "poppinsregular";
	height: 50px;
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 0 15px;
	:hover {
		background-color: #424242;
	}
	:first-child {
		border-top-left-radius: 12px;
		border-top-right-radius: 12px;
	}
	:last-child {
		border-bottom-left-radius: 12px;
		border-bottom-right-radius: 12px;
	}
	@media (max-width: 1280px) {
		font-size: 14px;
	}
`;
