import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { baseConfig } from "./../../../../axiosConfig";
import { Context } from "./../../../../contexts/Store";
import { useDrag } from "react-use-gesture";

export default function MassEditModal({
	setSelectedModal,
	selectedFindings,
	setSelectedFindings,
	selectedFindingsID,
	handleFindingFormValues,
	findingData,
	// handleFindings,
	findingFormValues,
	form,
	getFindingResults,
	setFindingFormValues,
	getFindings,
	editModalData,
}) {
	const [sizeUnit] = useState([
		{
			id: 1,
			unit: "cm",
		},
		{
			id: 1,
			unit: "mm",
		},
	]);
	const [divPos, setDivPos] = useState({ x: 300, y: 300 });
	const movementDiv = useDrag((params) => {
		setDivPos({
			x: params.offset[0],
			y: params.offset[1],
		});
	});
	const [isSizeUnit, setSizeUnit] = useState(false);
	const [selectedSize, setSelectedSize] = useState("cm");
	const toggleSizeUnit = () => setSizeUnit(!isSizeUnit);

	//location nipple unit selection

	const [isLocationUnit, setLocationUnit] = useState(false);
	const [selectedLocationUnit, setSelectedLocationUnit] = useState("cm");
	const toggleLocationUnit = () => setLocationUnit(!isLocationUnit);

	const { state } = useContext(Context);

	const [activeOption, setActiveOption] = useState(false);
	const toggleActiveOption = () => {
		setActiveOption(!activeOption);
	};

	const [activeQuadrant, setActiveQuadrant] = useState(false);
	const toggleActiveQuadrant = () => {
		setActiveQuadrant(!activeQuadrant);
	};

	const [activeThird, setActiveThird] = useState(false);
	const toggleActiveThird = () => {
		setActiveThird(!activeThird);
	};

	useEffect(() => {
		setSelectedSize(editModalData?.size[1]);
		setSelectedLocationUnit(editModalData?.distance[1]);
		setFindingFormValues({
			MassClock: editModalData?.clock,
			MassQuadrant: editModalData?.quadrant,
			MassThird: editModalData?.third,
			MassShape: editModalData?.shape,
			MassDistance: editModalData?.distance[0],
			MassMargin: editModalData?.margin,
			MassDensity: editModalData?.density,
			MassComment: editModalData?.comment,
			MassSizeLength: editModalData.size[0].split("x")[0],
			MassSizeWidth: editModalData.size[0].split("x")[1],
			// MassDistanceUnit: editModalData?.distance[1],
		});
	}, [editModalData]);

	const handleFindings = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.post(
				"/mammogram/findings-text-format/",
				{
					patient_finding_id: editModalData.id,
					findings_id: selectedFindingsID,
					report_id: form?.report?.report_id,
					size: `${findingFormValues?.MassSizeLength}x${findingFormValues?.MassSizeWidth} ${selectedSize} `,
					clock: findingFormValues?.MassClock,
					quadrant: findingFormValues?.MassQuadrant,
					third: findingFormValues?.MassThird,
					distance: `${findingFormValues?.MassDistance} ${selectedLocationUnit}`,
					shape: findingFormValues?.MassShape,
					margin: findingFormValues?.MassMargin,
					density: findingFormValues?.MassDensity,
					comment: findingFormValues?.MassComment
						? findingFormValues?.MassComment
						: "",
				},
				{
					headers: {
						Authorization: "Bearer " + accessToken,
					},
				}
			)
			.then((response) => {
				const { StatusCode, message } = response.data;
				if (StatusCode === 6000) {
					setSelectedModal("");
					getFindingResults();
					setSelectedFindings([
						...selectedFindings,
						selectedFindingsID,
					]);
					setFindingFormValues({});
					getFindings();
				} else {
					alert(message);
				}
			})
			.catch((error) => {});
	};

	return (
		<BackContainer>
			<Overlay
				onClick={() => {
					setSelectedModal("");
				}}
			></Overlay>
			<Modal
				style={{
					position: "relative",
					top: divPos.y,
					left: divPos.x,
				}}
			>
				<MovementBar {...movementDiv()}>
					<DotDiv>
						<Dot />
						<Dot />
						<Dot />
						<Dot />
						<Dot />
					</DotDiv>
				</MovementBar>
				<Container>
					{/* <LeftPointer></LeftPointer> */}
					<Head>
						<MainHeading>Mass</MainHeading>
						<ButtonDiv>
							<Cancel
								onClick={() => {
									setSelectedFindings(
										selectedFindings.filter(
											(data) =>
												data !== selectedFindingsID
										)
									);
									setSelectedModal("");
									setFindingFormValues({});
								}}
							>
								Cancel
							</Cancel>
							<SaveButton
								onClick={() => {
									handleFindings();
								}}
							>
								Save
							</SaveButton>
						</ButtonDiv>
					</Head>
					<BodyDiv>
						<SizeContainer>
							<Line></Line>
							<SubHeading>1. Size</SubHeading>
							<Dimension>
								<DimensionBox
									type={"number"}
									value={findingFormValues?.MassSizeLength}
									onChange={(e) =>
										handleFindingFormValues(
											"MassSizeLength",
											e.target.value
										)
									}
								/>
								X
								<DimensionBox
									style={{ marginLeft: "10px" }}
									type={"number"}
									value={findingFormValues?.MassSizeWidth}
									onChange={(e) =>
										handleFindingFormValues(
											"MassSizeWidth",
											e.target.value
										)
									}
								/>
								<div style={{ position: "relative" }}>
									<UnitBox
										onClick={() => {
											toggleSizeUnit();
										}}
									>
										<Text>{selectedSize}</Text>
										<Arrow
											src={
												require("../../../../assets/icons/arrow-down.svg")
													.default
											}
											alt="arrow"
										/>
									</UnitBox>
									{isSizeUnit && (
										<Dropdown
										// style={{ top: "160px", left: "200px" }}
										>
											{sizeUnit.map((data) => (
												<DropdownOption
													onClick={() => {
														toggleSizeUnit();
														setSelectedSize(
															data.unit
														);
													}}
												>
													{data.unit}
												</DropdownOption>
											))}
										</Dropdown>
									)}
								</div>
							</Dimension>
						</SizeContainer>

						<LocationContainer>
							<Line></Line>
							<SubHeading>2. Location</SubHeading>
							<OptionContainer>
								<Unit>
									<OptionBox
										onClick={() => {
											toggleActiveOption();
										}}
									>
										{findingData.parameters
											.filter(
												(data) =>
													data.name == "Location"
											)[0]
											.sub_parameters.filter(
												(item) => item.name == "o'clock"
											)[0]
											.choices.map(
												(data) =>
													findingFormValues?.MassClock ===
														data.id && (
														<Text>{data.name}</Text>
													)
											)}
										<Arrow
											src={
												require("../../../../assets/icons/arrow-down.svg")
													.default
											}
											alt="arrow"
										/>
									</OptionBox>

									{activeOption && (
										<Dropdown>
											{findingData.parameters
												.filter(
													(data) =>
														data.name == "Location"
												)[0]
												.sub_parameters.filter(
													(item) =>
														item.name == "o'clock"
												)[0]
												.choices.map((data) => (
													<DropdownOption
														onClick={() => {
															toggleActiveOption();
															handleFindingFormValues(
																"MassClock",
																data?.id
															);
														}}
													>
														{data.name}
													</DropdownOption>
												))}
										</Dropdown>
									)}
									<UnitText>o'clock,</UnitText>
								</Unit>
								<Unit>
									<OptionBox
										onClick={() => {
											toggleActiveQuadrant();
										}}
									>
										{findingData.parameters
											.filter(
												(data) =>
													data.name == "Location"
											)[0]
											.sub_parameters.filter(
												(item) =>
													item.name == "Quadrant"
											)[0]
											.choices.map(
												(data) =>
													findingFormValues?.MassQuadrant ===
														data.id && (
														<Text>{data.name}</Text>
													)
											)}

										<Arrow
											src={
												require("../../../../assets/icons/arrow-down.svg")
													.default
											}
											alt="arrow"
										/>
									</OptionBox>
									{activeQuadrant && (
										<Dropdown>
											{findingData.parameters
												.filter(
													(data) =>
														data.name == "Location"
												)[0]
												.sub_parameters.filter(
													(item) =>
														item.name == "Quadrant"
												)[0]
												.choices.map((data) => (
													<DropdownOption
														onClick={() => {
															toggleActiveQuadrant();
															handleFindingFormValues(
																"MassQuadrant",
																data.id
															);
														}}
													>
														{data.name}
													</DropdownOption>
												))}
										</Dropdown>
									)}
									<UnitText>Quadrant,</UnitText>
								</Unit>
								<Unit>
									<OptionBox
										onClick={() => {
											toggleActiveThird();
										}}
									>
										{findingData.parameters
											.filter(
												(data) =>
													data.name == "Location"
											)[0]
											.sub_parameters.filter(
												(item) => item.name == "Third"
											)[0]
											.choices.map(
												(data) =>
													findingFormValues?.MassThird ===
														data.id && (
														<Text>{data.name}</Text>
													)
											)}
										<Arrow
											src={
												require("../../../../assets/icons/arrow-down.svg")
													.default
											}
											alt="arrow"
										/>
									</OptionBox>
									{activeThird && (
										<Dropdown>
											{findingData.parameters
												.filter(
													(data) =>
														data.name == "Location"
												)[0]
												.sub_parameters.filter(
													(item) =>
														item.name == "Third"
												)[0]
												.choices.map((data) => (
													<DropdownOption
														onClick={() => {
															toggleActiveThird();
															handleFindingFormValues(
																"MassThird",
																data.id
															);
														}}
													>
														{data.name}
													</DropdownOption>
												))}
										</Dropdown>
									)}
									<UnitText>Third,</UnitText>
								</Unit>
								<Unit>
									<CentimeterBox
										style={{ marginRight: "5px" }}
									>
										<Centimeter
											type={"number"}
											onChange={(e) => {
												handleFindingFormValues(
													"MassDistance",
													e.target.value
												);
											}}
											value={
												findingFormValues?.MassDistance
											}
										/>
										{/* <CentimeterText>cm</CentimeterText> */}
									</CentimeterBox>
									<div style={{ position: "relative" }}>
										<UnitBox
											onClick={() => {
												toggleLocationUnit();
											}}
										>
											<Text>{selectedLocationUnit}</Text>
											<Arrow
												src={
													require("../../../../assets/icons/arrow-down.svg")
														.default
												}
												alt="arrow"
											/>
										</UnitBox>

										{isLocationUnit && (
											<Dropdown
											// style={{ top: "160px", left: "200px" }}
											>
												{sizeUnit.map((data) => (
													<DropdownOption
														onClick={() => {
															toggleLocationUnit();
															setSelectedLocationUnit(
																data.unit
															);
														}}
													>
														{data.unit}
													</DropdownOption>
												))}
											</Dropdown>
										)}
									</div>
									<UnitText>from Nipple</UnitText>
								</Unit>
							</OptionContainer>
						</LocationContainer>
						<ShapeContainer>
							<Line></Line>
							<SubHeading>3. Shape</SubHeading>
							<BottomContainer>
								{findingData.parameters
									.filter((data) => data.name == "Shape")[0]
									.choices.map((data) => (
										<SelectContainer
											onClick={() => {
												handleFindingFormValues(
													"MassShape",
													data.id
												);
											}}
										>
											<RadioButtonChecked>
												{findingFormValues?.MassShape ===
													data.id && <InnerCircle />}
											</RadioButtonChecked>
											<OptionText>{data.name}</OptionText>
										</SelectContainer>
									))}
							</BottomContainer>
						</ShapeContainer>
						<MarginContainer>
							<Line></Line>
							<SubHeading>4. Margin</SubHeading>
							<BottomContainer>
								{findingData.parameters
									.filter((data) => data.name == "Margin")[0]
									.choices.map((data) => (
										<SelectContainer
											onClick={() => {
												handleFindingFormValues(
													"MassMargin",
													data.id
												);
											}}
										>
											<RadioButtonChecked>
												{findingFormValues?.MassMargin ===
													data.id && <InnerCircle />}
											</RadioButtonChecked>
											<OptionText>{data.name}</OptionText>
										</SelectContainer>
									))}
							</BottomContainer>
						</MarginContainer>
						<DensityContainer>
							<Line></Line>
							<SubHeading>5. Density</SubHeading>
							<BottomContainer>
								{findingData.parameters
									.filter((data) => data.name == "Density")[0]
									.choices.map((data) => (
										<SelectContainer
											onClick={() => {
												handleFindingFormValues(
													"MassDensity",
													data.id
												);
											}}
										>
											<RadioButtonChecked>
												{findingFormValues?.MassDensity ===
													data.id && <InnerCircle />}
											</RadioButtonChecked>
											<OptionText>{data.name}</OptionText>
										</SelectContainer>
									))}
							</BottomContainer>
						</DensityContainer>
						<Comment>
							<Line></Line>
							<SubHeading>6. Comment</SubHeading>
							<TextArea
								placeholder="Write Here..."
								onChange={(e) => {
									handleFindingFormValues(
										"MassComment",
										e.target.value
									);
								}}
								value={findingFormValues?.MassComment}
							/>
						</Comment>
					</BodyDiv>
				</Container>
			</Modal>
		</BackContainer>
	);
}
const BackContainer = styled.div`
	position: fixed;
	transition: 0.2s;
	width: 100%;
	height: 100vh;
	z-index: 1000;
	left: 0;
	top: 0px;
	background: rgba(0, 0, 0, 0.4);
`;
const Overlay = styled.div`
	position: fixed;
	left: 0;
	top: 0px;
	width: 100%;
	cursor: pointer;
	height: 100vh;
`;
const Modal = styled.div`
	// margin: 0 auto;
	background: #222222;
	border-radius: 12px;
	transition: 0.2s;
	z-index: 101;
	width: 500px;
	height: 550px;
	overflow-y: auto;
	user-select: none;
	scrollbar-width: 16px;
`;

const MovementBar = styled.div`
	background: linear-gradient(360deg, #292929 0%, #4b4b4b 100%);
	height: 30px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: move;
`;
const DotDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;
const Dot = styled.div`
	height: 8px;
	width: 8px;
	border-radius: 50%;
	background-color: #212121;
	margin-right: 5px;
`;
const Container = styled.div`
	margin: 20px;
	margin-right: 5px;
`;
const ButtonDiv = styled.div`
	display: flex;
`;
const Head = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 30px;
	margin-bottom: 10px;
	height: 30px;
	margin-right: 15px;
`;
const BodyDiv = styled.div`
	height: calc(550px - 110px);
	overflow-y: scroll;
	padding-right: 10px;
	&::-webkit-scrollbar {
		width: 8px;
		border: 1px solid #222;
		background: #222;
		border-radius: 5px;
	}
	::-webkit-scrollbar-thumb:hover {
		background: #00ace2;
	}

	::-webkit-scrollbar-thumb {
		background: #00ace2;
		border-radius: 10px;
	}
`;
const Line = styled.div`
	height: 1px;
	background: #555555;
	width: 100%;
	margin: 20px 0;
`;
const MainHeading = styled.h3`
	font-family: "poppinsmedium";
	color: #ff4c51;
	font-size: 18px;
`;
const SaveButton = styled.div`
	color: #00ace2;
	font-size: 13px;
	background: #203035;
	padding: 8px 10px;
	border-radius: 8px;
	cursor: pointer;
`;
const Cancel = styled.div`
	color: #00ace2;
	font-size: 13px;
	// background: #203035;
	padding: 8px 10px;
	border-radius: 8px;
	cursor: pointer;
	margin-right: 10px;
`;
const SizeContainer = styled.div`
	margin-bottom: 10px;
`;
const SubHeading = styled.h4`
	font-family: "poppinsmedium";
	color: #00ace2;
	font-size: 14px;
	margin-bottom: 15px;
`;
const Dimension = styled.div`
	display: flex;
	/* justify-content: space-between; */
	align-items: center;
	width: 65%;
	padding-left: 20px;
`;
const DimensionBox = styled.input`
	padding: 0px 10px;
	width: 60px;
	background: #223035;
	border: 1px solid #30434a;
	border-radius: 5px;
	color: #fff;
	font-size: 14px;
	height: 30px;
	margin-right: 10px;
`;

const UnitBox = styled.div`
	display: flex;
	/* justify-content: space-between; */
	align-items: center;
	border: 1px solid #323232;
	border-radius: 3px;
	color: #fff;
	font-size: 16px;
	/* width: 60px; */
	padding: 4px;
	cursor: pointer;
`;
const Text = styled.h5`
	margin-right: 10px;
	font-size: 13px;
`;
const Arrow = styled.img`
	height: 5px;
`;
const LocationContainer = styled.div``;
const OptionContainer = styled.div`
	/* display: flex;
	justify-content: space-between;
	flex-wrap: wrap; */
	padding-left: 20px;

	display: grid !important;
	grid-template-columns: 1fr 1fr;
	/* grid-gap: 10px; */
`;
const Unit = styled.div`
	display: flex;
	align-items: center;
	color: #a3a3a3;
	/* width: 50%; */
	margin-bottom: 10px;
	position: relative;
	:nth-last-child(-n + 2) {
		margin-bottom: 0;
	}
`;
const OptionBox = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px 10px;
	height: 30px;
	width: 85px;
	background: #203035;
	border: 1px solid #2d4349;
	border-radius: 5px;
	color: #fff;
	font-size: 14px;
	cursor: pointer;
`;
const Dropdown = styled.div`
	background-color: #333333;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: absolute;
	top: 30px;
	width: 85px;
	z-index: 60;
	/* height: 100px;
	overflow-y: scroll;
	&::-webkit-scrollbar {
		display: none;
		-ms-overflow-style: none;
		scrollbar-width: none;
	} */
`;
const DropdownOption = styled.h4`
	color: #fff;
	font-size: 15px;
	font-family: "poppinsregular";
	height: 30px;
	display: flex;
	align-items: center;
	cursor: pointer;
	text-align: center;
	padding: 0 10px;
	:hover {
		background-color: #424242;
	}
	:first-child {
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}
	:last-child {
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
	}
	@media (max-width: 1280px) {
		font-size: 14px;
	}
`;
const CentimeterBox = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #203035;
	border: 1px solid #2d4349;
	border-radius: 5px;
	width: -webkit-fill-available;
	width: -moz-available;
	width: 50px;
	height: 30px;
`;
const Centimeter = styled.input`
	width: 100%;
	color: #fff;
	font-size: 14px;
	height: 100%;
	padding-left: 10px;
`;
const CentimeterText = styled.h5`
	font-size: 14px;
	color: #fff;
`;
const UnitText = styled.h6`
	color: #a3a3a3;
	font-size: 14px;
	margin-left: 10px;
`;
const ShapeContainer = styled.div``;
const BottomContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
`;
const SelectContainer = styled.div`
	display: flex;
	margin-bottom: 10px;
	cursor: pointer;
	width: 48%;
	:last-child {
		margin-bottom: 0;
	}
`;
const RadioButtonChecked = styled.div`
	background-color: #203035;
	width: 16px;
	height: 16px;
	min-width: 16px;
	min-height: 16px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
`;
const InnerCircle = styled.div`
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: #00ace2;
`;
const OptionText = styled.h6`
	color: #a3a3a3;
	font-size: 14px;
	// margin-bottom: 10px;
`;
const MarginContainer = styled.div``;
const DensityContainer = styled.div``;
const Comment = styled.div``;
const TextArea = styled.textarea`
	border: 1px solid #5d5c5d;
	border-radius: 8px;
	padding: 10px;
	color: #fff;
	background: #203035;
	width: 100%;
	resize: vertical;
	height: 100px;
	::placeholder {
		font-family: "poppinsregular";
		color: #4d595d;
	}
`;
