import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { baseConfig } from "./../../../axiosConfig";
import { Context } from "./../../../contexts/Store";
import AssessmentMangmentModal from "./AssessmentMangmentModal";

export default function AssessmentAndManagment({
	getSynopticReport,
	synopticReport,
}) {
	const { template, report_id, synoptic_id } = useParams();
	const { state } = useContext(Context);
	const [assessments, setAssessments] = useState([]);
	const [isLoading, setLoading] = useState(true);
	const [selectedPosition, setSelectedPosition] = useState("");

	// modal
	const [isAssessmentModal, setAssessmentModal] = useState(false);
	const [selectedCategoryItem, setSelectedCategoryItem] = useState({});

	const getAssessments = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`/mammogram/assessment-categories/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
				params: {
					mammogram_id: synoptic_id,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setAssessments(data);
					getSynopticReport();
					setLoading(false);
				} else {
					setLoading(false);
				}
			})
			.catch((error) => {
				setLoading(false);
			});
	};

	useEffect(() => {
		getAssessments();
	}, []);

	//position
	const [coords, setCoords] = useState({ x: 0, y: 0, balanceY: 0 });

	const handleMouseMove = (event) => {
		setCoords({
			x: event.clientX,
			y: event.clientY,
			balanceY: event.view.innerHeight - event.clientY,
		});
	};

	return (
		!isLoading &&
		assessments.assessment_data && (
			<IndicationCard>
				<MainContainer>
					<Heading>5. ASSESMENT AND MANAGEMENT</Heading>
					<>
						{assessments?.left_assessment ? (
							<>
								<>
									<LateralityHeading>Left</LateralityHeading>
									{assessments.left_assessment.map(
										(item, i) => (
											<Div
												key={i}
												onClick={(e) => {
													setAssessmentModal(true);
													setSelectedCategoryItem(
														item
													);
													setSelectedPosition("left");
													handleMouseMove(e);
												}}
											>
												<RadioButtonChecked>
													{item.active && (
														<InnerCircle />
													)}
												</RadioButtonChecked>
												<OptionText>
													{item.category} :
													<Highlight>
														{" "}
														{item.title}
													</Highlight>
												</OptionText>
											</Div>
										)
									)}
								</>

								<>
									<LateralityHeading>Right</LateralityHeading>
									{assessments.assessment.map((item, i) => (
										<Div
											key={i}
											onClick={(e) => {
												setAssessmentModal(true);
												setSelectedCategoryItem(item);
												setSelectedPosition("right");
												handleMouseMove(e);
											}}
										>
											<RadioButtonChecked>
												{item.active && <InnerCircle />}
											</RadioButtonChecked>
											<OptionText>
												{item.category} :
												<Highlight>
													{" "}
													{item.title}
												</Highlight>
											</OptionText>
										</Div>
									))}
								</>
							</>
						) : (
							<>
								{assessments?.assessment?.map((item, i) => (
									<Div
										key={i}
										onClick={(e) => {
											setAssessmentModal(true);
											setSelectedCategoryItem(item);
											setSelectedPosition("right");
											handleMouseMove(e);
										}}
									>
										<RadioButtonChecked>
											{item.active && <InnerCircle />}
										</RadioButtonChecked>
										<OptionText>
											{item.category} :
											<Highlight> {item.title}</Highlight>
										</OptionText>
									</Div>
								))}
							</>
						)}
					</>
				</MainContainer>

				{isAssessmentModal === true ? (
					<AssessmentMangmentModal
						selectedCategoryItem={selectedCategoryItem}
						setAssessmentModal={setAssessmentModal}
						setSelectedCategoryItem={setSelectedCategoryItem}
						getAssessments={getAssessments}
						selectedPosition={selectedPosition}
						getSynopticReport={getSynopticReport}
						position={coords}
					/>
				) : null}
			</IndicationCard>
		)
	);
}

const IndicationCard = styled.div`
	background: #171617;
	padding: 25px 20px 15px;
	margin-bottom: 5px;
`;
const MainContainer = styled.section`
	z-index: 0;
	width: 100%;
`;

const Heading = styled.h5`
	color: #22ade5;
	font-size: 15px;
	margin-bottom: 10px;
`;

const Div = styled.div`
	display: flex;
	margin-bottom: 10px;
	cursor: pointer;
	align-items: center;
`;
const RadioButtonChecked = styled.div`
	background-color: #203035;
	width: 16px;
	height: 16px;
	min-width: 16px;
	min-height: 16px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
`;
const InnerCircle = styled.div`
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: #00ace2;
`;
const OptionText = styled.h6`
	color: #a3a3a3;
	font-size: 15px;
	margin-bottom: -3px;
`;
const Highlight = styled.small`
	color: #f44b4b;
	font-size: 15px;
`;
const LateralityHeading = styled.h4`
	color: #fff;
	font-size: 15px;
	margin-bottom: 5px;
`;
