import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import PatientDetails from "./PatientDetails";
import { baseConfig } from "./../../../axiosConfig";
import { Context } from "./../../../contexts/Store";
import EditPatientDetails from "./EditPatientDetails";
import { useHistory, Link } from "react-router-dom";
import NoDataFound from "../../includes/no-data/NoDataFound";

export default function PatientsAll() {
	const history = useHistory();

	const [patientDetailsModal, setPatientDetailsModal] = useState(false);
	const [editPatientDetailsModal, setEditPatientDetailsModal] =
		useState(false);
	const { state } = useContext(Context);
	const [patientDetails, setPatientDetails] = useState([]);
	const [singlePatient, setSinglePatient] = useState({});
	const [singlePatientReport, setSinglePatientReports] = useState([]);

	const [selectedPatient, setSelectedPatient] = useState("");
	const [editPatientData, setEditPatientData] = useState({});
	const [search, setSearch] = useState("");
	const [filterDate, setFilterDate] = useState("");
	const [newPatientId, setNewPatientId] = useState("");
	const [isLoading, setLoading] = useState(true);
	const [drawerModal, setDrawerModal] = useState(false);
	const [paginationData, setpaginationData] = useState({});
	const toggleDrawer = () => {
		setDrawerModal(!drawerModal);
	};

	const getPatientDetails = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`/patients/patients/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
				params: {
					q: search,
					date: filterDate,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setLoading(false);
					setPatientDetails(data.data);
					getSinglePatient(data.data[0]?.id);
					setpaginationData(data.paginator);
				} else {
					setLoading(false);
				}
			})
			.catch((error) => {
				if (error?.response?.status === 401) {
					if (error?.response?.data?.code === "token_not_valid") {
						localStorage.clear();
						window.location = "/";
					}
				}
			});
	};

	const deletePatient = (id) => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`/patients/delete-patient/${id}/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					getPatientDetails();
				} else {
				}
			})
			.catch((error) => {});
	};

	const getSinglePatient = (patientID) => {
		setSelectedPatient(patientID);
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`/patients/patient-details/${patientID}/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data, reports } = response.data;
				if (StatusCode === 6000) {
					setSinglePatient(data);
					setSinglePatientReports(reports);
				} else {
				}
			})
			.catch((error) => {});
	};

	const getReportID = (patientID) => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`/dashboard/new-report/${patientID}`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					history.push(`/report/${data.report_id}/`);
				} else {
				}
			})
			.catch((error) => {});
	};

	// const getNewPatient = () => {
	// 	setSelectedPatient(newPatientId);
	// 	let accessToken = state.user_details.access_token;
	// 	baseConfig
	// 		.get(`/patients/patient-details/${newPatientId}/`, {
	// 			headers: {
	// 				Authorization: "Bearer " + accessToken,
	// 			},
	// 		})
	// 		.then((response) => {
	// 			const { StatusCode, data } = response.data;
	// 			if (StatusCode === 6000) {
	// 				setSinglePatient(data);
	// 			} else {
	// 			}
	// 		})
	// 		.catch((error) => {});
	// };

	useEffect(() => {
		getPatientDetails();
	}, [search]);

	// useEffect(() => {
	// 	if (newPatientId) {
	// 		setTimeout(() => {
	// 			getNewPatient();
	// 		}, 500);
	// 	}
	// }, [newPatientId]);

	return (
		<BackContainer
			style={{ position: "relative" }}
			onClick={() => {
				if (drawerModal) {
					toggleDrawer();
				}
			}}
		>
			<ContainerCommon>
				<div>
					<MenuContainer
						style={{
							position: "absolute",
							top: "0px",
							right: "30px",
						}}
					>
						<MenuDiv
							onClick={() => {
								toggleDrawer();
							}}
						>
							<MenuBar
								src={
									drawerModal
										? require("../../../assets/icons/cancel.svg")
												.default
										: require("../../../assets/icons/menu-bar.svg")
												.default
								}
								alt="menu"
							/>
						</MenuDiv>
					</MenuContainer>
					<ReferDiv>
						{drawerModal && (
							<LateralityOption>
								<OptionDrawer
									onClick={(e) => {
										e.preventDefault();
										localStorage.clear();
										window.location = "/";
									}}
								>
									Log Out
								</OptionDrawer>
							</LateralityOption>
						)}
					</ReferDiv>
				</div>

				<ContainerLeft>
					<ContentContainer>
						<TitleText>
							<HeadDiv>
								{/* <ArrowContainer
									onClick={() => {
										history.goBack();
									}}
								>
									<ArrowImage
										src={
											require("../../../assets/icons/Arrow - Left 2.svg")
												.default
										}
									/>
								</ArrowContainer> */}
								<HeadingFour>All Patients</HeadingFour>
							</HeadDiv>
							<AddNewDiv
								onClick={() => {
									setPatientDetailsModal(true);
								}}
							>
								<AddImage
									src={
										require("../../../assets/icons/add.svg")
											.default
									}
								/>
								<AddText>Add New</AddText>
							</AddNewDiv>
						</TitleText>
						<TopBar>
							<TopBarLeft>
								{/* <SmallContent>Show</SmallContent>
								<NumberBox>
									<NumberBoxLeft>10</NumberBoxLeft>
									<NumberBoxRight>
										<ArrowBox>
											<ArrowImage
												src={
													require("../../../assets/icons/Polygon 1.svg")
														.default
												}
											/>
										</ArrowBox>
										<ArrowBox>
											<ArrowImage
												src={
													require("../../../assets/icons/Polygon 2.svg")
														.default
												}
											/>
										</ArrowBox>
									</NumberBoxRight>
								</NumberBox>
								<SmallContent>Results</SmallContent> */}
							</TopBarLeft>
							<TopBarRight>
								<InputDiv>
									<Input
										placeholder="Search"
										onChange={(e) => {
											setSearch(e.target.value);
										}}
									/>
									<SearchBox>
										<ArrowImage
											src={
												require("../../../assets/icons/search.svg")
													.default
											}
										/>
									</SearchBox>
								</InputDiv>
								{/* <>
									<FilterBox>
										<FilterImage>
											<ArrowImage
												src={
													require("../../../assets/icons/filter.svg")
														.default
												}
											/>
										</FilterImage>
										<HeadingCustom>Filter</HeadingCustom>
									</FilterBox>

								</> */}
							</TopBarRight>
						</TopBar>
						<TableScroll>
							{patientDetails.length > 0 ? (
								<PatientTable>
									<TableRow>
										<TableHead>ID</TableHead>
										<TableHead>Patient Name</TableHead>
										<TableHead>Phone No</TableHead>
										<TableHead>Age</TableHead>
										<TableHead>Doctor Name</TableHead>
										<TableHead>Study ID</TableHead>
										<TableHead>Action</TableHead>
									</TableRow>

									<>
										<TableBody>
											{patientDetails.map((data) => (
												<TableRow
													className="table-body-row"
													key={data.id}
													onClick={() => {
														getSinglePatient(
															data.id
														);
													}}
													// style={{
													// 	background:
													// 		selectedPatient ===
													// 		data.id
													// 			? "#2a2a2a"
													// 			: "#0e0d0e",
													// }}
													active={
														selectedPatient ===
														data.id
															? true
															: false
													}
												>
													<TableData>
														{data.patient_id}
													</TableData>
													<TableData>
														{data.name}
													</TableData>
													<TableData>
														{data.phone}
													</TableData>
													<TableData>
														{data.age}
													</TableData>
													<TableData>
														{data.doctor_name}
													</TableData>
													<TableData>
														{data.study_id}
													</TableData>
													<TableData>
														<ActionContainer>
															<ActionItem
																onClick={() => {
																	getSinglePatient(
																		data.id
																	);
																}}
															>
																<ArrowImage
																	src={
																		require("../../../assets/icons/Show.svg")
																			.default
																	}
																/>
															</ActionItem>
															<ActionItem
																onClick={() => {
																	setEditPatientData(
																		data
																	);
																	setEditPatientDetailsModal(
																		true
																	);
																}}
															>
																<ArrowImage
																	src={
																		require("../../../assets/icons/Edit-pencile.svg")
																			.default
																	}
																/>
															</ActionItem>
															<ActionItem
																onClick={() => {
																	deletePatient(
																		data.id
																	);
																}}
															>
																<ArrowImage
																	src={
																		require("../../../assets/icons/Delete.svg")
																			.default
																	}
																/>
															</ActionItem>
														</ActionContainer>
													</TableData>
												</TableRow>
											))}
										</TableBody>
									</>
								</PatientTable>
							) : (
								<NoDataFound />
							)}
						</TableScroll>
					</ContentContainer>
					<PagenationContainer>
						<ContentsRight>
							<ButtonNav>
								<PageImage>
									<ArrowImage
										src={
											require("../../../assets/icons/left.svg")
												.default
										}
									/>
								</PageImage>
								Previous
							</ButtonNav>
							<Pages>
								<Page>1</Page>
								<Page>2</Page>
								<Page>3</Page>
							</Pages>
							<ButtonNav>
								Next
								<PageImage>
									<ArrowImage
										src={
											require("../../../assets/icons/right.svg")
												.default
										}
									/>
								</PageImage>
							</ButtonNav>
						</ContentsRight>
					</PagenationContainer>
				</ContainerLeft>
				<ContainerRight>
					<>
						<PatientDetailsContainer>
							<HeadingTwo style={{ color: "#22ADE5" }}>
								PATIENT DETAILS
							</HeadingTwo>
							{/* <ActionContainer>
							<ActionItem>
								<ArrowImage
									src={
										require("../../../assets/icons/Edit.svg")
											.default
									}
								/>
							</ActionItem>
							<ActionItem>
								<ArrowImage
									src={
										require("../../../assets/icons/Delete.svg")
											.default
									}
								/>
							</ActionItem>
						</ActionContainer> */}
						</PatientDetailsContainer>

						{Object.keys(singlePatient).length !== 0 && (
							<PatientDetailsContent>
								<FirstContent>
									<HeadingFive style={{ color: "#22ADE5" }}>
										PATIENT DETAILS
									</HeadingFive>
									<SmallContainer>
										<DetailFirst>Patient Id</DetailFirst>
										<DetailSecond>
											{singlePatient.patient_id}
										</DetailSecond>
									</SmallContainer>
									<SmallContainer>
										<DetailFirst>Patient Name</DetailFirst>
										<DetailSecond>
											{singlePatient.name}
										</DetailSecond>
									</SmallContainer>
									<SmallContainer>
										<DetailFirst>Age</DetailFirst>
										<DetailSecond>
											{singlePatient.age}
										</DetailSecond>
									</SmallContainer>
									<SmallContainer>
										{/* <DetailFirst>Study ID</DetailFirst> */}
										{/* <DetailSecond>CT Scan</DetailSecond> */}
									</SmallContainer>
									<SmallContainer>
										<DetailFirst>Doctor Name</DetailFirst>
										<DetailSecond>
											{singlePatient.doctor_name}
										</DetailSecond>
									</SmallContainer>
								</FirstContent>
								<FirstContent className="last">
									<HeadingFive style={{ color: "#22ADE5" }}>
										ADDRESS
									</HeadingFive>
									<SmallContainerTwo>
										<SmallContainerLeft>
											<DetailFirst>Address 1</DetailFirst>
											<DetailSecond>
												{singlePatient.address}
											</DetailSecond>
										</SmallContainerLeft>
										<SmallContainerRight>
											<DetailFirst>Address 2</DetailFirst>
											<DetailSecond>
												{singlePatient.address2}
											</DetailSecond>
										</SmallContainerRight>
									</SmallContainerTwo>
									<SmallContainerTwo>
										<SmallContainerLeft>
											<DetailFirst>City</DetailFirst>
											<DetailSecond>
												{singlePatient.city}
											</DetailSecond>
										</SmallContainerLeft>
										<SmallContainerRight>
											<DetailFirst>District</DetailFirst>
											<DetailSecond>
												{singlePatient.district}
											</DetailSecond>
										</SmallContainerRight>
									</SmallContainerTwo>
									<SmallContainerTwo>
										<SmallContainerLeft>
											<DetailFirst>State</DetailFirst>
											<DetailSecond>
												{singlePatient.state}
											</DetailSecond>
										</SmallContainerLeft>
										<SmallContainerRight>
											<DetailFirst>PIN</DetailFirst>
											<DetailSecond>
												{singlePatient.pin}
											</DetailSecond>
										</SmallContainerRight>
									</SmallContainerTwo>
								</FirstContent>

								<LoadReport
									onClick={() => {
										getReportID(singlePatient.id);
									}}
									// to={`/report/${singlePatient.id}/`}
								>
									Report
								</LoadReport>
								{singlePatientReport.length > 0 && (
									<FirstContent>
										<HeadingFive
											style={{ color: "#22ADE5" }}
										>
											SAVED REPORTS
										</HeadingFive>

										{singlePatientReport.map((data, i) => (
											<ReportContainer key={i}>
												<SmallContainerTwo>
													<SmallContainerLeft>
														<DetailFirst>
															study
														</DetailFirst>
														<DetailSecond>
															{data.study}
														</DetailSecond>
													</SmallContainerLeft>
													<SmallContainerRight>
														<DetailFirst>
															Report Date
														</DetailFirst>
														<DetailSecond>
															{data.date}
														</DetailSecond>
													</SmallContainerRight>
												</SmallContainerTwo>
												<DownloadButtons>
													{/* <ReportButton
																onClick={() => {
																	history.push(
																		`/report/${data.id}/`
																	);
																}}
															>
																Open Report
															</ReportButton> */}
													<ReportButton
														onClick={() => {
															window.open(
																data.report
															);
														}}
													>
														Download Report
													</ReportButton>
												</DownloadButtons>
											</ReportContainer>
										))}
									</FirstContent>
								)}
							</PatientDetailsContent>
						)}
					</>
				</ContainerRight>
			</ContainerCommon>
			{patientDetailsModal && (
				<PatientDetails
					setPatientDetailsModal={setPatientDetailsModal}
					getPatientDetails={getPatientDetails}
					// setSelectedPatient={setSelectedPatient}

					// setNewPatientId={setNewPatientId}
				/>
			)}

			{editPatientDetailsModal && (
				<EditPatientDetails
					patientDetailsModal={editPatientDetailsModal}
					setPatientDetailsModal={setEditPatientDetailsModal}
					getPatientDetails={getPatientDetails}
					editPatientData={editPatientData}
				/>
			)}
		</BackContainer>
	);
}

const BackContainer = styled.div`
	height: 100vh;
	background: #161616;
	padding: 60px 40px;
`;
const ContainerCommon = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
	@media (max-width: 1024px) {
		flex-wrap: wrap;
	}
`;
const ContainerLeft = styled.div`
	background: #0e0d0e;
	border-radius: 12px;
	padding: 30px;
	width: 70%;
	height: calc(100vh - 120px);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	// overflow: scroll;
	// &::-webkit-scrollbar {
	// 	display: none;
	// 	-ms-overflow-style: none;
	// 	scrollbar-width: none;
	// }
	// scrollbar-width: none;
	@media (max-width: 1024px) {
		width: 100%;
	}
`;
const ContainerRight = styled.div`
	background: #0e0d0e;
	border-radius: 12px;
	padding: 20px;
	width: 28%;
	// height: calc(100vh - 120px);
	// overflow: scroll;
	// &::-webkit-scrollbar {
	// 	display: none;
	// 	-ms-overflow-style: none;
	// 	scrollbar-width: none;
	// }
	// scrollbar-width: none;
	@media (max-width: 1024px) {
		width: 100%;
		margin-top: 96px;
	}
`;
const ContentContainer = styled.div``;
const TitleText = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 20px;
	height: 60px;
	justify-content: space-between;
	@media (max-width: 1024px) {
		margin-bottom: 16px;
	}
`;
const HeadDiv = styled.div`
	display: flex;
	align-items: center;
`;
const ArrowImage = styled.img`
	display: block;
	width: 100%;
	height: 100%;
`;
const ArrowContainer = styled.div`
	width: 9px;
	height: 16px;
	margin: 0 30px 5px 0;
	cursor: pointer;
`;
const HeadingFour = styled.h4`
	font-size: 24px;
	color: #ffffff;
	font-weight: 500;
`;
const AddNewDiv = styled.div`
	display: flex;
	background: #22ade5;
	align-items: center;
	height: 50px;
	width: 150px;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	cursor: pointer;
	@media (max-width: 620px) {
		height: 40px;
		width: 120px;
	}
`;
const AddImage = styled.img`
	// display: flex;
	// justify-content: space-between;
	// height: 50px;
	// margin-bottom: 10px;
`;
const AddText = styled.h4`
	color: #fff;
	font-size: 16px;
	margin-left: 10px;
`;
const TopBar = styled.div`
	display: flex;
	justify-content: space-between;
	height: 50px;
	margin-bottom: 10px;
`;
const TopBarLeft = styled.div`
	display: flex;
	align-items: center;
`;
const TopBarRight = styled.div`
	display: flex;
`;
const SmallContent = styled.span`
	font-weight: 500;
	font-size: 14px;
	color: #ffffff;
	opacity: 0.7;
`;
const NumberBox = styled.div`
	display: flex;
	align-items: center;
	background: #222222;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	padding: 3px 10px;
	margin: 0 10px;
	:hover {
		cursor: pointer;
	}
`;
const NumberBoxLeft = styled.h3`
	color: #22ade5;
	opacity: 0.7;
	font-size: 15px;
	margin-right: 5px;
`;
const NumberBoxRight = styled.div``;
const ArrowBox = styled.div`
	width: 9px;
	height: 6px;
	margin: 2px 0;
`;
const InputDiv = styled.div`
	background: #222222;
	border: 2px solid rgba(255, 255, 255, 0.2);
	box-sizing: border-box;
	border-radius: 8px;
	display: flex;
	padding: 10px 19px;
	align-items: center;
	// margin-right: 16px;
`;
const SearchBox = styled.div`
	width: 20px;
	height: 20px;
	:hover {
		cursor: pointer;
	}
`;
const Input = styled.input`
	width: 300px;
	height: 45px;
	color: #fff;
	font-size: 16px;
	line-height: 50px;
	@media (max-width: 768px) {
		width: 250px;
		height: 45px;
	}
	@media (max-width: 768px) {
		width: 200px;
	}
`;
const FilterBox = styled.div`
	display: flex;
	align-items: center;
	background: #2a2a2a;
	border-radius: 8px;
	padding: 11px 15px;
	:hover {
		cursor: pointer;
	}
`;
const FilterImage = styled.div`
	width: 24px;
	height: 24px;
	margin-right: 13px;
`;
const HeadingCustom = styled.div`
	letter-spacing: 0.01em;
	font-weight: 600;
	font-size: 14px;
	color: #22ade5;
`;
const TableScroll = styled.div`
	width: 100%;
	height: calc(100vh - 400px);
	overflow: scroll;
	&::-webkit-scrollbar {
		display: none;
		-ms-overflow-style: none;
		scrollbar-width: none;
	}
	scrollbar-width: none;
`;
const PatientTable = styled.table`
	width: 100%;
	border-collapse: collapse;
`;
const TableRow = styled.tr`
	height: 60px;
	border-radius: 5px;
	cursor: pointer;
	background: ${(props) => (props.active ? "#2a2a2a" : "#0e0d0e")};
	&.table-body-row {
		&:hover {
			background: ${(props) =>
				props.active ? "#2a2a2a" : "rgba(42, 42, 42, 0.5)"};
		}
	}
`;
const TableHead = styled.th`
	text-align: start;
	font-size: 18px;
	color: #ffffff;
	opacity: 0.5;
	// padding-bottom: 20px;
	border-bottom: 1px solid #2a2a2a;
	vertical-align: middle;
	padding-left: 20px;
	@media (max-width: 1280px) {
		font-size: 16px;
	}
	@media (max-width: 680px) {
		font-size: 13px;
		padding-left: 12px;
	}
`;
const TableData = styled.td`
	// padding: 15px 0;
	font-size: 16px;
	// font-weight: 500;
	color: #fff;
	border-bottom: 1px solid #2a2a2a;
	vertical-align: middle;
	padding-left: 20px;
	@media (max-width: 768px) {
		font-size: 12px;
	}
`;
const ActionContainer = styled.ul`
	display: flex;
`;
const ActionItem = styled.li`
	width: 36px;
	height: 36px;
	padding: 10px 8px;
	background: #2b2b2b;
	border-radius: 8px;
	margin-right: 11px;
	&:last-child {
		margin-right: 0;
	}
	:hover {
		cursor: pointer;
	}
	@media (max-width: 768px) {
		width: 32px;
		height: 32px;
	}
`;
const PagenationContainer = styled.div`
	margin-top: 30px;
	display: flex;
	justify-content: end;
	height: 30px;
`;
const TableBody = styled.tbody`
	// display:block;
	// height: 100px;
	// overflow-y: scroll;
	// width:100%;
`;

const ContentsRight = styled.div`
	display: flex;
	display: flex;
`;

const ButtonNav = styled.button`
	display: flex;
	padding: 10px 19px;
	align-items: center;
	color: #22ade5;
	font-size: 13px;
	background: #222222;
	box-shadow: 0px 2px 6px rgba(9, 142, 223, 0.1);
	border-radius: 4px;
	:hover {
		cursor: pointer;
	}
`;
const Pages = styled.ul`
	display: flex;
	align-items: center;
	margin: 0 23px;
`;
const Page = styled.li`
	margin-right: 19px;
	:last-child {
		margin-right: 0;
	}
	:hover {
		cursor: pointer;
	}
`;
const PageImage = styled.div`
	width: 5px;
	height: 10px;
	margin: 0 10px;
`;
const PatientDetailsContainer = styled.div`
	display: flex;
	justify-content: space-between;
	// padding: 17px 14px;
	align-items: center;
	background: #222222;
	border-radius: 12px;
	margin-bottom: 10px;
	padding: 0 15px;
	height: 70px;

	@media (max-width: 1024px) {
		justify-content: center;
	}
`;
const HeadingTwo = styled.h2`
	font-size: 18px;
`;
const PatientDetailsContent = styled.div`
	padding: 14px;
	overflow-y: scroll;
	height: calc(100vh - 220px);
	::-webkit-scrollbar {
		width: 10px;
		background: #0e0d0e;
		border: 0;
	}
`;
const FirstContent = styled.div`
	margin-bottom: 30px;
	&.last {
		margin-bottom: 0;
	}
`;
const HeadingFive = styled.h5`
	font-size: 16px;
`;
const SmallContainer = styled.div`
	font-size: 16px;
	margin-top: 10px;
`;
const DetailFirst = styled.small`
	font-size: 13px;
	color: #ffffff;
	opacity: 0.6;
	line-height: 160%;
	text-transform: uppercase;
`;
const DetailSecond = styled.h6`
	font-size: 16px;
	color: #ffffff;
	line-height: 160%;
`;
const SmallContainerLeft = styled.div`
	width: 50%;
`;
const SmallContainerRight = styled.div`
	width: 50%;
`;
const SmallContainerTwo = styled.div`
	display: flex;
	margin-top: 10px;
`;
const LoadReport = styled.div`
	font-size: 16px;
	padding: 12px;
	color: #fff;
	border: 1px solid #22ade5;
	border-radius: 5px;
	margin-top: 30px;
	cursor: pointer;
	display: inline-block;
	margin-bottom: 15px;
`;
const ReportContainer = styled.div`
	background: #222222;
	border-radius: 12px;
	padding: 20px;
	margin-top: 16px;
`;
const DownloadButtons = styled.div`
	margin-top: 23px;
`;
const ReportButton = styled.button`
	padding: 8px 16px;
	font-size: 16px;
	color: #fff;
	border: 1px solid #22ade5;
	border-radius: 5px;
	margin-right: 16px;
	:last-child {
		margin-right: 0;
	}
`;

const MenuContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	height: 70px;
	align-items: center;
`;
const MenuDiv = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background: #171617;
	border-radius: 50%;
	height: 40px;
	width: 40px;
	cursor: pointer;
`;
const ReferDiv = styled.div`
	overflow: scroll;
	&::-webkit-scrollbar {
		display: none;
		-ms-overflow-style: none;
		scrollbar-width: none;
	}
	scrollbar-width: none;
	height: calc(100vh - 90px);
	width: 30%;
	display: flex;
	justify-content: flex-end;
`;
const MenuBar = styled.img`
	height: 15px;
	width: 15px;
`;
const LateralityOption = styled.div`
	// display: flex;
	// flex-wrap: wrap;
	// justify-content: space-between;
	// margin-bottom: 10px;
	padding: 8px;
	background: #0e0d0e;
	border-radius: 10px;
	position: absolute;
	width: 20%;
	background: #333;
	right: 40px;
`;
const OptionDrawer = styled.div`
	color: #fff;
	font-size: 15px;
	font-family: "poppinsregular";
	height: 50px;
	display: flex;
	align-items: center;
	cursor: pointer;
	border-radius: 10px;
	padding: 0 15px;
	:hover {
		background-color: #404040;
	}
	// :first-child {
	// 	border-top-left-radius: 10px;
	// 	border-top-right-radius: 10px;
	// }
	// :last-child {
	// 	border-bottom-left-radius: 10px;
	// 	border-bottom-right-radius: 10px;
	// }
	@media (max-width: 1280px) {
		font-size: 14px;
	}
`;
