import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { baseConfig } from "./../../../axiosConfig";
import { Context } from "./../../../contexts/Store";
import ReportHeaders from "../../includes/synoptic-templates/ReportHeaders";

//components
import {
	RenderLateralityView,
	RenderIndication,
	RenderComposition,
	RenderExamComparison,
	RenderFindings,
	RenderAssessments,
} from "./../../includes/synoptic-templates/ReportBody";

export default function Report({ synopticReport }) {
	const [doctorDetails, setDoctorDetails] = useState({});

	// const { template, report_id, synoptic_id } = useParams();
	// const { state } = useContext(Context);
	// const [synopticReport, setSynopticReport] = useState({});
	// const [isLoading, setLoading] = useState(true);

	// const getSynopticReport = () => {
	// 	setLoading(true);
	// 	let accessToken = state.user_details.access_token;
	// 	baseConfig
	// 		.get(`/mammogram/mammogram-report/${synoptic_id}`, {
	// 			headers: {
	// 				Authorization: "Bearer " + accessToken,
	// 			},
	// 		})
	// 		.then((response) => {
	// 			const { StatusCode, data } = response.data;
	// 			if (StatusCode === 6000) {
	// 				setSynopticReport(data);
	// 				setLoading(false);
	// 			} else {
	// 				setLoading(false);
	// 			}
	// 		})
	// 		.catch((error) => {});
	// };

	// useEffect(() => {
	// 	getSynopticReport();
	// 	return () => {};
	// }, []);

	const { template, report_id, synoptic_id } = useParams();
	const { state } = useContext(Context);
	const [isLoading, setLoading] = useState(true);

	//patient personal details
	const [patientPersonalData, setPatientPersonalData] = useState({});

	//get patient details
	const getPatientDetails = () => {
		setLoading(true);
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`/dashboard/report-data/${report_id}/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setPatientPersonalData(data);
					setLoading(false);
				} else {
					setLoading(false);
				}
			})
			.catch((error) => {
				setLoading(false);
			});
	};

	useEffect(() => {
		getPatientDetails();
	}, []);

	//download pdf url api
	const pdfurl = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`dashboard/download-report/${report_id}/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data, url } = response.data;
				if (StatusCode === 6000) {
					window.open(url);
				} else {
				}
			})
			.catch((error) => {});
	};

	return (
		<MainContainer>
			{!isLoading && (
				<InnerContainer>
					<ReportHeaders
						patientPersonalData={patientPersonalData}
						isLoading={isLoading}
					/>
					<ReportContent>
						<ReportInnerContainer>
							<>
								<RenderLateralityView data={synopticReport} />
								<RenderIndication data={synopticReport} />
								<RenderComposition data={synopticReport} />
								<RenderExamComparison data={synopticReport} />

								{synopticReport.laterality_name ===
								"Unilateral" ? (
									<>
										<RenderFindings
											data={synopticReport?.findings}
										/>
										<RenderAssessments
											data={
												synopticReport?.assessment_management
											}
										/>
									</>
								) : (
									<>
										<>
											<TitleTextDiv>
												<FormTitleText
													style={{
														fontWeight: 600,
														textDecoration:
															"underline",
													}}
												>
													Left
												</FormTitleText>
											</TitleTextDiv>
											<div
												style={{ paddingLeft: "10px" }}
											>
												<RenderFindings
													data={
														synopticReport?.left_findings
													}
												/>
												<RenderAssessments
													data={
														synopticReport?.assessment_management_left
													}
												/>
											</div>
										</>
										<>
											<TitleTextDiv>
												<FormTitleText
													style={{
														fontWeight: 600,
														textDecoration:
															"underline",
													}}
												>
													Right
												</FormTitleText>
											</TitleTextDiv>
											<div
												style={{ paddingLeft: "10px" }}
											>
												<RenderFindings
													data={
														synopticReport?.findings
													}
												/>
												<RenderAssessments
													data={
														synopticReport?.assessment_management
													}
												/>
											</div>
										</>
									</>
								)}

								{/* {isFindingLoading === false && (
							<>
								{findingsResults?.laterality_text !== null &&
									renderLateralityView()}
								{findingsResults?.indication_for_examination !==
									"" && renderIndication()}
								{findingsResults?.composition?.category !==
									"" && (
									<div style={{ marginTop: "20px" }}>
										{renderComposition()}
									</div>
								)}
								{findingsResults.laterality_name ===
								"Bilateral" ? (
									<>

										{renderFindings(
											findingsResults?.left_findings,
											"Left"
										)}

										{renderFindings(
											findingsResults?.findings,
											"Right"
										)}
									</>
								) : (
									renderFindings(findingsResults?.findings)
								)}
								{findingsResults?.exam_comparison !== null &&
									renderExamComparison()}

								{findingsResults.laterality_name ===
								"Bilateral" ? (
									<>
										{findingsResults
											?.assessment_management_left
											?.assessment !== "" &&
											renderAssessments(
												findingsResults?.assessment_management_left,
												"Left"
											)}

										{findingsResults?.assessment_management
											?.assessment !== "" &&
											renderAssessments(
												findingsResults?.assessment_management,
												"Right"
											)}
									</>
								) : (
									<>
										{findingsResults?.assessment_management
											?.assessment !== "" &&
											renderAssessments(
												findingsResults?.assessment_management
											)}
									</>
								)}
							</>
						)} */}
							</>
							{/* )} */}
						</ReportInnerContainer>
						{/* )} */}
					</ReportContent>
					<ReportBottom>
						<BottomLeft>
							<SignedText>Electronically signed by</SignedText>
							<DoctorName>
								{patientPersonalData?.doctor_details?.name}
								{/* Sajad */}
							</DoctorName>
						</BottomLeft>

						<PdfSave>
							{/* <ButtonDiv>
								<SubmitButton
									onClick={() => {
										// pdfurl();
									}}
								>
									Save
								</SubmitButton>
							</ButtonDiv> */}
							{/* {isDownloading && ( */}
							<DownloadButton
								//  href={pdfUrl} target="_blank"
								onClick={() => {
									pdfurl();
								}}
							>
								<DownloadImage
									src={
										require("../../../assets/icons/Download.svg")
											.default
									}
								/>
								Download Report
							</DownloadButton>
							{/* )} */}
						</PdfSave>
					</ReportBottom>
				</InnerContainer>
			)}
		</MainContainer>
	);
}

const MainContainer = styled.section`
	z-index: 0;
	width: 100%;
`;

const Heading = styled.h5`
	color: #22ade5;
	font-size: 15px;
	margin-bottom: 10px;
`;
const InnerContainer = styled.div`
	background: #222222;
	// height: 100vh;
	border-radius: 8px;
	margin: 10px;
`;
const ReportHead = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 65px 20px 50px;
`;
const Left = styled.div`
	display: flex;
	align-items: center;
	// width: 30%;
`;
const LogoImage = styled.img`
	height: 90px;
	width: 90px;
	margin-right: 15px;
`;
const MainText = styled.h3`
	color: #fff;
	font-size: 17px;
	line-height: 24px;
	font-family: "ramblaregular";
	@media (max-width: 1280px) {
		font-size: 16px;
	}
`;
const Right = styled.div`
	width: 55%;
`;

const LeftDetails = styled.ul`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
`;
const List = styled.li`
	width: 48%;
	display: flex;
	margin-bottom: 10px;
	:nth-last-child(-n + 2) {
		margin-bottom: 0px;
	}
	:nth-last-child(odd) {
		margin-left: 5px;
	}
`;
const LeftSection = styled.h3`
	font-size: 14px;
	font-family: "poppinsregular";
	color: #8d8d8d;
	width: 40%;
	margin-right: 5px;
	@media (max-width: 1280px) {
		font-size: 13px;
	}
`;
const RightSection = styled.h4`
	font-size: 14px;
	color: #fff;
	font-family: "poppinsregular";
	width: 47%;
	text-align: left;
	text-transform: capitalize;
	@media (max-width: 1280px) {
		font-size: 13px;
	}
`;

const ReportContent = styled.div`
	background: #222222;
	padding: 10px 30px;
	height: calc(100vh - 230px);
	overflow: scroll;
	&::-webkit-scrollbar {
		display: none;
		-ms-overflow-style: none;
		scrollbar-width: none;
	}
	scrollbar-width: none;
`;

const ReportBottom = styled.div`
	background: #222222;
	padding: 0px 60px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 80px;
`;
const BottomLeft = styled.div`
	display: flex;
	align-items: center;
`;
const SignedText = styled.h5`
	font-family: "poppinsregular";
	font-style: italic;
	margin-right: 10px;
	color: #a3a3a3;
`;
const DoctorName = styled.h4`
	font-family: "poppinsregular";
	color: #f44b4b;
	font-size: 15px;
`;
const DownloadButton = styled.div`
	display: flex;
	align-items: center;
	border: 1px solid grey;
	padding: 10px;
	border-radius: 5px;
	color: #a3a3a3;
	font-size: 15px;
	height: 40px;
	cursor: pointer;
`;
const DownloadImage = styled.img`
	height: 15px;
	margin-right: 10px;
`;

const ReportInnerContainer = styled.div`
	border-radius: 8px;
	background: #171617;
	min-height: calc(100vh - 250px);
	padding-top: 20px;
`;

const PdfSave = styled.div`
	display: flex;
	align-items: center;
`;
const ButtonDiv = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-right: 10px;
	height: 40px;
`;
const SubmitButton = styled.div`
	padding: 10px 22px;
	color: #fff;
	background: #00ace2;
	border-radius: 5px;
	font-size: 16px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const TitleTextDiv = styled.div`
	padding: 0 30px;
	margin-bottom: 10px;
`;
const FormTitleText = styled.h5`
	color: #fff;
	font-family: "poppinsregular";
	font-size: 16px;
`;
