import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
// import onClickOutside from "react-onclickoutside";

// function UnitDropDown({
export default function SynopticUnitDropDown({
	items,
	selectedValue,
	setSelectedValue,
	label,
}) {
	const [open, setOpen] = useState(false);
	const toggle = () => setOpen(!open);

	const selectOption = (value) => {
		// setSelectedValue(value);
		setSelectedValue(label, value);
		setOpen(false);
	};

	const unitRef = useRef(null);

	useEffect(() => {
		const onBodyClick = (event) => {
			if (unitRef.current && unitRef.current.contains(event.target)) {
				return;
			}
			setOpen(false);
		};
		// add event listener
		document.body.addEventListener("click", onBodyClick);
		// remove event listener
		return () => {
			document.body.removeEventListener("click", onBodyClick);
		};
	}, []);

	return (
		<>
			<Top>
				<Modality
					ref={unitRef}
					onClick={() => {
						toggle();
					}}
				>
					{items.map(
						(data, i) =>
							data.id === selectedValue && (
								<Text>{data.name}</Text>
							)
					)}
					{!selectedValue && <Text />}

					<Arrow
						src={
							require("./../../../assets/icons/arrow-down.svg")
								.default
						}
						alt="arrow"
						active={open}
					/>
				</Modality>
				{open && (
					<ModalityOption active={open}>
						{items.map((data, i) => (
							<Option
								key={i}
								onClick={() => {
									selectOption(data.id);
								}}
							>
								{data.name}
							</Option>
						))}
					</ModalityOption>
				)}
			</Top>
		</>
	);
}

const Top = styled.div`
	display: flex;
	flex-direction: Column;
	/* padding: 0 10px; */
	background: #171617;
	border-radius: 8px;
	position: relative;
`;
const Modality = styled.div`
	height: 40px;
	min-width: 75px;
	background: #1c1c1c;
	color: #a1a1a1;
	font-size: 14px;
	border: 1px solid #1c1c1c;
	border-radius: 8px;
	/* // margin-bottom: 10px; */
	padding: 0 10px;
	align-items: center;
	display: flex;
	justify-content: space-between;
	cursor: pointer;
	@media (max-width: 1280px) {
		font-size: 13px;
	}
	&.select-drop-drown {
		width: 85px;
		background: #203035;
		border: 1px solid #2d4349;
		border-radius: 5px;
		color: #fff;
		font-size: 14px;
		cursor: pointer;
	}
`;
const Text = styled.h4`
	font-size: 14px;
	font-family: "poppinsregular";
	text-transform: lowercase;
`;
const Arrow = styled.img`
	transform: ${(props) => (props.active ? `rotate(180deg)` : `rotate(0deg)`)};
	margin-left: 10px;
	height: 10px;
	width: 10px;
`;

const ModalityOption = styled.div`
	background-color: #333333;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: absolute;
	top: 50px;
	width: 100%;
	z-index: 60;
	visibility: ${(props) => (props.active ? "visible" : "hidden")};
`;
const Option = styled.h4`
	color: #fff;
	font-size: 15px;
	font-family: "poppinsregular";
	height: 40px;
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 0 15px;
	:hover {
		background-color: #424242;
	}
	:first-child {
		border-top-left-radius: 12px;
		border-top-right-radius: 12px;
	}
	:last-child {
		border-bottom-left-radius: 12px;
		border-bottom-right-radius: 12px;
	}
	@media (max-width: 1280px) {
		font-size: 14px;
	}
`;
