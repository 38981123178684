import React from "react";
import "./App.css";
import Store from "./contexts/Store";
import AppRouter from "./routing/routers/AppRouter";
import MainRouter from "./routing/routers/MainRouter";

function App() {
	return (
		<Store>
			<MainRouter />
			{/* <AppRouter /> */}
		</Store>
	);
}

export default App;
