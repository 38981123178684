import React from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";

export default function AlertBox({
	// status,
	// isAlert,
	// setIsAlert,
	// setAlertStatus,
	// alertMessage,
	propsObject,
}) {
	const handleClose = () => {
		propsObject.setIsAlert(false);
		propsObject.setAlertStatus("");
	};

	return (
		<Stack spacing={2} sx={{ width: "100%" }}>
			<Snackbar
				open={propsObject.isAlert}
				autoHideDuration={3000}
				onClose={handleClose}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				{/* {status === "success" ? (
                    <Alert
                        onClose={handleClose}
                        severity={status}
                        sx={{ width: "100%" }}
                    >
                        Lead added success — <strong>check it out!</strong>
                    </Alert>
                ) : (
                    <Alert
                        onClose={handleClose}
                        severity={status}
                        sx={{ width: "100%" }}
                    >
                        Lead added failed — <strong>check it out!</strong>
                    </Alert>
                )} */}
				<Alert
					onClose={handleClose}
					severity={propsObject.status}
					sx={{ width: "100%" }}
				>
					{propsObject.alertMessage} — <strong>check it out!</strong>
				</Alert>
			</Snackbar>
		</Stack>
	);
}
