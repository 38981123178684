import React, { useContext, useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import "./../../assets/css/style.css";
import PrimaryPage from "./../../components/screens/Home/PrimaryPage";
import Login from "./../../components/screens/Login";
// import NoAsymmetryPopup from "../includes/NoAsymmetryPopup";
// import NoCalcificationPopup from "../includes/NoCalcificationsPopup";
// import NoDistortionPopup from "../includes/NoDistortionPopup";
// import NoIntramammaryNodesPopup from "../includes/NoIntramammaryNodesPopup";
// import NoMassPopup from "../includes/NoMassPopup";
// import NoSkinLesionsPopup from "../includes/NoSkinLesionsPopup";
// import LymphNodesPopup from "../includes/LymphNodesPopup";
// import CategoryCommonPopup from "../includes/CategoryCommonPopup";
// import FollowupModal from "../screens/FollowupModal";
import BreastPrimaryPage from "./../../components/screens/breast/BreastPrimaryPage";
import patientsAll from "../../components/screens/Home/PatientsAll";
import SynopticTemplates from "../../components/screens/synoptic-templates/SynopticTemplates";

function AppRouter() {
	return (
		<Switch>
			<Route exact path="/">
				<Redirect to="/patient-report" />
			</Route>
			<Route
				exact
				path="/synoptic-report/:report_id/:synoptic_id/"
				component={BreastPrimaryPage}
			/>
			<Route path="/report/:report_Id/" component={PrimaryPage} />
			<Route path="/patient-report/" component={patientsAll} />
			<Route
				path="/synoptic-report/:template/:report_id/:synoptic_id/"
				component={SynopticTemplates}
			/>
		</Switch>
	);
}

export default AppRouter;
