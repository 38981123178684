import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

function LateralityModal({
	optionsItem,
	toggleActiveOption,
	top,
	selectedLateralityName,
	setSelectedLateralityName,
	selectedUnilateral,
	setSelectedUnilateral,
}) {
	useEffect(() => {
		if (selectedLateralityName === "Bilateral") {
			setSelectedUnilateral("");
		}
	}, [selectedLateralityName]);

	return (
		<BackContainer>
			<Overlay
				onClick={() => {
					toggleActiveOption();
				}}
			></Overlay>
			<Modal top={top}>
				<Container>
					{optionsItem.map((data) => (
						<SelectLateralityContainer
							onClick={() => {
								setSelectedLateralityName(`${data.option}`);
							}}
						>
							<div
								style={{
									display: "flex",
									marginBottom: "10px",
								}}
							>
								<RadioButtonChecked>
									{selectedLateralityName === data.option && (
										<InnerCircle />
									)}
								</RadioButtonChecked>
								<OptionText>{data.option}</OptionText>
							</div>
							{selectedLateralityName === "Unilateral" && (
								<div
									style={{
										display: "flex",
										marginLeft: "30px",
									}}
								>
									{data.inner?.map((item) => (
										<div
											onClick={() => {
												setSelectedUnilateral(
													item.option
												);
											}}
											style={{
												display: "flex",
												marginLeft: "30px",
											}}
										>
											<RadioButtonChecked>
												{selectedUnilateral ===
													item.option && (
													<InnerCircle />
												)}
											</RadioButtonChecked>
											<OptionText>
												{item.option}
											</OptionText>
										</div>
									))}
								</div>
							)}
						</SelectLateralityContainer>
					))}
				</Container>
			</Modal>
		</BackContainer>
	);
}
export default LateralityModal;

const BackContainer = styled.div`
	position: fixed;
	transition: 0.3s;
	width: 100%;
	height: 100vh;
	z-index: 1000;
	left: 0;
	top: 0px;
	background: rgba(0, 0, 0, 0.4);
`;
const Overlay = styled.div`
	position: fixed;
	left: 0;
	top: 0px;
	width: 100%;
	cursor: pointer;
	height: 100vh;
`;
const Modal = styled.div`
	margin: 0 auto;
	background: #222222 !important;
	width: calc(25% - 40px);
	left: 20px;
	top: ${(props) => (props.top ? props.top : "50%")};
	position: absolute;
	border-radius: 12px;
	transition: 0.5s;
	z-index: 101;
`;
const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 20px;
`;
const Option = styled.h4`
	color: #fff;
	font-size: 15px;
	font-family: "poppinsregular";
	height: 50px;
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 0 15px;
	:hover {
		background-color: #424242;
	}
	:first-child {
		border-top-left-radius: 12px;
		border-top-right-radius: 12px;
	}
	:last-child {
		border-bottom-left-radius: 12px;
		border-bottom-right-radius: 12px;
	}
	@media (max-width: 1280px) {
		font-size: 14px;
	}
`;
//ggggggggggggggggg
const SelectLateralityContainer = styled.div`
	// display: flex;
	margin-bottom: 10px;
	cursor: pointer;
	:last-child {
		margin-bottom: 0;
	}
`;
const RadioButtonChecked = styled.div`
	background-color: #203035;
	width: 16px;
	height: 16px;
	min-width: 16px;
	min-height: 16px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
`;
const InnerCircle = styled.div`
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: #00ace2;
`;
const OptionText = styled.h6`
	color: #a3a3a3;
	font-size: 15px;
	// margin-bottom: 10px;
`;
