import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BreastLeftContainer from "./BreastLeftContainer";
import { baseConfig } from "./../../../axiosConfig";
import { Context } from "./../../../contexts/Store";
import moment from "moment";
import "./styles.css";

export default function BreastPrimaryPage() {
	const history = useHistory();
	const { report_id, synoptic_id } = useParams();
	const { state } = useContext(Context);
	const [leftContainer, setLeftContainer] = useState("Home");
	const [selectedField, setSelectedField] = useState("Subtitle");
	const [isFieldActive, setFieldActive] = useState(false);
	const [isFindingLoading, setFindingLoading] = useState(true);
	const [isDownloading, setDownloading] = useState(false);
	const [drawerModal, setDrawerModal] = useState(false);
	const [findingsResults, setFindingsResults] = useState();
	const toggleDrawer = () => {
		setDrawerModal(!drawerModal);
	};

	const [optionsItem, setOptionItem] = useState([
		{
			id: 1,
			title: "Subtitle",
		},
		{
			id: 2,
			title: "Paragraph",
		},
	]);
	const toggleActiveOption = () => {
		setFieldActive(!isFieldActive);
	};
	//Indication
	const [indication, setIndication] = useState("");
	const [indicationResult, setIndicationResult] = useState("");

	//Exam Comparison
	const [examComparison, setExamComparison] = useState("");
	const [examComparisonResult, setExamComparisonResult] = useState("");

	//Composition
	const [selectedComposition, setSelectedComposition] = useState("");
	const [compositionCategory, setCompositionCategory] = useState("");
	const [optionsComposition, setOptionsComposition] = useState([]);
	//Findings
	const [optionsFinding, setOptionsFinding] = useState([]);

	//assessments
	const [assessment, setAssessment] = useState([]);
	const [isAssessmentComplete, setAssessmentComplete] = useState(false);

	// useLocation
	const location = useLocation();
	const [form, setFormValues] = useState(location?.state?.state);

	//form values
	const [findingFormValues, setFindingFormValues] = useState({});

	const handleFindingFormValues = (key, value) => {
		setFindingFormValues({ ...findingFormValues, [key]: value });
	};

	//Reference
	const [articles, setArticles] = useState([]);
	const [articleId, setArticleId] = useState("");
	const [references, setReferences] = useState([]);

	//Lexicon for

	const [isLexicon, setLexicon] = useState(true);
	const [lexiconSubtitle, setLexiconSubtitle] = useState("");

	//Assessmet

	const [assessmentData, setAssessmentData] = useState({});
	const [categoryData, setCategoryData] = useState({});

	//Show
	const [isShown, setIsShown] = useState(false);

	//dowload pdf url
	const [pdfUrl, setPDFUrl] = useState("");

	//patient personal details
	const [patientPersonalData, setPatientPersonalData] = useState({});

	//get patient details
	const getPatientDetails = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`/dashboard/report-data/${report_id}/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setPatientPersonalData(data);
				} else {
				}
			})
			.catch((error) => {});
	};

	const getComposition = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`/mammogram/composition/?mammogram_id=${synoptic_id}`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setOptionsComposition(data);
				} else {
				}
			})
			.catch((error) => {});
	};

	const getFindings = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`/mammogram/attributes/?mammogram_id=${synoptic_id}`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setOptionsFinding(data);
				} else {
				}
			})
			.catch((error) => {});
	};

	const getAssessments = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`/mammogram/assessment-categories/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
				params: {
					mammogram_id: synoptic_id,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setAssessment(data);
				} else {
				}
			})
			.catch((error) => {});
	};

	//finding result api
	// const getFindingResults = () => {
	// 	let accessToken = state.user_details.access_token;
	// 	baseConfig
	// 		.get(
	// 			`/mammogram/mammogram-findings/?mammogram_id=${synoptic_id}`,
	// 			{
	// 				headers: {
	// 					Authorization: "Bearer " + accessToken,
	// 				},
	// 			}
	// 		)
	// 		.then((response) => {
	// 			const { StatusCode, data } = response.data;
	// 			if (StatusCode === 6000) {
	// 				setFindingsResults(data);
	// 			} else {
	// 			}
	// 		})
	// 		.catch((error) => {});
	// };

	//finding result api
	const getFindingResults = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`/mammogram/mammogram-report/${synoptic_id}`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setFindingsResults(data);
					setIndication(data?.indication_for_examination);
					setExamComparison(data?.exam_comparison);
					setCompositionCategory(data?.composition?.category);
					setFindingLoading(false);
				} else {
					setFindingLoading(false);
				}
			})
			.catch((error) => {});
	};

	//download pdf url api
	const pdfurl = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`dashboard/download-report/${report_id}/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data, url } = response.data;
				if (StatusCode === 6000) {
					setPDFUrl(url);
					setDownloading(true);
				} else {
				}
			})
			.catch((error) => {});
	};

	//reference api
	const getReference = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.get("/dashboard/articles-references", {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, reference_data, lexicon_data } =
					response.data;
				if (StatusCode === 6000) {
					setReferences(reference_data);
					setArticles(lexicon_data);
				} else {
				}
			})
			.catch((error) => {});
	};

	const submitAssessment = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.post(
				"/dashboard/indication-text-save/",
				{
					report_id: report_id,
					// exam_comparison: examComparison,
					indication_text: indication,
				},
				{
					headers: {
						Authorization: "Bearer " + accessToken,
					},
				}
			)
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					// pdfurl();
					// setAssessmentComplete(true);
					getFindingResults();
					setIndicationResult(indication);
				} else {
				}
			})
			.catch((error) => {});
	};

	const submitExamComparison = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.post(
				"/dashboard/exam-comparison-save/",
				{
					report_id: report_id,
					exam_comparison: examComparison,
				},
				{
					headers: {
						Authorization: "Bearer " + accessToken,
					},
				}
			)
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					getFindingResults();
					setExamComparisonResult(
						findingsResults?.indication_for_examination
					);
				} else {
				}
			})
			.catch((error) => {});
	};

	useEffect(() => {
		getComposition();
		getFindings();
		getAssessments();
		getFindingResults();
		getReference();
		getPatientDetails();
	}, []);

	const [isCheck, setIsCheck] = useState([]);

	const handleClick = (e) => {
		const { id, checked } = e.target;
		setIsCheck([...isCheck, id]);
		if (!checked) {
			setIsCheck(isCheck.filter((item) => item !== id));
		}
	};

	const renderDefinition = (data, pos) => {
		return data.definition.map((def, ind) => (
			<>
				{def.slice(0, 2) === "$$" ? (
					<LinkText
						style={{
							marginLeft: "0",
							display: "inline",
						}}
						to={{
							pathname: def.slice(2),
						}}
						target="_blank"
					>
						{def.slice(2)}
					</LinkText>
				) : def.slice(0, 2) === "##" ? (
					<>
						<div className="HoverButton">
							<span className={"hoverHide"}>{def.slice(2)} </span>
							{data.figure !== null && (
								<div className={"test"}>
									<div
										style={{
											position: "absolute",
											bottom: 0,
											top: 0,
											right: 0,
											left: 0,
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											width: "max-content",
											margin: "0 auto",
										}}
									>
										<LexiconImage
											src={data.figure}
											alt="image"
										/>
									</div>
								</div>
							)}
						</div>
					</>
				) : def.slice(0, 2) === "**" ? (
					<Ul>
						<Li>
							<Dot /> {def.slice(2)}
						</Li>
					</Ul>
				) : (
					<>
						<Definition>{def}</Definition>
					</>
				)}
			</>
		));
	};

	const renderLateralityView = () => {
		return (
			<>
				<TitleTextDiv>
					<FormTitleText>VIEWS</FormTitleText>
				</TitleTextDiv>

				<ParagraphTextDiv>
					<ParagraphText>
						{findingsResults?.laterality_text}
					</ParagraphText>
					<ParagraphText>
						{findingsResults?.laterality_text_view}
					</ParagraphText>
				</ParagraphTextDiv>
			</>
		);
	};
	const renderIndication = () => {
		return (
			<>
				<TitleTextDiv>
					<FormTitleText>INDICATION FOR EXAMINATION</FormTitleText>
				</TitleTextDiv>

				<ParagraphTextDiv>
					<ParagraphText>
						{findingsResults?.indication_for_examination}
					</ParagraphText>
				</ParagraphTextDiv>
			</>
		);
	};
	const renderComposition = () => {
		return (
			<>
				<TitleTextDiv>
					<FormTitleText>BREAST COMPOSITION</FormTitleText>
				</TitleTextDiv>

				<ParagraphTextDiv
					style={{ display: "flex", alignItems: "baseline" }}
				>
					<FormTitleText>
						{findingsResults?.composition?.category} :
					</FormTitleText>
					<ParagraphText>
						&nbsp;{findingsResults?.composition?.title}
					</ParagraphText>
				</ParagraphTextDiv>
			</>
		);
	};

	const renderFindings = (findings, val) => {
		return (
			<div style={{ marginTop: "10px" }}>
				{val && (
					<TitleTextDiv>
						<FormTitleText
							style={{
								fontWeight: 600,
								textDecoration: "underline",
							}}
						>
							{val}
						</FormTitleText>
					</TitleTextDiv>
				)}

				<TitleTextDiv>
					<FormTitleText>FINDINGS</FormTitleText>
				</TitleTextDiv>
				<ParagraphTextDiv>
					{findings?.map((data) =>
						data.texts.map((item) => (
							<ParagraphText>{item}</ParagraphText>
						))
					)}
				</ParagraphTextDiv>
			</div>
		);
	};

	const renderExamComparison = () => {
		return (
			<>
				<TitleTextDiv style={{ marginTop: "10px" }}>
					<FormTitleText>
						PREVIOUS EXAM COMPARISON / USG CORRELATION
					</FormTitleText>
				</TitleTextDiv>

				<ParagraphTextDiv>
					<ParagraphText>
						{findingsResults?.exam_comparison}
					</ParagraphText>
				</ParagraphTextDiv>
			</>
		);
	};

	const renderAssessments = (data, val) => {
		return (
			<div style={{ marginTop: "10px" }}>
				{val && (
					<TitleTextDiv>
						<FormTitleText
							style={{
								fontWeight: 600,
								textDecoration: "underline",
							}}
						>
							{val}
						</FormTitleText>
					</TitleTextDiv>
				)}

				<TitleTextDiv>
					<FormTitleText>ASSESSMENTS</FormTitleText>
				</TitleTextDiv>

				<ParagraphTextDiv
					style={{ display: "flex", alignItems: "center" }}
				>
					<ParagraphText
						style={{
							fontSize: "16px",
							color: "#fff",
							whiteSpace: "nowrap",
							// width: "18%",
						}}
					>
						{data?.category_name} -
					</ParagraphText>
					<ParagraphText>
						&nbsp;
						{data?.assessment}
					</ParagraphText>
				</ParagraphTextDiv>

				<TitleTextDiv style={{ marginTop: "10px" }}>
					<FormTitleText>MANAGEMENT</FormTitleText>
				</TitleTextDiv>

				<ParagraphTextDiv>
					<ParagraphText>{data?.management}</ParagraphText>
				</ParagraphTextDiv>

				<TitleTextDiv style={{ marginTop: "10px" }}>
					<FormTitleText>Likelihood of Cancer</FormTitleText>
				</TitleTextDiv>

				<ParagraphTextDiv>
					<ParagraphText>{data?.likelihood_of_cancer}</ParagraphText>
				</ParagraphTextDiv>
			</div>
		);
	};

	return (
		<MainContainer>
			<BreastLeftContainer
				optionsComposition={optionsComposition}
				selectedComposition={selectedComposition}
				setSelectedComposition={setSelectedComposition}
				compositionCategory={compositionCategory}
				setCompositionCategory={setCompositionCategory}
				setIndication={setIndication}
				indication={indication}
				optionsFinding={optionsFinding}
				handleClick={handleClick}
				isCheck={isCheck}
				assessment={assessment}
				form={form}
				handleFindingFormValues={handleFindingFormValues}
				getFindingResults={getFindingResults}
				setAssessmentData={setAssessmentData}
				setExamComparison={setExamComparison}
				submitAssessment={submitAssessment}
				categoryData={categoryData}
				setCategoryData={setCategoryData}
				getFindings={getFindings}
				examComparison={examComparison}
				submitExamComparison={submitExamComparison}
				getAssessments={getAssessments}
				getComposition={getComposition}
				findingsResults={findingsResults}
			/>
			<ReportContainer>
				<InnerContainer>
					<ReportHead>
						<Left>
							<LogoImage
								src={
									require("../../../assets/images/logo.svg")
										.default
								}
							/>
							<MainText>
								RADIOLOGY
								<br />
								REPORTING
								<br />
								REDEFINED
							</MainText>
						</Left>
						<Right>
							<LeftDetails>
								<List>
									<LeftSection>Patient:</LeftSection>
									<RightSection>
										{
											patientPersonalData?.patient_details
												?.name
										}
									</RightSection>
								</List>
								<List>
									<LeftSection>Report ID:</LeftSection>
									<RightSection>
										{patientPersonalData?.study_id}
									</RightSection>
								</List>
								<List>
									<LeftSection>Age:</LeftSection>
									<RightSection>
										{
											patientPersonalData?.patient_details
												?.age
										}
									</RightSection>
								</List>
								<List>
									<LeftSection>Date:</LeftSection>
									<RightSection>
										{moment(
											patientPersonalData.date
										).format("YYYY-MM-DD")}
									</RightSection>
								</List>
								{/* <List>
									<LeftSection>Disease:</LeftSection>
									<RightSection>Breast Cancer</RightSection>
								</List>
								<List>
									<LeftSection>Exam:</LeftSection>
									<RightSection>MRI Brain</RightSection>
								</List> */}
							</LeftDetails>
						</Right>
					</ReportHead>
					<ReportContent>
						<ReportInnerContainer>
							{isFindingLoading === false && (
								<>
									{findingsResults?.laterality_text !==
										null && renderLateralityView()}
									{findingsResults?.indication_for_examination !==
										"" && renderIndication()}
									{findingsResults?.composition?.category !==
										"" && (
										<div style={{ marginTop: "20px" }}>
											{renderComposition()}
										</div>
									)}
									{findingsResults.laterality_name ===
									"Bilateral" ? (
										<>
											{/* {renderFindings(
												findingsResults?.findings
										  )} */}

											{renderFindings(
												findingsResults?.left_findings,
												"Left"
											)}

											{renderFindings(
												findingsResults?.findings,
												"Right"
											)}
										</>
									) : (
										renderFindings(
											findingsResults?.findings
										)
									)}
									{findingsResults?.exam_comparison !==
										null && renderExamComparison()}

									{findingsResults.laterality_name ===
									"Bilateral" ? (
										<>
											{findingsResults
												?.assessment_management_left
												?.assessment !== "" &&
												renderAssessments(
													findingsResults?.assessment_management_left,
													"Left"
												)}

											{findingsResults
												?.assessment_management
												?.assessment !== "" &&
												renderAssessments(
													findingsResults?.assessment_management,
													"Right"
												)}
										</>
									) : (
										<>
											{findingsResults
												?.assessment_management
												?.assessment !== "" &&
												renderAssessments(
													findingsResults?.assessment_management
												)}
										</>
									)}
									{/* <TextInput
								placeholder="TITLE..."
								type="text"
							></TextInput>
							<Buttons>
								<ButtonSubtitle>+ Subtitle</ButtonSubtitle>
								<ButtonParagraph>+ Paragraph</ButtonParagraph>
							</Buttons> */}
								</>
							)}
						</ReportInnerContainer>
					</ReportContent>
					<ReportBottom>
						<BottomLeft>
							<SignedText>Electronically signed by</SignedText>
							<DoctorName>
								{patientPersonalData?.doctor_details?.name}
							</DoctorName>
						</BottomLeft>

						{/* {isAssessmentComplete === false ? ( */}
						<PdfSave>
							<ButtonDiv>
								<SubmitButton
									onClick={() => {
										pdfurl();
									}}
								>
									Save
								</SubmitButton>
							</ButtonDiv>
							{isDownloading && (
								<DownloadButton
									href={pdfUrl}
									target="_blank"

									// onClick={() => {
									// 	window.location(
									// 		"https://www.clickdimensions.com/links/TestPDFfile.pdf"
									// 	);
									// }}
									// onClick={() => {
									// 	if (isAssessmentComplete === false) {
									// 		alert("Submit the synoptic report");
									// 	} else {
									// 	}
									// }}
								>
									<DownloadImage
										src={
											require("../../../assets/icons/Download.svg")
												.default
										}
									/>
									Download Report
								</DownloadButton>
							)}
						</PdfSave>
						{/* ) : ( */}
						{/* <DownloadButton
								// onClick={() => {
								// 	window.location(
								// 		"https://www.clickdimensions.com/links/TestPDFfile.pdf"
								// 	);
								// }}
								href={pdfUrl}
								target="_blank"
							>
								<DownloadImage
									src={
										require("../../../assets/icons/Download.svg")
											.default
									}
								/>
								Download Report
							</DownloadButton> */}
						{/* )} */}
					</ReportBottom>
				</InnerContainer>
			</ReportContainer>
			<RightReferenceContainer>
				<MenuContainer>
					<MenuDiv
						onClick={() => {
							toggleDrawer();
						}}
					>
						<MenuBar
							src={
								drawerModal
									? require("../../../assets/icons/cancel.svg")
											.default
									: require("../../../assets/icons/menu-bar.svg")
											.default
							}
							alt="menu"
						/>
					</MenuDiv>
				</MenuContainer>
				<ReferDiv>
					{drawerModal && (
						<LateralityOption>
							{/* <OptionDrawer
							// onClick={() => {
							// 	toggleActiveLaterality();
							// 	setSelectedLaterality(data.title);
							// }}
							>
								New Report
							</OptionDrawer> */}
							<OptionDrawer
								onClick={() => {
									history.push("/patient-report/");
								}}
							>
								Patient History
							</OptionDrawer>
							<OptionDrawer
								onClick={(e) => {
									e.preventDefault();
									localStorage.clear();
									window.location = "/";
								}}
							>
								Log Out
							</OptionDrawer>
						</LateralityOption>
					)}

					<ArticleInnerContainer>
						<ReferanceHeadDiv>
							<HeadContainer
								onClick={() => {
									setLexicon(true);
								}}
								style={{
									backgroundColor: isLexicon
										? "#343434"
										: "#222222",
								}}
							>
								<Head>LEXICON</Head>
							</HeadContainer>

							<HeadContainer
								onClick={() => {
									setLexicon(false);
								}}
								style={{
									backgroundColor: isLexicon
										? "#222222"
										: "#343434",
								}}
							>
								<Head>REFERENCES</Head>
							</HeadContainer>
							{/* <SearchDiv>
								<Search
									src={
										require("./../../../assets/icons/search.svg")
											.default
									}
									alt="search"
								/>
							</SearchDiv> */}
						</ReferanceHeadDiv>
						<>
							{isLexicon ? (
								<ReferanceDiv>
									{articles.map((data, index) => (
										<ArticleMainDiv
											style={{
												background:
													articleId === data.id
														? "#1c1c1c"
														: "#171617",
											}}
										>
											<ArticleDiv>
												<ArticleHeading>
													{/* {data.title} */}
													{data.name}
												</ArticleHeading>
												{articleId === data.name ? (
													<AddMoreDiv
														onClick={() => {
															setArticleId("");
														}}
													>
														<AddMore
															style={{
																marginBottom:
																	"5px",
															}}
															src={
																require("./../../../assets/icons/less.svg")
																	.default
															}
														/>
													</AddMoreDiv>
												) : (
													<AddMoreDiv
														onClick={() => {
															setArticleId(
																data.name
															);
														}}
													>
														<AddMore
															src={
																require("./../../../assets/icons/add.svg")
																	.default
															}
														/>
													</AddMoreDiv>
												)}
											</ArticleDiv>

											{articleId === data.name && (
												<>
													<div
														className={
															"parentHover"
														}
													>
														{renderDefinition(
															data,
															"1st"
														)}
													</div>

													{data.sub_articles.map(
														(item) => (
															<>
																<ArticleDiv
																	style={{
																		paddingLeft:
																			"15px",
																	}}
																>
																	<ArticleHeading>
																		{
																			item?.name
																		}
																	</ArticleHeading>
																	{lexiconSubtitle ===
																	item?.name ? (
																		<AddMoreDiv
																			onClick={() => {
																				setLexiconSubtitle(
																					""
																				);
																			}}
																		>
																			<AddMore
																				src={
																					require("./../../../assets/icons/less.svg")
																						.default
																				}
																			/>
																		</AddMoreDiv>
																	) : (
																		<AddMoreDiv
																			onClick={() => {
																				setLexiconSubtitle(
																					item.name
																				);
																			}}
																		>
																			<AddMore
																				src={
																					require("./../../../assets/icons/add.svg")
																						.default
																				}
																			/>
																		</AddMoreDiv>
																	)}
																</ArticleDiv>

																{lexiconSubtitle ===
																	item.name && (
																	<>
																		<div
																			style={{
																				paddingLeft:
																					"30px",
																			}}
																		>
																			<div
																				className={
																					"parentHover"
																				}
																			>
																				{renderDefinition(
																					item,
																					"2st"
																				)}
																			</div>
																		</div>
																		<>
																			{item.lexicon.map(
																				(
																					lastItem
																				) => (
																					<>
																						<ArticleDiv
																							style={{
																								paddingLeft:
																									"35px",
																							}}
																						>
																							<ArticleHeading>
																								{
																									lastItem?.name
																								}
																							</ArticleHeading>
																						</ArticleDiv>
																						<div
																							style={{
																								paddingLeft:
																									"35px",
																							}}
																						>
																							<div
																								className={
																									"parentHover"
																								}
																							>
																								{renderDefinition(
																									lastItem,
																									"3rd"
																								)}
																							</div>
																						</div>
																					</>
																				)
																			)}
																		</>
																	</>
																)}
															</>
														)
													)}
												</>
											)}
										</ArticleMainDiv>
									))}
								</ReferanceDiv>
							) : (
								<ReferanceDiv>
									{references.map((data) => (
										<RefLinksDiv>
											<LinkHeading>
												{data.heading}
											</LinkHeading>
											{data.site_links.map((item) => (
												<LinkDiv>
													<LinkImage
														src={
															require("./../../../assets/icons/link.svg")
																.default
														}
													/>
													<LinkText
														to={{
															pathname: item.link,
														}}
														target="_blank"
													>
														{item.title}
													</LinkText>
												</LinkDiv>
											))}
										</RefLinksDiv>
									))}
								</ReferanceDiv>
							)}
						</>
					</ArticleInnerContainer>
				</ReferDiv>
			</RightReferenceContainer>
		</MainContainer>
	);
}

const MainContainer = styled.section`
	display: flex;
	background: #171617;
	height: 100vh;
	position: relative;
	z-index: 0;
	width: 100%;
`;
const ReportContainer = styled.div`
	width: 58%;
	background: #171617;
	margin-left: 25%;
`;
const InnerContainer = styled.div`
	background: #222222;
	// height: 100vh;
	border-radius: 8px;
	margin: 10px;
`;
const ReportHead = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 65px 20px 50px;
`;
const Left = styled.div`
	display: flex;
	align-items: center;
	// width: 30%;
`;
const LogoImage = styled.img`
	height: 90px;
	width: 90px;
	margin-right: 15px;
`;
const MainText = styled.h3`
	color: #fff;
	font-size: 17px;
	line-height: 24px;
	font-family: "ramblaregular";
	@media (max-width: 1280px) {
		font-size: 16px;
	}
`;
const Right = styled.div`
	width: 55%;
`;

const LeftDetails = styled.ul`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
`;
const List = styled.li`
	width: 48%;
	display: flex;
	margin-bottom: 10px;
	:nth-last-child(-n + 2) {
		margin-bottom: 0px;
	}
	:nth-last-child(odd) {
		margin-left: 5px;
	}
`;
const LeftSection = styled.h3`
	font-size: 14px;
	font-family: "poppinsregular";
	color: #8d8d8d;
	width: 40%;
	margin-right: 5px;
	@media (max-width: 1280px) {
		font-size: 13px;
	}
`;
const RightSection = styled.h4`
	font-size: 14px;
	color: #fff;
	font-family: "poppinsregular";
	width: 47%;
	text-align: left;
	text-transform: capitalize;
	@media (max-width: 1280px) {
		font-size: 13px;
	}
`;

const ReportContent = styled.div`
	background: #222222;
	padding: 10px 30px;
	height: calc(100vh - 230px);
	overflow: scroll;
	&::-webkit-scrollbar {
		display: none;
		-ms-overflow-style: none;
		scrollbar-width: none;
	}
	scrollbar-width: none;
`;

const ReportBottom = styled.div`
	background: #222222;
	padding: 0px 60px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 80px;
`;
const BottomLeft = styled.div`
	display: flex;
	align-items: center;
`;
const SignedText = styled.h5`
	font-family: "poppinsregular";
	font-style: italic;
	margin-right: 10px;
	color: #a3a3a3;
`;
const DoctorName = styled.h4`
	font-family: "poppinsregular";
	color: #f44b4b;
	font-size: 15px;
`;
const DownloadButton = styled.a`
	display: flex;
	align-items: center;
	border: 1px solid grey;
	padding: 10px;
	border-radius: 5px;
	color: #a3a3a3;
	font-size: 15px;
	height: 40px;
	cursor: pointer;
`;
const DownloadImage = styled.img`
	height: 15px;
	margin-right: 10px;
`;

const ReportInnerContainer = styled.div`
	border-radius: 8px;
	background: #171617;
	min-height: calc(100vh - 250px);
	padding-top: 20px;
`;
const TopBar = styled.div`
	display: flex;
	width: 100%;
	justify-content: right;
	margin-bottom: 20px;
`;
const ContentAlignControl = styled.ul`
	display: flex;
	align-items: center;
	height: 40px;
	border: 1px solid grey;
	border-radius: 5px;
	margin-top: 20px;
	margin-right: 20px;
	position: relative;
`;
const ControlIcon = styled.li`
	margin-right: 20px;
	&:first-child {
		margin-left: 20px;
	}
`;
const TitleDiv = styled.div`
	// color: #c4c4c4;
	display: flex;
	align-items: center;
	cursor: pointer;
`;
const Subtitle = styled.h4`
	color: #c4c4c4;
	margin-right: 7px;
	font-family: "poppinsregular";
	font-size: 16px;
`;
const FieldOption = styled.div`
	background-color: #333333;
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: absolute;
	top: 40px;
	left: 0;
`;
const Option = styled.h4`
	color: #fff;
	font-size: 15px;
	font-family: "poppinsregular";
	height: 50px;
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 0 15px;
	:hover {
		background-color: #424242;
	}
	:first-child {
		border-top-left-radius: 12px;
		border-top-right-radius: 12px;
	}
	:last-child {
		border-bottom-left-radius: 12px;
		border-bottom-right-radius: 12px;
	}
	@media (max-width: 1280px) {
		font-size: 14px;
	}
`;
const BoldIcon = styled.img``;
const ItalicIcon = styled(BoldIcon)``;
const LeftIcon = styled(BoldIcon)``;
const UnderlineIcon = styled(BoldIcon)``;
const CenterIcon = styled(BoldIcon)``;
const RightIcon = styled(BoldIcon)``;
const JustifyIcon = styled(BoldIcon)``;
const ImageIcon = styled(BoldIcon)``;
const HyperlinkIcon = styled(BoldIcon)``;
const TextInput = styled.input`
	margin: 10px 0;
	padding: 0 30px;
	color: #fff;
	// background-color: red;
	width: 100%;
`;
const Buttons = styled.div`
	padding-left: 30px;
	margin-top: 20px;
`;
const ButtonSubtitle = styled(Link)`
	margin-right: 20px;
	padding: 10px;
	border: 1px solid grey;
	border-radius: 5px;
	color: grey;
	font-size: 15px;
	@media (max-width: 1280px) {
		font-size: 14px;
	}
`;
const ButtonParagraph = styled(ButtonSubtitle)``;

const RightReferenceContainer = styled.div`
	width: 25%;
	padding: 10px;
	background: #222222;
	/* position: relative; */
`;
const ArticleInnerContainer = styled.div`
	background: #171617;
	border-radius: 8px;
	padding: 10px;
	height: calc(100vh - 90px);
	overflow: scroll;
	&::-webkit-scrollbar {
		display: none;
		-ms-overflow-style: none;
		scrollbar-width: none;
	}
	scrollbar-width: none;
`;
const MenuContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	height: 70px;
	align-items: center;
`;
const MenuDiv = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background: #171617;
	border-radius: 50%;
	height: 40px;
	width: 40px;
	cursor: pointer;
`;
const ReferDiv = styled.div`
	overflow: scroll;
	&::-webkit-scrollbar {
		display: none;
		-ms-overflow-style: none;
		scrollbar-width: none;
	}
	scrollbar-width: none;
	height: calc(100vh - 90px);
`;
const MenuBar = styled.img`
	height: 15px;
	width: 15px;
`;
const LateralityOption = styled.div`
	// display: flex;
	// flex-wrap: wrap;
	// justify-content: space-between;
	// margin-bottom: 10px;
	padding: 8px;
	background: #0e0d0e;
	border-radius: 10px;
	position: absolute;
	width: 95%;
`;
const OptionDrawer = styled.h4`
	color: #fff;
	font-size: 15px;
	font-family: "poppinsregular";
	height: 50px;
	display: flex;
	align-items: center;
	cursor: pointer;
	border-radius: 10px;
	padding: 0 15px;
	:hover {
		background-color: #404040;
	}
	// :first-child {
	// 	border-top-left-radius: 10px;
	// 	border-top-right-radius: 10px;
	// }
	// :last-child {
	// 	border-bottom-left-radius: 10px;
	// 	border-bottom-right-radius: 10px;
	// }
	@media (max-width: 1280px) {
		font-size: 14px;
	}
`;

const ImageContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: 10px;
`;
const Image = styled.img`
	width: 32%;
	:hover {
		border: 10px solid;
		border-image-slice: 1;
		border-width: 5px;
		border-image-source: linear-gradient(to bottom, #00eed2, #00ade5);
	}
`;

const ReferanceHeadDiv = styled.div`
	/* margin: 10px; */
	background: #222222;
	border-radius: 8px;
	margin-bottom: 10px;
	display: flex;
	/* justify-content: space-between; */
	align-items: center;
	height: 50px;
	padding: 0 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const SearchDiv = styled.div`
	// margin: 10px;
`;
const Search = styled.img`
	// margin: 10px;
`;
const ArticleMainDiv = styled.div`
	// display: flex;
	// justify-content: space-between;
	// align-items: center;
	/* padding: 5px 10px; */
	border-radius: 5px;
	border-bottom: 1px solid #555555;
`;
const ArticleDiv = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 5px 0;
	margin-bottom: 5px;
`;
const ArticleHeading = styled.h5`
	// margin: 10px;
	color: #fff;
	font-size: 16px;
	line-height: 22px;
	width: 90%;
`;
const AddMoreDiv = styled.div`
	// display: flex;
	// justify-content: space-between;
	// align-items: center;
	// padding: 5px 0;
	// background: red;
	cursor: pointer;
`;
const AddMore = styled.img`
	// margin: 10px;
`;
const DefinitionDiv = styled.div`
	// display: flex;
	// justify-content: space-between;
	// align-items: center;
	padding: 5px 0;
	display: inline;
`;
const Definition = styled.span`
	color: #a3a3a3;
	font-size: 14px;
	width: 95%;
	line-height: 20px;
`;
const LexiconImageDiv = styled.div`
	/* display: flex;
	justify-content: space-between;
	align-items: center;
	// padding: 5px 0;
	height: 100%;
	width: 100%;
	/* display: none; */
	/* transition: 0.3s; */
`;
const LexiconImage = styled.img`
	// height: 100%;
	// width: 100%;
	border-radius: 5px;
	width: 150px;
	height: 150px;
`;

const Line = styled.div`
	height: 1px;
	background: #555555;
	width: 100%;
	margin: 20px 0;
`;
const ArticleTextDiv = styled.div`
	// display: flex;
	// justify-content: space-between;
	// align-items: center;
	padding: 0 15px;
	margin-top: 5px;
`;
const ArticleText = styled.h5`
	// margin: 10px;
	color: #a3a3a3;
	font-size: 14px;
	line-height: 20px;
	width: 95%;
`;

const ReferanceInnerContainer = styled.div`
	background: #171617;
	border-radius: 8px;
	padding: 10px;
	height: calc(100vh - 35vh - 100px);
	overflow: scroll;
	&::-webkit-scrollbar {
		display: none;
		-ms-overflow-style: none;
		scrollbar-width: none;
	}
	scrollbar-width: none;
	margin-top: 10px;
`;

const ReferanceDiv = styled.div`
	// display: flex;
	// justify-content: space-between;
	// align-items: center;
	// padding: 0 15px;
`;
const RefLinksDiv = styled.div`
	// display: flex;
	// justify-content: space-between;
	// align-items: center;
	// padding: 0 15px;
`;
const LinkHeading = styled.h3`
	color: #fff;
	font-size: 16px;
	margin-bottom: 10px;
	margin-top: 15px;
`;
const LinkDiv = styled.div`
	display: flex;
	// justify-content: space-between;
	align-items: center;
	// padding: 0 15px;
`;
const LinkImage = styled.img``;
const LinkText = styled(Link)`
	color: #fff;
	font-size: 14px;
	margin-left: 10px;
	margin-bottom: 8px;
	word-break: break-all;
	&:hover {
		color: #22ade5;
	}
`;

const HoverImage = styled.img``;
const ImageHoverText = styled.h4`
	/* cursor: pointer; */
`;
const HeadContainer = styled.div`
	width: 48%;
	height: 35px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	background-color: #343434;
	cursor: pointer;
	border-radius: 4px;
`;

const Head = styled.h4`
	color: #22ade5;
	font-size: 15px;
	text-align: center;
	user-select: none;
	@media (max-width: 1280px) {
		font-size: 14px;
	}
`;
const Radiographic = styled.ul``;
const Titletext = styled.h5`
	color: #22ade5;
	font-size: 15px;
	margin-bottom: 10px;
	@media (max-width: 1280px) {
		font-size: 14px;
	}
`;
const ListItem = styled.li`
	color: #a3a3a3;
	list-style-type: disc;
	margin-left: 20px;
	line-height: 25px;
	font-size: 15px;
	:last-child {
		margin-bottom: 10px;
	}
	@media (max-width: 1280px) {
		font-size: 14px;
	}
`;
const MRI = styled(Radiographic)``;
const DifferentialDiagnosis = styled(Radiographic)``;
const TitleTextDiv = styled.div`
	padding: 0 30px;
	margin-bottom: 10px;
`;
const FormTitleText = styled.h5`
	color: #fff;
	font-family: "poppinsregular";
	font-size: 16px;
`;
const ParagraphTextDiv = styled.div`
	padding: 0 60px;
`;
const ParagraphText = styled.h6`
	color: #a3a3a3;
	font-family: "poppinsregular";
	font-size: 14px;
	margin-bottom: 5px;
`;
const PdfSave = styled.div`
	display: flex;
	align-items: center;
`;
const ButtonDiv = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-right: 10px;
	height: 40px;
`;
const SubmitButton = styled.div`
	padding: 10px 22px;
	color: #fff;
	background: #00ace2;
	border-radius: 5px;
	font-size: 16px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const Ul = styled.div`
	display: flex;
	/* align-items: center; */
`;
const Dot = styled.div`
	height: 10px;
	width: 10px;
	background-color: #a3a3a3;
	display: inline-block;
	margin-right: 8px;
	border-radius: 50%;
`;
const Li = styled.h4`
	font-size: 14px;
`;
