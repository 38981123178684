import React, { useState, useContext, useRef, useEffect } from "react";
import styled from "styled-components";

export default function LeftRightRadio({
	handleFindingFormValues,
	findingFormValues,
}) {
	const position = [
		{
			id: 1,
			value: "left",
			name: "Left",
		},
		{
			id: 2,
			value: "right",
			name: "Right",
		},
	];
	return (
		<RadioContainer>
			{position.map((item) => (
				<RadioDiv
					onClick={() => {
						handleFindingFormValues("position", item.value);
					}}
				>
					<RadioButtonChecked>
						{findingFormValues?.position === item.value && (
							<InnerCircle />
						)}
					</RadioButtonChecked>
					<OptionText>{item.name}</OptionText>
				</RadioDiv>
			))}
		</RadioContainer>
	);
}

const RadioContainer = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	/* justify-content: center; */
`;

const RadioDiv = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	margin-right: 20px;
	:last-child {
		margin-right: 0;
	}
`;
const RadioButtonChecked = styled.div`
	background-color: #203035;
	width: 16px;
	height: 16px;
	min-width: 16px;
	min-height: 16px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
`;
const InnerCircle = styled.div`
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: #00ace2;
`;
const OptionText = styled.h6`
	color: #a3a3a3;
	font-size: 15px;
	margin-bottom: -3px;
`;
