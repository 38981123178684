import React, { useState, useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { baseConfig } from "./../../../axiosConfig";
import { Context } from "./../../../contexts/Store";

export default function AssessmentMangmentModal({
	selectedCategoryItem,
	setAssessmentModal,
	setSelectedCategoryItem,
	getAssessments,
	selectedPosition,
	getSynopticReport,
	position,
}) {
	const [category, setCategory] = useState({});
	const { template, report_id, synoptic_id } = useParams();
	const { state } = useContext(Context);
	const [isLoading, setLoading] = useState(true);

	//modal height
	const modalHeight = useRef(null);

	const [height, setHeight] = useState(0);

	useEffect(() => {
		setHeight(modalHeight.current.clientHeight);
	});

	useEffect(() => {
		selectedCategoryItem?.assessment_data.map((data, i) => {
			if (selectedCategoryItem.active) {
				if (data.active) {
					setCategory(data);
				}
			} else {
				if (i === 0) {
					setCategory(data);
				}
			}

			return data;
		});
		return () => {};
	}, []);

	//CategorySave
	const categorySave = (val) => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.post(
				"/mammogram/assessment-data-save/",
				{
					mammogram_id: synoptic_id,
					assessment_id: category?.id,
					position: val,
				},
				{
					headers: {
						Authorization: "Bearer " + accessToken,
					},
				}
			)
			.then((response) => {
				const { StatusCode, reference_data } = response.data;
				if (StatusCode === 6000) {
					getAssessments();
					getSynopticReport();
					setAssessmentModal(false);
				} else {
					setAssessmentModal(false);
				}
			})
			.catch((error) => {
				setAssessmentModal(false);
			});
	};

	// //position
	// const [coords, setCoords] = useState({ x: 0, y: 0 });

	// const handleMouseMove = (event) => {
	// 	setCoords({
	// 		x: event.clientX - event.target.offsetLeft,
	// 		y: event.clientY - event.target.offsetTop,
	// 	});
	// };

	return (
		<BackContainer
		// onMouseMove={handleMouseMove}
		>
			<Overlay
				onClick={() => {
					setAssessmentModal(false);
					setSelectedCategoryItem({});
				}}
			></Overlay>
			<Modal
				ref={modalHeight}
				style={{
					left: "300px",
					top: position.balanceY > height ? `${position.y}px` : null,

					bottom: position.balanceY <= height - 1 ? `20px` : null,
				}}
			>
				<Container>
					<Head>
						<MainHeading>
							{selectedCategoryItem.category}
						</MainHeading>
						<SaveButton
							onClick={() => {
								categorySave(selectedPosition);
							}}
						>
							Save
						</SaveButton>
					</Head>
					<TitleContainer>
						{selectedCategoryItem?.assessment_data?.map(
							(item, i) => (
								// <h4
								// 	onClick={() => {
								// 		setCategory(item);
								// 	}}
								// >
								// 	a
								// </h4>
								<div key={i}>
									{item.title !== null && (
										<TitleDiv
											onClick={() => {
												setCategory(item);
											}}
										>
											<RadioButtonChecked>
												{category?.title ===
													item.title && (
													<InnerCircle />
												)}
											</RadioButtonChecked>
											<Title>{item.title}</Title>
										</TitleDiv>
									)}
								</div>
							)
						)}
					</TitleContainer>
					<Line></Line>
					<TextContainer>
						<SubHeading>Assessment</SubHeading>
						<Content>{category?.assessment}</Content>
					</TextContainer>
					<TextContainer>
						<SubHeading>Management</SubHeading>
						<Content>{category?.management}</Content>
					</TextContainer>
					<TextContainer>
						<SubHeading>Likelihood of Cancer</SubHeading>
						<Content>{category?.likelihood_of_cancer}</Content>
					</TextContainer>
				</Container>
			</Modal>
		</BackContainer>
	);
}

const BackContainer = styled.div`
	position: fixed;
	transition: 0.3s;
	width: 100%;
	height: 100vh;
	z-index: 1000;
	left: 0;
	top: 0px;
	background: rgba(0, 0, 0, 0.4);
`;
const Overlay = styled.div`
	position: fixed;
	left: 0;
	top: 0px;
	width: 100%;
	cursor: pointer;
	height: 100vh;
`;
const Modal = styled.div`
	margin: 0 auto;
	/* background: #333333; */
	width: calc(25% - 40px);
	/* left: 20%; */
	/* top: 50%; */
	position: absolute;
	border-radius: 12px;
	transition: 0.5s;
	z-index: 101;
`;
const Container = styled.div`
	width: 100%;
	background: #222222;
	border-radius: 8px;
	padding: 20px;
	/* height: 200px;
	overflow-y: scroll; */
`;
const Head = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const Line = styled.div`
	height: 1px;
	background: #555555;
	width: 100%;
	margin: 10px 0 20px 0;
`;
const MainHeading = styled.h3`
	font-family: "poppinsmedium";
	color: #ff4c51;
	font-size: 18px;
`;
const SaveButton = styled.div`
	color: #00ace2;
	font-size: 13px;
	background: #203035;
	padding: 8px 10px;
	border-radius: 8px;
	cursor: pointer;
`;
const TitleContainer = styled.div`
	display: flex;
	align-items: center;
`;
const RadioButtonChecked = styled.div`
	background-color: #203035;
	width: 16px;
	height: 16px;
	min-width: 16px;
	min-height: 16px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
`;
const InnerCircle = styled.div`
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: #00ace2;
`;
const TitleDiv = styled.div`
	background-color: #21292c;
	border-radius: 5px;
	display: flex;
	align-items: center;
	margin-right: 10px;
	height: 30px;
	padding: 0 5px;
	border: 1px solid #21292c;
	cursor: pointer;
`;
const Title = styled.h4`
	color: #a3a3a3;
	font-size: 16px;
`;
const SubHeading = styled.h4`
	font-family: "poppinsmedium";
	color: #00ace2;
	font-size: 15px;
	margin-bottom: 0px;
`;
const TextContainer = styled.div`
	margin-bottom: 15px;
	:last-child {
		margin-bottom: 0;
	}
`;
const Content = styled.p`
	color: #a3a3a3;
	font-size: 14px;
`;
