import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { Context } from "./../../../contexts/Store";
import { Link, useLocation, useHistory, useParams } from "react-router-dom";
import { baseConfig } from "../../../axiosConfig";

export default function Referance() {
	const [drawerModal, setDrawerModal] = useState(false);
	const history = useHistory();
	const toggleDrawer = () => {
		setDrawerModal(!drawerModal);
	};
	const { state } = useContext(Context);

	//Reference
	const [articles, setArticles] = useState([]);
	const [articleId, setArticleId] = useState("");
	const [references, setReferences] = useState([]);

	//Lexicon for
	const [isLexicon, setLexicon] = useState(true);
	const [lexiconSubtitle, setLexiconSubtitle] = useState("");

	//reference api
	const getReference = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.get("/dashboard/articles-references", {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, reference_data, lexicon_data } =
					response.data;
				if (StatusCode === 6000) {
					setReferences(reference_data);
					setArticles(lexicon_data);
				} else {
				}
			})
			.catch((error) => {});
	};

	useEffect(() => {
		getReference();
	}, []);

	const renderDefinition = (data, pos) => {
		return data.definition.map((def, ind) => (
			<>
				{def.slice(0, 2) === "$$" ? (
					<LinkText
						style={{
							marginLeft: "0",
							display: "inline",
						}}
						to={{
							pathname: def.slice(2),
						}}
						target="_blank"
					>
						{def.slice(2)}
					</LinkText>
				) : def.slice(0, 2) === "##" ? (
					<>
						<div className="HoverButton">
							<span className={"hoverHide"}>{def.slice(2)} </span>
							{data.figure !== null && (
								<div className={"test"}>
									<div
										style={{
											position: "absolute",
											bottom: 0,
											top: 0,
											right: 0,
											left: 0,
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											width: "max-content",
											margin: "0 auto",
										}}
									>
										<LexiconImage
											src={data.figure}
											alt="image"
										/>
									</div>
								</div>
							)}
						</div>
					</>
				) : def.slice(0, 2) === "**" ? (
					<Ul>
						<Li>
							<Dot /> {def.slice(2)}
						</Li>
					</Ul>
				) : (
					<>
						<Definition>{def}</Definition>
					</>
				)}
			</>
		));
	};

	return (
		<MainContainer>
			<RightReferenceContainer>
				<MenuContainer>
					<MenuDiv
						onClick={() => {
							toggleDrawer();
						}}
					>
						<MenuBar
							src={
								drawerModal
									? require("../../../assets/icons/cancel.svg")
											.default
									: require("../../../assets/icons/menu-bar.svg")
											.default
							}
							alt="menu"
						/>
					</MenuDiv>
				</MenuContainer>
				<ReferDiv>
					{drawerModal && (
						<LateralityOption>
							{/* <OptionDrawer
							// onClick={() => {
							// 	toggleActiveLaterality();
							// 	setSelectedLaterality(data.title);
							// }}
							>
								New Report
							</OptionDrawer> */}
							<OptionDrawer
								onClick={() => {
									history.push("/patient-report/");
								}}
							>
								Patient History
							</OptionDrawer>
							<OptionDrawer
								onClick={(e) => {
									e.preventDefault();
									localStorage.clear();
									window.location = "/";
								}}
							>
								Log Out
							</OptionDrawer>
						</LateralityOption>
					)}

					<ArticleInnerContainer>
						<ReferanceHeadDiv>
							<HeadContainer
								onClick={() => {
									setLexicon(true);
								}}
								style={{
									backgroundColor: isLexicon
										? "#343434"
										: "#222222",
								}}
							>
								<Head>LEXICON</Head>
							</HeadContainer>

							<HeadContainer
								onClick={() => {
									setLexicon(false);
								}}
								style={{
									backgroundColor: isLexicon
										? "#222222"
										: "#343434",
								}}
							>
								<Head>REFERENCES</Head>
							</HeadContainer>
							{/* <SearchDiv>
								<Search
									src={
										require("./../../../assets/icons/search.svg")
											.default
									}
									alt="search"
								/>
							</SearchDiv> */}
						</ReferanceHeadDiv>
						<>
							{isLexicon ? (
								<ReferanceDiv>
									{articles.map((data, index) => (
										<ArticleMainDiv
											key={index}
											style={{
												background:
													articleId === data.id
														? "#1c1c1c"
														: "#171617",
											}}
										>
											<ArticleDiv>
												<ArticleHeading>
													{/* {data.title} */}
													{data.name}
												</ArticleHeading>
												{articleId === data.name ? (
													<AddMoreDiv
														onClick={() => {
															setArticleId("");
														}}
													>
														<AddMore
															style={{
																marginBottom:
																	"5px",
															}}
															src={
																require("./../../../assets/icons/less.svg")
																	.default
															}
														/>
													</AddMoreDiv>
												) : (
													<AddMoreDiv
														onClick={() => {
															setArticleId(
																data.name
															);
														}}
													>
														<AddMore
															src={
																require("./../../../assets/icons/add.svg")
																	.default
															}
														/>
													</AddMoreDiv>
												)}
											</ArticleDiv>

											{articleId === data.name && (
												<>
													<div
														className={
															"parentHover"
														}
													>
														{renderDefinition(
															data,
															"1st"
														)}
													</div>

													{data.sub_articles.map(
														(item) => (
															<>
																<ArticleDiv
																	style={{
																		paddingLeft:
																			"15px",
																	}}
																>
																	<ArticleHeading>
																		{
																			item?.name
																		}
																	</ArticleHeading>
																	{lexiconSubtitle ===
																	item?.name ? (
																		<AddMoreDiv
																			onClick={() => {
																				setLexiconSubtitle(
																					""
																				);
																			}}
																		>
																			<AddMore
																				src={
																					require("./../../../assets/icons/less.svg")
																						.default
																				}
																			/>
																		</AddMoreDiv>
																	) : (
																		<AddMoreDiv
																			onClick={() => {
																				setLexiconSubtitle(
																					item.name
																				);
																			}}
																		>
																			<AddMore
																				src={
																					require("./../../../assets/icons/add.svg")
																						.default
																				}
																			/>
																		</AddMoreDiv>
																	)}
																</ArticleDiv>

																{lexiconSubtitle ===
																	item.name && (
																	<>
																		<div
																			style={{
																				paddingLeft:
																					"30px",
																			}}
																		>
																			<div
																				className={
																					"parentHover"
																				}
																			>
																				{renderDefinition(
																					item,
																					"2st"
																				)}
																			</div>
																		</div>
																		<>
																			{item.lexicon.map(
																				(
																					lastItem
																				) => (
																					<>
																						<ArticleDiv
																							style={{
																								paddingLeft:
																									"35px",
																							}}
																						>
																							<ArticleHeading>
																								{
																									lastItem?.name
																								}
																							</ArticleHeading>
																						</ArticleDiv>
																						<div
																							style={{
																								paddingLeft:
																									"35px",
																							}}
																						>
																							<div
																								className={
																									"parentHover"
																								}
																							>
																								{renderDefinition(
																									lastItem,
																									"3rd"
																								)}
																							</div>
																						</div>
																					</>
																				)
																			)}
																		</>
																	</>
																)}
															</>
														)
													)}
												</>
											)}
										</ArticleMainDiv>
									))}
								</ReferanceDiv>
							) : (
								<ReferanceDiv>
									{references.map((data) => (
										<RefLinksDiv>
											<LinkHeading>
												{data.heading}
											</LinkHeading>
											{data.site_links.map((item) => (
												<LinkDiv>
													<LinkImage
														src={
															require("./../../../assets/icons/link.svg")
																.default
														}
													/>
													<LinkText
														to={{
															pathname: item.link,
														}}
														target="_blank"
													>
														{item.title}
													</LinkText>
												</LinkDiv>
											))}
										</RefLinksDiv>
									))}
								</ReferanceDiv>
							)}
						</>
					</ArticleInnerContainer>
				</ReferDiv>
			</RightReferenceContainer>
		</MainContainer>
	);
}

const MainContainer = styled.section`
	display: flex;
	background: #171617;
	height: 100vh;
	z-index: 0;
	width: 100%;
`;

const RightReferenceContainer = styled.div`
	width: 100%;
	padding: 10px;
	background: #222222;
	/* position: relative; */
`;
const ArticleInnerContainer = styled.div`
	background: #171617;
	border-radius: 8px;
	padding: 10px;
	height: calc(100vh - 90px);
	overflow: scroll;
	&::-webkit-scrollbar {
		display: none;
		-ms-overflow-style: none;
		scrollbar-width: none;
	}
	scrollbar-width: none;
`;
const MenuContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	height: 70px;
	align-items: center;
`;
const MenuDiv = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background: #171617;
	border-radius: 50%;
	height: 40px;
	width: 40px;
	cursor: pointer;
`;
const ReferDiv = styled.div`
	overflow: scroll;
	&::-webkit-scrollbar {
		display: none;
		-ms-overflow-style: none;
		scrollbar-width: none;
	}
	scrollbar-width: none;
	height: calc(100vh - 90px);
`;
const MenuBar = styled.img`
	height: 15px;
	width: 15px;
`;
const LateralityOption = styled.div`
	// display: flex;
	// flex-wrap: wrap;
	// justify-content: space-between;
	// margin-bottom: 10px;
	padding: 8px;
	background: #0e0d0e;
	border-radius: 10px;
	position: absolute;
	width: 95%;
`;
const OptionDrawer = styled.h4`
	color: #fff;
	font-size: 15px;
	font-family: "poppinsregular";
	height: 50px;
	display: flex;
	align-items: center;
	cursor: pointer;
	border-radius: 10px;
	padding: 0 15px;
	:hover {
		background-color: #404040;
	}
	// :first-child {
	// 	border-top-left-radius: 10px;
	// 	border-top-right-radius: 10px;
	// }
	// :last-child {
	// 	border-bottom-left-radius: 10px;
	// 	border-bottom-right-radius: 10px;
	// }
	@media (max-width: 1280px) {
		font-size: 14px;
	}
`;

const ImageContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: 10px;
`;
const Image = styled.img`
	width: 32%;
	:hover {
		border: 10px solid;
		border-image-slice: 1;
		border-width: 5px;
		border-image-source: linear-gradient(to bottom, #00eed2, #00ade5);
	}
`;

const ReferanceHeadDiv = styled.div`
	/* margin: 10px; */
	background: #222222;
	border-radius: 8px;
	margin-bottom: 10px;
	display: flex;
	/* justify-content: space-between; */
	align-items: center;
	height: 50px;
	padding: 0 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const SearchDiv = styled.div`
	// margin: 10px;
`;
const Search = styled.img`
	// margin: 10px;
`;
const ArticleMainDiv = styled.div`
	// display: flex;
	// justify-content: space-between;
	// align-items: center;
	/* padding: 5px 10px; */
	border-radius: 5px;
	border-bottom: 1px solid #555555;
`;
const ArticleDiv = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 5px 0;
	margin-bottom: 5px;
`;
const ArticleHeading = styled.h5`
	// margin: 10px;
	color: #fff;
	font-size: 16px;
	line-height: 22px;
	width: 90%;
`;
const AddMoreDiv = styled.div`
	// display: flex;
	// justify-content: space-between;
	// align-items: center;
	// padding: 5px 0;
	// background: red;
	cursor: pointer;
`;
const AddMore = styled.img`
	// margin: 10px;
`;
const DefinitionDiv = styled.div`
	// display: flex;
	// justify-content: space-between;
	// align-items: center;
	padding: 5px 0;
	display: inline;
`;
const Definition = styled.span`
	color: #a3a3a3;
	font-size: 14px;
	width: 95%;
	line-height: 20px;
`;
const LexiconImageDiv = styled.div`
	/* display: flex;
	justify-content: space-between;
	align-items: center;
	// padding: 5px 0;
	height: 100%;
	width: 100%;
	/* display: none; */
	/* transition: 0.3s; */
`;
const LexiconImage = styled.img`
	// height: 100%;
	// width: 100%;
	border-radius: 5px;
	width: 150px;
	height: 150px;
`;

const Line = styled.div`
	height: 1px;
	background: #555555;
	width: 100%;
	margin: 20px 0;
`;
const ArticleTextDiv = styled.div`
	// display: flex;
	// justify-content: space-between;
	// align-items: center;
	padding: 0 15px;
	margin-top: 5px;
`;
const ArticleText = styled.h5`
	// margin: 10px;
	color: #a3a3a3;
	font-size: 14px;
	line-height: 20px;
	width: 95%;
`;

const ReferanceInnerContainer = styled.div`
	background: #171617;
	border-radius: 8px;
	padding: 10px;
	height: calc(100vh - 35vh - 100px);
	overflow: scroll;
	&::-webkit-scrollbar {
		display: none;
		-ms-overflow-style: none;
		scrollbar-width: none;
	}
	scrollbar-width: none;
	margin-top: 10px;
`;

const ReferanceDiv = styled.div`
	// display: flex;
	// justify-content: space-between;
	// align-items: center;
	// padding: 0 15px;
`;
const RefLinksDiv = styled.div`
	// display: flex;
	// justify-content: space-between;
	// align-items: center;
	// padding: 0 15px;
`;
const LinkHeading = styled.h3`
	color: #fff;
	font-size: 16px;
	margin-bottom: 10px;
	margin-top: 15px;
`;
const LinkDiv = styled.div`
	display: flex;
	// justify-content: space-between;
	align-items: center;
	// padding: 0 15px;
`;
const LinkImage = styled.img``;
const LinkText = styled(Link)`
	color: #fff;
	font-size: 14px;
	margin-left: 10px;
	margin-bottom: 8px;
	word-break: break-all;
	&:hover {
		color: #22ade5;
	}
`;

const HoverImage = styled.img``;
const ImageHoverText = styled.h4`
	/* cursor: pointer; */
`;
const HeadContainer = styled.div`
	width: 48%;
	height: 35px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	background-color: #343434;
	cursor: pointer;
	border-radius: 4px;
`;

const Head = styled.h4`
	color: #22ade5;
	font-size: 15px;
	text-align: center;
	user-select: none;
	@media (max-width: 1280px) {
		font-size: 14px;
	}
`;
const Radiographic = styled.ul``;
const Titletext = styled.h5`
	color: #22ade5;
	font-size: 15px;
	margin-bottom: 10px;
	@media (max-width: 1280px) {
		font-size: 14px;
	}
`;
const ListItem = styled.li`
	color: #a3a3a3;
	list-style-type: disc;
	margin-left: 20px;
	line-height: 25px;
	font-size: 15px;
	:last-child {
		margin-bottom: 10px;
	}
	@media (max-width: 1280px) {
		font-size: 14px;
	}
`;
const MRI = styled(Radiographic)``;
const DifferentialDiagnosis = styled(Radiographic)``;
const TitleTextDiv = styled.div`
	padding: 0 30px;
	margin-bottom: 10px;
`;
const FormTitleText = styled.h5`
	color: #fff;
	font-family: "poppinsregular";
	font-size: 16px;
`;
const ParagraphTextDiv = styled.div`
	padding: 0 60px;
`;
const ParagraphText = styled.h6`
	color: #a3a3a3;
	font-family: "poppinsregular";
	font-size: 14px;
	margin-bottom: 5px;
`;
const PdfSave = styled.div`
	display: flex;
	align-items: center;
`;
const ButtonDiv = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-right: 10px;
	height: 40px;
`;
const SubmitButton = styled.div`
	padding: 10px 22px;
	color: #fff;
	background: #00ace2;
	border-radius: 5px;
	font-size: 16px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const Ul = styled.div`
	display: flex;
	/* align-items: center; */
`;
const Dot = styled.div`
	height: 10px;
	width: 10px;
	background-color: #a3a3a3;
	display: inline-block;
	margin-right: 8px;
	border-radius: 50%;
`;
const Li = styled.h4`
	font-size: 14px;
`;
