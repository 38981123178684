import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { baseConfig } from "./../../axiosConfig";
import { Context } from "./../../contexts/Store";
import Draggable from "react-draggable";
import FindingDropDown from "./dropdown/FindingDropDown";
import UnitDropDown from "./UnitDropDown";

export default function NoAsymmetryPopup({
	setSelectedModal,
	selectedFindings,
	setSelectedFindings,
	selectedFindingsID,
	findingData,
	report_id,
	getFindingResults,
	handleAsymmetryFormValues,
	asymmetryFormValues,
	setFindingFormValues,
	getFindings,
	assessmentPositions,
	selectedLesionDetails,
	setSelectedLesionDetails,
}) {
	const { state } = useContext(Context);

	const [sizeUnit] = useState([
		{
			id: 1,
			unit: "cm",
			name: "cm",
		},
		{
			id: 1,
			unit: "mm",
			name: "mm",
		},
	]);

	//location nipple unit selection

	const [isLocationUnit, setLocationUnit] = useState(false);
	const [selectedLocationUnit, setSelectedLocationUnit] = useState("cm");
	const toggleLocationUnit = () => setLocationUnit(!isLocationUnit);

	const [selectedFeatures, setSelectedFeatures] = useState("");
	const [selectedOption, setSelectedOption] = useState("");
	const [activeOption, setActiveOption] = useState(false);
	const toggleActiveOption = () => {
		setActiveOption(!activeOption);
	};

	const [selectedQuadrant, setSelectedQuadrant] = useState("");
	const [activeQuadrant, setActiveQuadrant] = useState(false);
	const toggleActiveQuadrant = () => {
		setActiveQuadrant(!activeQuadrant);
	};

	const [selectedThird, setSelectedThird] = useState("");
	const [activeThird, setActiveThird] = useState(false);
	const toggleActiveThird = () => {
		setActiveThird(!activeThird);
	};

	const handleFindings = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.post(
				"/mammogram/findings-text-format/",
				{
					findings_id: selectedFindingsID,
					report_id: report_id,
					clock: asymmetryFormValues?.AsymmetryClock,
					quadrant: asymmetryFormValues?.AsymmetryQuadrant,
					third: asymmetryFormValues?.AsymmetryThird,
					distance: `${asymmetryFormValues?.AsymmetryDistance} ${selectedLocationUnit}`,
					associated_calcification:
						asymmetryFormValues?.AsymmetryCalcification,
					associated_features: asymmetryFormValues?.AsymmetryFeatures,
					type_data: asymmetryFormValues?.AsymmetryType,
					comment: asymmetryFormValues?.AsymmetryComment,
					position: assessmentPositions,
					patient_finding_id: selectedLesionDetails.id
						? selectedLesionDetails.id
						: "",
				},
				{
					headers: {
						Authorization: "Bearer " + accessToken,
					},
				}
			)
			.then((response) => {
				const { StatusCode, message } = response.data;
				if (StatusCode === 6000) {
					setSelectedModal("");
					getFindingResults();
					setFindingFormValues({});
					getFindings();
				} else {
					alert(message);
				}
			})
			.catch((error) => {});
	};

	useEffect(() => {
		if (selectedLesionDetails.id) {
			// setLymphComment(selectedLesionDetails.comment);
			// setSelectedSize(selectedLesionDetails?.size[1]);
			setSelectedLocationUnit(selectedLesionDetails?.distance[1]);
			setFindingFormValues({
				AsymmetryClock: selectedLesionDetails?.clock,
				AsymmetryQuadrant: selectedLesionDetails?.quadrant,
				AsymmetryThird: selectedLesionDetails?.third,
				AsymmetryType: selectedLesionDetails?.type_data,
				AsymmetryDistance: selectedLesionDetails?.distance[0],
				AsymmetryFeatures: selectedLesionDetails?.associated_features,
				AsymmetryComment: selectedLesionDetails?.comment,
				AsymmetryCalcification:
					selectedLesionDetails?.associated_calcification,
				// MassSizeLength: selectedLesionDetails.size[0].split("x")[0],
				// MassSizeWidth: selectedLesionDetails.size[0].split("x")[1],
				// MassDistanceUnit: editModalData?.distance[1],
			});
		} else {
			// setLymphComment("");
			setFindingFormValues({
				AsymmetryClock: "",
				AsymmetryQuadrant: "",
				AsymmetryThird: "",
				MassShape: "",
				MassDistance: "",
				MassMargin: "",
				MassDensity: "",
				MassComment: "",
				MassSizeLength: "",
				MassSizeWidth: "",
			});
			// setSelectedSize("cm");
			setSelectedLocationUnit("cm");
		}
		return () => {
			setSelectedLesionDetails({});
		};
	}, []);

	return (
		<BackContainer>
			<Overlay
				onClick={() => {
					setSelectedModal("");
				}}
			></Overlay>
			<Draggable handle="#handle" defaultPosition={{ x: 0, y: 300 }}>
				<Modal>
					<MovementBar id="handle">
						<DotDiv>
							<Dot />
							<Dot />
							<Dot />
							<Dot />
							<Dot />
						</DotDiv>
					</MovementBar>
					<Container>
						<Head>
							<MainHeading>Asymmetry</MainHeading>
							<ButtonDiv>
								<Cancel
									onClick={() => {
										setSelectedFindings(
											selectedFindings.filter(
												(data) =>
													data !== selectedFindingsID
											)
										);
										setSelectedModal("");
										setFindingFormValues({});
									}}
								>
									Cancel
								</Cancel>
								<SaveButton
									onClick={() => {
										handleFindings();
									}}
								>
									Save
								</SaveButton>
							</ButtonDiv>
						</Head>
						<BodyDiv>
							<TypeContainer>
								<Line></Line>
								<SubHeading>1. Type</SubHeading>
								<BottomContainer>
									{findingData.parameters
										.filter(
											(data) => data.name == "Type"
										)[0]
										.choices.map((data) => (
											<SelectContainer
												onClick={() => {
													handleAsymmetryFormValues(
														"AsymmetryType",
														data.id
													);
												}}
											>
												<RadioButtonChecked>
													{asymmetryFormValues?.AsymmetryType ===
														data.id && (
														<InnerCircle />
													)}
												</RadioButtonChecked>
												<OptionText>
													{data.name}
												</OptionText>
											</SelectContainer>
										))}
								</BottomContainer>
							</TypeContainer>
							<LocationContainer>
								<Line></Line>
								<SubHeading>2. Location</SubHeading>
								<OptionContainer>
									<Unit>
										{/* <OptionBox
											onClick={() => {
												toggleActiveOption();
											}}
										>
											<Text>
												{
													asymmetryFormValues
														?.AsymmetryClock?.name
												}
											</Text>
											<Arrow
												src={
													require("../../assets/icons/arrow-down.svg")
														.default
												}
												alt="arrow"
											/>
										</OptionBox>
										{activeOption && (
											<Dropdown>
												{findingData.parameters
													.filter(
														(data) =>
															data.name ==
															"Location"
													)[0]
													.sub_parameters.filter(
														(item) =>
															item.name ==
															"o'clock"
													)[0]
													.choices.map((data) => (
														<DropdownOption
															onClick={() => {
																toggleActiveOption();
																// setSelectedOption(
																// 	data.name
																// );
																handleAsymmetryFormValues(
																	"AsymmetryClock",
																	data
																);
															}}
														>
															{data.name}
														</DropdownOption>
													))}
											</Dropdown>
										)} */}
										<FindingDropDown
											items={
												findingData.parameters
													.filter(
														(data) =>
															data.name ==
															"Location"
													)[0]
													.sub_parameters.filter(
														(item) =>
															item.name ==
															"o'clock"
													)[0].choices
											}
											selectedValue={asymmetryFormValues}
											setSelectedValue={
												handleAsymmetryFormValues
											}
											label={"AsymmetryClock"}
										/>
										<UnitText>o'clock,</UnitText>
									</Unit>
									<Unit>
										{/* <OptionBox
											onClick={() => {
												toggleActiveQuadrant();
											}}
										>
											<Text>
												{
													asymmetryFormValues
														?.AsymmetryQuadrant
														?.name
												}
											</Text>
											<Arrow
												src={
													require("../../assets/icons/arrow-down.svg")
														.default
												}
												alt="arrow"
											/>
										</OptionBox>
										{activeQuadrant && (
											<Dropdown>
												{findingData.parameters
													.filter(
														(data) =>
															data.name ==
															"Location"
													)[0]
													.sub_parameters.filter(
														(item) =>
															item.name ==
															"Quadrant"
													)[0]
													.choices.map((data) => (
														<DropdownOption
															onClick={() => {
																toggleActiveQuadrant();
																handleAsymmetryFormValues(
																	"AsymmetryQuadrant",
																	data
																);
															}}
														>
															{data.name}
														</DropdownOption>
													))}
											</Dropdown>
										)} */}
										<FindingDropDown
											items={
												findingData.parameters
													.filter(
														(data) =>
															data.name ==
															"Location"
													)[0]
													.sub_parameters.filter(
														(item) =>
															item.name ==
															"Quadrant"
													)[0].choices
											}
											selectedValue={asymmetryFormValues}
											setSelectedValue={
												handleAsymmetryFormValues
											}
											label={"AsymmetryQuadrant"}
										/>
										<UnitText>Quadrant,</UnitText>
									</Unit>
									<Unit>
										{/* <OptionBox
											onClick={() => {
												toggleActiveThird();
											}}
										>
											<Text>
												{
													asymmetryFormValues
														?.AsymmetryThird?.name
												}
											</Text>
											<Arrow
												src={
													require("../../assets/icons/arrow-down.svg")
														.default
												}
												alt="arrow"
											/>
										</OptionBox>
										{activeThird && (
											<Dropdown>
												{findingData.parameters
													.filter(
														(data) =>
															data.name ==
															"Location"
													)[0]
													.sub_parameters.filter(
														(item) =>
															item.name == "Third"
													)[0]
													.choices.map((data) => (
														<DropdownOption
															onClick={() => {
																toggleActiveThird();
																handleAsymmetryFormValues(
																	"AsymmetryThird",
																	data
																);
															}}
														>
															{data.name}
														</DropdownOption>
													))}
											</Dropdown>
										)} */}
										<FindingDropDown
											items={
												findingData.parameters
													.filter(
														(data) =>
															data.name ==
															"Location"
													)[0]
													.sub_parameters.filter(
														(item) =>
															item.name == "Third"
													)[0].choices
											}
											selectedValue={asymmetryFormValues}
											setSelectedValue={
												handleAsymmetryFormValues
											}
											label={"AsymmetryThird"}
										/>
										<UnitText>Third,</UnitText>
									</Unit>
									<Unit>
										{/* <CentimeterBox
										> */}
										<DimensionBox
											type={"number"}
											onChange={(e) => {
												handleAsymmetryFormValues(
													"AsymmetryDistance",
													e.target.value
												);
											}}
											value={
												asymmetryFormValues?.AsymmetryDistance
											}
										/>
										<UnitDropDown
											items={sizeUnit}
											selectedValue={selectedLocationUnit}
											setSelectedValue={
												setSelectedLocationUnit
											}
										/>
										{/* <CentimeterText>cm</CentimeterText> */}
										{/* </CentimeterBox> */}
										{/* <div style={{ position: "relative" }}>
											<UnitBox
												onClick={() => {
													toggleLocationUnit();
												}}
											>
												<Text>
													{selectedLocationUnit}
												</Text>
												<Arrow
													src={
														require("../../assets/icons/arrow-down.svg")
															.default
													}
													alt="arrow"
												/>
											</UnitBox>

											{isLocationUnit && (
												<Dropdown
												// style={{ top: "160px", left: "200px" }}
												>
													{sizeUnit.map((data) => (
														<DropdownOption
															onClick={() => {
																toggleLocationUnit();
																setSelectedLocationUnit(
																	data.unit
																);
															}}
														>
															{data.unit}
														</DropdownOption>
													))}
												</Dropdown>
											)}
										</div> */}
										<UnitText>from Nipple</UnitText>
									</Unit>
								</OptionContainer>
							</LocationContainer>
							<AssociatedCalcifications>
								<Line></Line>
								<SubHeading>
									3. Associated calcification
								</SubHeading>
								<ButtonContainer>
									{findingData.parameters
										.filter(
											(data) =>
												data.name ==
												"Associated calcification"
										)[0]
										.choices.map((item) => (
											<Button
												style={{
													marginRight: "10px",
													border:
														asymmetryFormValues?.AsymmetryCalcification ===
														item.id
															? "1px solid #22ade5"
															: "1px solid #2d4349",
												}}
												onClick={() => {
													handleAsymmetryFormValues(
														"AsymmetryCalcification",
														item.id
													);
												}}
											>
												{item.name}
											</Button>
										))}
								</ButtonContainer>
							</AssociatedCalcifications>
							<FeaturesContainer>
								<Line></Line>
								<SubHeading>4. Features</SubHeading>
								<BottomContainer>
									{findingData.parameters
										.filter(
											(data) =>
												data.name ==
												"Associated features"
										)[0]
										.choices.map((data) => (
											<SelectContainerFeatures
												onClick={() => {
													handleAsymmetryFormValues(
														"AsymmetryFeatures",
														data.id
													);
												}}
											>
												<RadioButtonChecked>
													{asymmetryFormValues?.AsymmetryFeatures ===
														data.id && (
														<InnerCircle />
													)}
												</RadioButtonChecked>
												<OptionText>
													{data.name}
												</OptionText>
											</SelectContainerFeatures>
										))}
								</BottomContainer>
							</FeaturesContainer>
							<Comment>
								<Line></Line>
								<SubHeading>5. Comment</SubHeading>
								<TextArea
									placeholder="Write Here..."
									onChange={(e) => {
										handleAsymmetryFormValues(
											"AsymmetryComment",
											e.target.value
										);
									}}
									value={
										asymmetryFormValues?.AsymmetryComment
									}
								/>
							</Comment>
						</BodyDiv>
					</Container>
				</Modal>
			</Draggable>
		</BackContainer>
	);
}

const BackContainer = styled.div`
	position: fixed;
	transition: 0.3s;
	width: 100%;
	height: 100vh;
	z-index: 1000;
	left: 0;
	top: 0px;
	background: rgba(0, 0, 0, 0.4);
`;
const Overlay = styled.div`
	position: fixed;
	left: 0;
	top: 0px;
	width: 100%;
	cursor: pointer;
	height: 100vh;
`;
const Modal = styled.div`
	// margin: 0 auto;
	background: #222222;
	width: calc(25% - 40px);
	left: 20%;
	top: 20%;
	position: absolute;
	border-radius: 12px;
	transition: 0.1s;
	z-index: 101;
	width: 500px;
	// padding: 20px;
	height: 500px;
	overflow-y: auto;
	user-select: none;
	scrollbar-width: 16px;
`;

const MovementBar = styled.div`
	background: linear-gradient(360deg, #292929 0%, #4b4b4b 100%);
	height: 30px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: move;
`;
const DotDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;
const Dot = styled.div`
	height: 8px;
	width: 8px;
	border-radius: 50%;
	background-color: #212121;
	margin-right: 5px;
`;
const Container = styled.div`
	margin: 20px;
	margin-right: 5px;
`;
const Head = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 30px;
	margin-right: 15px;
`;
const BodyDiv = styled.div`
	height: calc(500px - 100px);
	overflow-y: scroll;
	padding-right: 10px;
	&::-webkit-scrollbar {
		width: 8px;
		border: 1px solid #222;
		background: #222;
		border-radius: 5px;
	}
	::-webkit-scrollbar-thumb:hover {
		background: #00ace2;
	}

	::-webkit-scrollbar-thumb {
		background: #00ace2;
		border-radius: 10px;
	}
`;
const Line = styled.div`
	height: 1px;
	background: #555555;
	width: 100%;
	margin: 20px 0;
`;
const MainHeading = styled.h3`
	font-family: "poppinsmedium";
	color: #ff4c51;
	font-size: 18px;
`;
const ButtonDiv = styled.div`
	display: flex;
`;
const Cancel = styled.div`
	color: #00ace2;
	font-size: 13px;
	// background: #203035;
	padding: 8px 10px;
	border-radius: 8px;
	cursor: pointer;
	margin-right: 10px;
`;
const SaveButton = styled.div`
	color: #00ace2;
	font-size: 13px;
	background: #203035;
	padding: 8px 10px;
	border-radius: 8px;
	cursor: pointer;
`;
const SubHeading = styled.h4`
	font-family: "poppinsmedium";
	color: #00ace2;
	font-size: 14px;
	margin-bottom: 15px;
`;
const Text = styled.h5`
	margin-right: 10px;
	font-size: 13px;
`;
const Arrow = styled.img`
	height: 5px;
`;
const LocationContainer = styled.div``;
const OptionContainer = styled.div`
	padding-left: 20px;
	display: grid !important;
	grid-template-columns: 1fr 1fr;
`;
const Unit = styled.div`
	display: flex;
	align-items: center;
	color: #a3a3a3;
	/* width: 50%; */
	margin-bottom: 10px;
	position: relative;
	:nth-last-child(-n + 2) {
		margin-bottom: 0;
	}
`;
const DimensionBox = styled.input`
	padding: 0px 10px;
	width: 60px;
	background: #223035;
	border: 1px solid #30434a;
	border-radius: 5px;
	color: #fff;
	font-size: 14px;
	height: 40px;
	margin-right: 10px;
	&:focus {
		border-color: #aaa;
	}
`;
const CentimeterBox = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #203035;
	border: 1px solid #2d4349;
	padding: 8px 10px;
	border-radius: 5px;
`;
const Centimeter = styled.input`
	width: 25px;
	color: #fff;
	font-size: 14px;
`;
const CentimeterText = styled.h5`
	font-size: 14px;
	color: #fff;
`;
const OptionBox = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px 10px;
	height: 30px;
	width: 100px;
	background: #203035;
	border: 1px solid #2d4349;
	border-radius: 5px;
	color: #fff;
	font-size: 14px;
	cursor: pointer;
`;
const Dropdown = styled.div`
	background-color: #333333;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: absolute;
	top: 30px;
	width: 100px;
	z-index: 60;
`;
const DropdownOption = styled.h4`
	color: #fff;
	font-size: 15px;
	font-family: "poppinsregular";
	height: 40px;
	display: flex;
	align-items: center;
	cursor: pointer;
	text-align: center;
	padding: 0 10px;
	:hover {
		background-color: #424242;
	}
	:first-child {
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}
	:last-child {
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
	}
	@media (max-width: 1280px) {
		font-size: 14px;
	}
`;
const UnitText = styled.h6`
	color: #a3a3a3;
	font-size: 14px;
	margin-left: 10px;
`;
const TypeContainer = styled.div``;
const BottomContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
`;
const SelectContainer = styled.div`
	display: flex;
	margin-bottom: 10px;
	cursor: pointer;
	width: 48%;
	:last-child {
		margin-bottom: 0;
	}
`;
const SelectContainerFeatures = styled(SelectContainer)`
	width: 60%;
`;
const RadioButtonChecked = styled.div`
	background-color: #203035;
	width: 16px;
	height: 16px;
	min-width: 16px;
	min-height: 16px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
`;
const InnerCircle = styled.div`
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: #00ace2;
`;
const OptionText = styled.h6`
	color: #a3a3a3;
	font-size: 14px;
	// margin-bottom: 10px;
`;
const FeaturesContainer = styled.div``;
const AssociatedCalcifications = styled.div``;
const ButtonContainer = styled.div`
	display: flex;
	padding-left: 20px;
`;
const Button = styled.div`
	width: 60px;
	padding: 8px 0;
	text-align: center;
	background: #203035;
	border: 1px solid #2d4349;
	border-radius: 5px;
	color: #fff;
	font-size: 14px;
	cursor: pointer;
	:hover {
		border: 1px solid #22ade5;
	}
`;
const Comment = styled.div``;
const TextArea = styled.textarea`
	border: 1px solid #5d5c5d;
	border-radius: 8px;
	padding: 10px;
	color: #fff;
	background: #203035;
	width: 100%;
	resize: vertical;
	height: 100px;
	::placeholder {
		font-family: "poppinsregular";
		color: #4d595d;
	}
	&:focus {
		border-color: #aaa;
	}
`;

const UnitBox = styled.div`
	display: flex;
	/* justify-content: space-between; */
	align-items: center;
	border: 1px solid #323232;
	border-radius: 3px;
	color: #fff;
	font-size: 16px;
	/* width: 60px; */
	padding: 4px;
	cursor: pointer;
`;
