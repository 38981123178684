import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { baseConfig } from "./../../../axiosConfig";
import { Context } from "./../../../contexts/Store";

export default function Indication({ getSynopticReport, synopticReport }) {
	const [indication, setIndication] = useState("");
	const { template, report_id, synoptic_id } = useParams();
	const { state } = useContext(Context);

	const submitAssessment = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.post(
				"/dashboard/indication-text-save/",
				{
					report_id: report_id,
					indication_text: indication,
				},
				{
					headers: {
						Authorization: "Bearer " + accessToken,
					},
				}
			)
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					getSynopticReport();
					// setIndicationResult(indication);
				} else {
				}
			})
			.catch((error) => {});
	};

	useEffect(() => {
		setIndication(synopticReport.indication_for_examination);
		return () => {};
	}, [synopticReport]);

	return (
		<MainContainer>
			<Heading>1. INDICATION FOR EXAMINATION</Heading>
			<TextArea
				value={indication}
				onChange={(e) => {
					setIndication(e.target.value);
				}}
				placeholder="Write Here..."
			/>
			<ButtonDiv>
				<SubmitButton
					onClick={() => {
						if (indication === "") {
							alert("Fill indication field");
						} else {
							submitAssessment();
						}
					}}
				>
					Save
				</SubmitButton>
			</ButtonDiv>
		</MainContainer>
	);
}

const MainContainer = styled.section`
	z-index: 0;
	width: 100%;
`;

const Heading = styled.h5`
	color: #22ade5;
	font-size: 15px;
	margin-bottom: 10px;
`;

const TextArea = styled.textarea`
	border: 1px solid #5d5c5d;
	border-radius: 8px;
	padding: 10px;
	width: 100%;
	resize: vertical;
	color: #fff;
	min-height: 100px;
	::placeholder {
		font-family: "poppinsregular";
		color: #4d595d;
	}
`;

const ButtonDiv = styled.div`
	display: flex;
	justify-content: flex-end;
`;

const SubmitButton = styled.div`
	padding: 0 22px;
	height: 40px;
	color: #fff;
	background: #00ace2;
	border-radius: 5px;
	font-size: 16px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
`;
