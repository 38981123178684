import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { baseConfig } from "./../../axiosConfig";
import Draggable from "react-draggable";
import { Context } from "./../../contexts/Store";
import UnitDropDown from "./UnitDropDown";
import FindingDropDown from "./dropdown/FindingDropDown";

export default function NoIntramammaryNodesPopup({
	setSelectedModal,
	selectedFindings,
	setSelectedFindings,
	selectedFindingsID,
	findingData,
	report_id,
	getFindingResults,
	intramammaryFormValues,
	handleIntramammaryFormValues,
	setFindingFormValues,
	getFindings,
	assessmentPositions,
	selectedLesionDetails,
	setSelectedLesionDetails,
}) {
	const { state } = useContext(Context);
	const [selectedItem, setSelectedItem] = useState("cm");

	const [selectedOption, setSelectedOption] = useState("");
	const [activeOption, setActiveOption] = useState(false);
	const toggleActiveOption = () => {
		setActiveOption(!activeOption);
	};

	const [sizeUnit] = useState([
		{
			id: 1,
			unit: "cm",
			name: "cm",
		},
		{
			id: 1,
			unit: "mm",
			name: "mm",
		},
	]);

	const [isSizeUnit, setSizeUnit] = useState(false);
	const [selectedSize, setSelectedSize] = useState("cm");
	const toggleSizeUnit = () => setSizeUnit(!isSizeUnit);

	//location nipple unit selection

	const [isLocationUnit, setLocationUnit] = useState(false);
	const [selectedLocationUnit, setSelectedLocationUnit] = useState("cm");
	const toggleLocationUnit = () => setLocationUnit(!isLocationUnit);

	const [selectedQuadrant, setSelectedQuadrant] = useState("");
	const [activeQuadrant, setActiveQuadrant] = useState(false);
	const toggleActiveQuadrant = () => {
		setActiveQuadrant(!activeQuadrant);
	};

	const [selectedThird, setSelectedThird] = useState("");
	const [activeThird, setActiveThird] = useState(false);
	const toggleActiveThird = () => {
		setActiveThird(!activeThird);
	};

	const handleFindings = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.post(
				"/mammogram/findings-text-format/",
				{
					findings_id: selectedFindingsID,
					report_id: report_id,
					size: `${intramammaryFormValues?.IntramammarySizeLength}x${intramammaryFormValues?.IntramammarySizeWidth} ${selectedSize} `,
					clock: intramammaryFormValues?.IntramammaryClock,
					quadrant: intramammaryFormValues?.IntramammaryQuadrant,
					third: intramammaryFormValues?.IntramammaryThird,
					distance: `${intramammaryFormValues?.IntramammaryDistance} ${selectedLocationUnit}`,
					comment: intramammaryFormValues?.IntramammaryComment,
					position: assessmentPositions,
					patient_finding_id: selectedLesionDetails.id
						? selectedLesionDetails.id
						: "",
				},
				{
					headers: {
						Authorization: "Bearer " + accessToken,
					},
				}
			)
			.then((response) => {
				const { StatusCode, data, message } = response.data;
				if (StatusCode === 6000) {
					setSelectedModal("");
					getFindingResults();
					setFindingFormValues({});
					getFindings();
				} else {
					alert(message);
				}
			})
			.catch((error) => {});
	};

	useEffect(() => {
		if (selectedLesionDetails.id) {
			// setLymphComment(selectedLesionDetails.comment);
			setSelectedSize(selectedLesionDetails?.size[1]);
			setSelectedLocationUnit(selectedLesionDetails?.distance[1]);
			setFindingFormValues({
				IntramammarySizeLength:
					selectedLesionDetails.size[0].split("x")[0],
				IntramammarySizeWidth:
					selectedLesionDetails.size[0].split("x")[1],
				IntramammaryClock: selectedLesionDetails?.clock,
				IntramammaryQuadrant: selectedLesionDetails?.quadrant,
				IntramammaryThird: selectedLesionDetails?.third,
				IntramammaryDistance: selectedLesionDetails?.distance[0],
				IntramammaryComment: selectedLesionDetails?.comment,
			});
		} else {
			// setLymphComment("");
			setFindingFormValues({
				IntramammaryClock: "",
				IntramammaryQuadrant: "",
				IntramammaryThird: "",
				IntramammaryDistance: "",
				IntramammaryComment: "",
			});
			setSelectedSize("cm");
			setSelectedLocationUnit("cm");
		}
		return () => {
			setSelectedLesionDetails({});
		};
	}, []);

	return (
		<BackContainer>
			<Overlay
				onClick={() => {
					setSelectedModal("");
				}}
			></Overlay>
			<Draggable handle="#handle" defaultPosition={{ x: 0, y: 0 }}>
				<Modal>
					<MovementBar id="handle">
						<DotDiv>
							<Dot />
							<Dot />
							<Dot />
							<Dot />
							<Dot />
						</DotDiv>
					</MovementBar>
					<Container>
						<Head>
							<MainHeading>Intramammary Nodes</MainHeading>
							<ButtonDiv>
								<Cancel
									onClick={() => {
										setSelectedFindings(
											selectedFindings.filter(
												(data) =>
													data !== selectedFindingsID
											)
										);
										setSelectedModal("");
										setFindingFormValues({});
									}}
								>
									Cancel
								</Cancel>
								<SaveButton
									onClick={() => {
										handleFindings();
									}}
								>
									Save
								</SaveButton>
							</ButtonDiv>
						</Head>
						<SizeContainer>
							<Line></Line>
							<SubHeading>1. Size</SubHeading>
							<Dimension>
								<DimensionBox
									type={"number"}
									value={
										intramammaryFormValues?.IntramammarySizeLength
									}
									onChange={(e) =>
										handleIntramammaryFormValues(
											"IntramammarySizeLength",
											e.target.value
										)
									}
								/>
								X
								<DimensionBox
									style={{ marginLeft: "10px" }}
									type={"number"}
									value={
										intramammaryFormValues?.IntramammarySizeWidth
									}
									onChange={(e) =>
										handleIntramammaryFormValues(
											"IntramammarySizeWidth",
											e.target.value
										)
									}
								/>
								<UnitDropDown
									items={sizeUnit}
									selectedValue={selectedSize}
									setSelectedValue={setSelectedSize}
								/>
								{/* <div style={{ position: "relative" }}>
									<UnitBox
										onClick={() => {
											toggleSizeUnit();
										}}
									>
										<Text>{selectedSize}</Text>
										<Arrow
											src={
												require("../../assets/icons/arrow-down.svg")
													.default
											}
											alt="arrow"
										/>
									</UnitBox>
									{isSizeUnit && (
										<Dropdown
										// style={{ top: "160px", left: "200px" }}
										>
											{sizeUnit.map((data) => (
												<DropdownOption
													onClick={() => {
														toggleSizeUnit();
														setSelectedSize(
															data.unit
														);
													}}
												>
													{data.unit}
												</DropdownOption>
											))}
										</Dropdown>
									)}
								</div> */}
							</Dimension>
						</SizeContainer>
						<LocationContainer>
							<Line></Line>
							<SubHeading>2. Location</SubHeading>
							<OptionContainer>
								<Unit>
									{/* <OptionBox
										onClick={() => {
											toggleActiveOption();
										}}
									>
										<Text>
											{
												intramammaryFormValues
													?.IntramammaryClock?.name
											}
										</Text>
										<Arrow
											src={
												require("../../assets/icons/arrow-down.svg")
													.default
											}
											alt="arrow"
										/>
									</OptionBox>
									{activeOption && (
										<Dropdown>
											{findingData.parameters
												.filter(
													(data) =>
														data.name == "Location"
												)[0]
												.sub_parameters.filter(
													(item) =>
														item.name == "o'clock"
												)[0]
												.choices.map((data) => (
													<DropdownOption
														onClick={() => {
															toggleActiveOption();
															// setSelectedOption(
															// 	data.name
															// );
															handleIntramammaryFormValues(
																"IntramammaryClock",
																data
															);
														}}
													>
														{data.name}
													</DropdownOption>
												))}
										</Dropdown>
									)} */}
									<FindingDropDown
										items={
											findingData.parameters
												.filter(
													(data) =>
														data.name == "Location"
												)[0]
												.sub_parameters.filter(
													(item) =>
														item.name == "o'clock"
												)[0].choices
										}
										selectedValue={intramammaryFormValues}
										setSelectedValue={
											handleIntramammaryFormValues
										}
										label={"IntramammaryClock"}
									/>
									<UnitText>o'clock,</UnitText>
								</Unit>
								<Unit>
									{/* <OptionBox
										onClick={() => {
											toggleActiveQuadrant();
										}}
									>
										<Text>
											{
												intramammaryFormValues
													?.IntramammaryQuadrant?.name
											}
										</Text>
										<Arrow
											src={
												require("../../assets/icons/arrow-down.svg")
													.default
											}
											alt="arrow"
										/>
									</OptionBox>
									{activeQuadrant && (
										<Dropdown>
											{findingData.parameters
												.filter(
													(data) =>
														data.name == "Location"
												)[0]
												.sub_parameters.filter(
													(item) =>
														item.name == "Quadrant"
												)[0]
												.choices.map((data) => (
													<DropdownOption
														onClick={() => {
															toggleActiveQuadrant();
															// setSelectedQuadrant(
															// 	data.title
															// );
															handleIntramammaryFormValues(
																"IntramammaryQuadrant",
																data
															);
														}}
													>
														{data.name}
													</DropdownOption>
												))}
										</Dropdown>
									)} */}
									<FindingDropDown
										items={
											findingData.parameters
												.filter(
													(data) =>
														data.name == "Location"
												)[0]
												.sub_parameters.filter(
													(item) =>
														item.name == "Quadrant"
												)[0].choices
										}
										selectedValue={intramammaryFormValues}
										setSelectedValue={
											handleIntramammaryFormValues
										}
										label={"IntramammaryQuadrant"}
									/>
									<UnitText>Quadrant,</UnitText>
								</Unit>
								<Unit>
									{/* <OptionBox
										onClick={() => {
											toggleActiveThird();
										}}
									>
										<Text>
											{
												intramammaryFormValues
													?.IntramammaryThird?.name
											}
										</Text>
										<Arrow
											src={
												require("../../assets/icons/arrow-down.svg")
													.default
											}
											alt="arrow"
										/>
									</OptionBox>
									{activeThird && (
										<Dropdown>
											{findingData.parameters
												.filter(
													(data) =>
														data.name == "Location"
												)[0]
												.sub_parameters.filter(
													(item) =>
														item.name == "Third"
												)[0]
												.choices.map((data) => (
													<DropdownOption
														onClick={() => {
															toggleActiveThird();
															// setSelectedThird(
															// 	data.name
															// );
															handleIntramammaryFormValues(
																"IntramammaryThird",
																data
															);
														}}
													>
														{data.name}
													</DropdownOption>
												))}
										</Dropdown>
									)} */}
									<FindingDropDown
										items={
											findingData.parameters
												.filter(
													(data) =>
														data.name == "Location"
												)[0]
												.sub_parameters.filter(
													(item) =>
														item.name == "Third"
												)[0].choices
										}
										selectedValue={intramammaryFormValues}
										setSelectedValue={
											handleIntramammaryFormValues
										}
										label={"IntramammaryThird"}
									/>
									<UnitText>Third,</UnitText>
								</Unit>
								<Unit>
									{/* <CentimeterBox
										style={{ marginRight: "5px" }}
									>
										<Centimeter
											type={"number"}
											onChange={(e) => {
												handleIntramammaryFormValues(
													"IntramammaryDistance",
													e.target.value
												);
											}}
											value={
												intramammaryFormValues?.IntramammaryDistance
											}
										/>
									</CentimeterBox>
									<div style={{ position: "relative" }}>
										<UnitBox
											onClick={() => {
												toggleLocationUnit();
											}}
										>
											<Text>{selectedLocationUnit}</Text>
											<Arrow
												src={
													require("../../assets/icons/arrow-down.svg")
														.default
												}
												alt="arrow"
											/>
										</UnitBox>

										{isLocationUnit && (
											<Dropdown>
												{sizeUnit.map((data) => (
													<DropdownOption
														onClick={() => {
															toggleLocationUnit();
															setSelectedLocationUnit(
																data.unit
															);
														}}
													>
														{data.unit}
													</DropdownOption>
												))}
											</Dropdown>
										)}
									</div> */}
									<DimensionBox
										type={"number"}
										value={
											intramammaryFormValues?.IntramammaryDistance
										}
										onChange={(e) =>
											handleIntramammaryFormValues(
												"IntramammaryDistance",
												e.target.value
											)
										}
										onWheel={(e) => e.target.blur()}
									/>
									<UnitDropDown
										items={sizeUnit}
										selectedValue={selectedLocationUnit}
										setSelectedValue={
											setSelectedLocationUnit
										}
									/>
									<UnitText>from Nipple</UnitText>
								</Unit>
							</OptionContainer>
						</LocationContainer>
						<Comment>
							<Line></Line>
							<SubHeading>3. Comment</SubHeading>
							<TextArea
								placeholder="Write Here..."
								onChange={(e) => {
									handleIntramammaryFormValues(
										"IntramammaryComment",
										e.target.value
									);
								}}
								value={
									intramammaryFormValues?.IntramammaryComment
								}
							/>
						</Comment>
					</Container>
				</Modal>
			</Draggable>
		</BackContainer>
	);
}

const BackContainer = styled.div`
	position: fixed;
	transition: 0.2s;
	width: 100%;
	height: 100vh;
	z-index: 1000;
	left: 0;
	top: 0px;
	background: rgba(0, 0, 0, 0.4);
`;
const Overlay = styled.div`
	position: fixed;
	left: 0;
	top: 0px;
	width: 100%;
	cursor: pointer;
	height: 100vh;
`;
const Modal = styled.div`
	background: #222222;
	left: 20%;
	top: 40%;
	position: absolute;
	border-radius: 12px;
	transition: 0.1s;
	z-index: 101;
	width: 500px;
	// padding: 20px;
	overflow-y: auto;
	user-select: none;
`;

const MovementBar = styled.div`
	background: linear-gradient(360deg, #292929 0%, #4b4b4b 100%);
	height: 30px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: move;
`;
const DotDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;
const Dot = styled.div`
	height: 8px;
	width: 8px;
	border-radius: 50%;
	background-color: #212121;
	margin-right: 5px;
`;
const Container = styled.div`
	margin: 20px;
`;
// const Container = styled.div `
//     width: 400px;
//     background: #222222;
//     border-radius: 8px;
//     padding: 20px;
//     position: absolute;
//     top: 760px;
//     left: 300px;
//     z-index:999;
// `;
const Head = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 30px;
	margin-bottom: 10px;
`;
const Line = styled.div`
	height: 1px;
	background: #555555;
	width: 100%;
	margin: 20px 0;
`;
const MainHeading = styled.h3`
	font-family: "poppinsmedium";
	color: #ff4c51;
	font-size: 18px;
`;
const ButtonDiv = styled.div`
	display: flex;
`;
const Cancel = styled.div`
	color: #00ace2;
	font-size: 13px;
	// background: #203035;
	padding: 8px 10px;
	border-radius: 8px;
	cursor: pointer;
	margin-right: 10px;
`;
const SaveButton = styled.div`
	color: #00ace2;
	font-size: 13px;
	background: #203035;
	padding: 8px 10px;
	border-radius: 8px;
	cursor: pointer;
`;
const SizeContainer = styled.div`
	margin-bottom: 10px;
`;
const SubHeading = styled.h4`
	font-family: "poppinsmedium";
	color: #00ace2;
	font-size: 14px;
	margin-bottom: 15px;
`;
const Dimension = styled.div`
	display: flex;
	/* justify-content: space-between; */
	align-items: center;
	/* width: 65%; */
	padding-left: 20px;
`;
const DimensionBox = styled.input`
	padding: 8px 15px;
	width: 50px;
	background: #203035;
	border: 1px solid #2d4349;
	border-radius: 5px;
	color: #fff;
	font-size: 14px;
	margin-right: 10px;
	&:focus {
		border-color: #aaa;
	}
`;

const UnitBox = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid #323232;
	border-radius: 3px;
	color: #fff;
	font-size: 16px;
	width: 70px;
	padding: 4px;
`;
const Text = styled.h5`
	margin-right: 10px;
	font-size: 13px;
`;
const Arrow = styled.img`
	height: 5px;
`;
const LocationContainer = styled.div``;
const OptionContainer = styled.div`
	padding-left: 20px;
	display: grid !important;
	grid-template-columns: 1fr 1fr;
`;
const Unit = styled.div`
	display: flex;
	align-items: center;
	color: #a3a3a3;
	/* width: 50%; */
	margin-bottom: 10px;
	position: relative;
	:nth-last-child(-n + 2) {
		margin-bottom: 0;
	}
`;
const CentimeterBox = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #203035;
	border: 1px solid #2d4349;
	padding: 8px 10px;
	border-radius: 5px;
`;
const Centimeter = styled.input`
	width: 25px;
	color: #fff;
	font-size: 14px;
`;
const CentimeterText = styled.h5`
	font-size: 14px;
	color: #fff;
`;
const OptionBox = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px 10px;
	height: 30px;
	width: 85px;
	background: #203035;
	border: 1px solid #2d4349;
	border-radius: 5px;
	color: #fff;
	font-size: 14px;
	cursor: pointer;
`;
const UnitText = styled.h6`
	color: #a3a3a3;
	font-size: 14px;
	margin-left: 10px;
`;
const Dropdown = styled.div`
	background-color: #333333;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: absolute;
	top: 30px;
	width: 85px;
	z-index: 60;
`;
const DropdownOption = styled.h4`
	color: #fff;
	font-size: 15px;
	font-family: "poppinsregular";
	height: 40px;
	display: flex;
	align-items: center;
	cursor: pointer;
	text-align: center;
	padding: 0 10px;
	:hover {
		background-color: #424242;
	}
	:first-child {
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}
	:last-child {
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
	}
	@media (max-width: 1280px) {
		font-size: 14px;
	}
`;
const Comment = styled.div``;
const TextArea = styled.textarea`
	border: 1px solid #5d5c5d;
	border-radius: 8px;
	padding: 10px;
	color: #fff;
	background: #203035;
	width: 100%;
	resize: vertical;
	height: 100px;
	::placeholder {
		font-family: "poppinsregular";
		color: #4d595d;
	}
	:focus {
		border-color: #aaa;
	}
`;
