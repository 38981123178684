// import { data } from "jquery";
import React, { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import NoMassPopup from "../../includes/NoMassPopup";
import NoCalcificationPopup from "../../includes/NoCalcificationsPopup";
import NoAsymmetryPopup from "../../includes/NoAsymmetryPopup";
import NoDistortionPopup from "../../includes/NoDistortionPopup";
import NoIntramammaryNodesPopup from "../../includes/NoIntramammaryNodesPopup";
import NoSkinLesionsPopup from "../../includes/NoSkinLesionsPopup";
import LymphNodesPopup from "../../includes/LymphNodesPopup";
import CategoryCommonPopup from "../../includes/CategoryCommonPopup";
import { baseConfig } from "./../../../axiosConfig";
import { Context } from "./../../../contexts/Store";
import "./styles.css";
import FindingList from "./FindingList";
import MassEditModal from "./editModal/MassEditModal";
import CalcificationEditModal from "./editModal/CalcificationEditModal";
import DistortionEditModal from "./editModal/DistortionEditModal";
import SkinLesionsEditModal from "./editModal/SkinLesionsEditModal";
import LympNodeEditModal from "./editModal/LympNodeEditModal";
import IntramammaryNodeEditModal from "./editModal/IntramammaryNodeEditModal";
import AsymmetricEditModal from "./editModal/AsymmetricEditModal";

export default function LeftContainer({
	optionsComposition,
	selectedComposition,
	setSelectedComposition,
	compositionCategory,
	setCompositionCategory,
	setIndication,
	indication,
	optionsFinding,
	handleClick,
	isCheck,
	assessment,
	form,
	examComparison,
	getFindingResults,
	setAssessmentData,
	setExamComparison,
	submitAssessment,
	getFindings,
	submitExamComparison,
	getAssessments,
	getComposition,
	findingsResults,
}) {
	const { state } = useContext(Context);
	const { report_id, synoptic_id } = useParams();

	// const [modalXYPosition, setModalXYPosition] = useState();

	// useEffect(() => {
	// 	// 👇️ get global mouse coordinates
	// 	const handleWindowMouseMove = (event) => {
	// 		setModalXYPosition({
	// 			x: event.x,
	// 			y: event.y,
	// 		});
	// 	};
	// 	window.addEventListener("mousemove", handleWindowMouseMove);

	// 	return () => {
	// 		window.removeEventListener("mousemove", handleWindowMouseMove);
	// 	};
	// }, []);

	const position = [
		{
			id: 1,
			value: "left",
			name: "Left",
		},
		{
			id: 2,
			value: "right",
			name: "Right",
		},
	];
	const [selectedManagmentPosition, setSelectedManagmentPosition] =
		useState("left");
	const [optionsMammogram, setOptionsMammogram] = useState([
		{
			id: 1,
			option: "Unilateral",
			inner: [
				{
					id: 4,
					option: "Left",
				},
				{
					id: 5,
					option: "Right",
				},
			],
		},
		{
			id: 2,
			option: "Bilateral",
		},
	]);
	const [categoryModal, setCategoryModal] = useState(false);
	const [categoryData, setCategoryData] = useState({});
	const [assessmentPositions, setAssessmentPositions] = useState("");
	// const [optionsComposition, setOptionsComposition] = useState([
	// {
	// 	id: 1,
	// 	option: "The breasts are almost fatty.",
	// },
	// {
	// 	id: 2,
	// 	option: "There are scattered areas of fibroglandular density.",
	// },
	// {
	// 	id: 3,
	// 	option: "The breasts are heterogeneously dense,which may obscure small masses.",
	// },
	// {
	// 	id: 4,
	// 	option: "The breasts are extremely dense, which lowers the sensitivity of mammography.",
	// },
	// ]);
	// const [optionsFinding, setOptionsFinding] = useState([
	// 	{
	// 		id: 1,
	// 		title: "no mass",
	// 		value: false,
	// 	},
	// 	{
	// 		id: 2,
	// 		title: "no calcifications",
	// 		value: false,
	// 	},
	// 	{
	// 		id: 3,
	// 		title: "no architectural distortion",
	// 		value: false,
	// 	},
	// 	{
	// 		id: 4,
	// 		title: "no asymmetry",
	// 		value: false,
	// 	},
	// 	{
	// 		id: 5,
	// 		title: "no intramammary nodes",
	// 		value: false,
	// 	},
	// 	{
	// 		id: 6,
	// 		title: "no skin lesions",
	// 		value: false,
	// 	},
	// ]);

	const [selectedMammogram, setSelectedMammogram] = useState("");
	const [selectedUnilateral, setSelectedUnilateral] = useState("");
	const [selectedModal, setSelectedModal] = useState("");

	//edit modal
	const [selectedEditModal, setSelectedEditModal] = useState("");
	const [editModalData, setEditModalData] = useState({});

	const [globalFindCheckbox, setGlobalFindCheckbox] = useState(false);
	//Findings
	const [selectedFindings, setSelectedFindings] = useState([]);
	const [selectedFindingsID, setSelectedFindingsID] = useState("");
	const [findingData, setFindingData] = useState([]);
	const [selectedFindingDetails, setSelectedFindingDetails] = useState({});

	//Composition
	// const [selectedComposition, setSelectedComposition] = useState("");
	// const [compositionCategory, setCompositionCategory] = useState("");

	//mass
	// const [massSize, setMassSize] = useState("");
	// const [massSizeLength, setMassSizeLength] = useState("");
	// const [massSizeWidth, setMassSizeWidth] = useState("");
	// const [massClock, setMassClock] = useState("");
	// const [massQuadrant, setMassQuadrant] = useState("");
	// const [massThird, setMassThird] = useState("");
	// const [massDistance, setMassDistance] = useState("");
	// const [massShape, setMassShape] = useState("");
	// const [massMargin, setMassMargin] = useState("");
	// const [massDensity, setMassDensity] = useState("");

	//Mass form values
	const [findingFormValues, setFindingFormValues] = useState({});

	const handleFindingFormValues = (key, value) => {
		setFindingFormValues({ ...findingFormValues, [key]: value });
	};

	//Calcification form values
	const [calcificationFormValues, setCalcificationFormValues] = useState({});

	const handleCalcificationFormValues = (key, value) => {
		setCalcificationFormValues({
			...calcificationFormValues,
			[key]: value,
		});
	};

	// Lission details
	const [selectedLesionDetails, setSelectedLesionDetails] = useState({});

	//Skin form values
	const [skinComment, setSkinComment] = useState("");

	//Lymph form values
	const [lymphComment, setLymphComment] = useState("");

	//Architectural form values
	const [architecturalFormValues, setArchitecturalFormValues] = useState({});

	const handleArchitecturalFormValues = (key, value) => {
		setArchitecturalFormValues({
			...architecturalFormValues,
			[key]: value,
		});
	};

	//Intramammary form values
	const [intramammaryFormValues, setIntramammaryFormValues] = useState({});

	const handleIntramammaryFormValues = (key, value) => {
		setIntramammaryFormValues({
			...intramammaryFormValues,
			[key]: value,
		});
	};

	//Asymmetry form values
	const [asymmetryFormValues, setAsymmetryFormValues] = useState({});

	const handleAsymmetryFormValues = (key, value) => {
		setAsymmetryFormValues({
			...asymmetryFormValues,
			[key]: value,
		});
	};

	//Category

	const [selectedCategory, setSelectedCategory] = useState();

	//CategorySave
	const categorySave = (val) => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.post(
				"/mammogram/assessment-data-save/",
				{
					mammogram_id: synoptic_id,
					assessment_id: selectedCategory?.id,
					position: val,
				},
				{
					headers: {
						Authorization: "Bearer " + accessToken,
					},
				}
			)
			.then((response) => {
				const { StatusCode, reference_data } = response.data;
				if (StatusCode === 6000) {
					// setReferences(reference_data);
					setCategoryModal(false);
					setAssessmentData(categoryData);
					getFindingResults();
					getAssessments();
				} else {
				}
			})
			.catch((error) => {});
	};
	//Composition
	const saveComposition = (id) => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.post(
				"/mammogram/composition-save/",
				{
					mammogram_id: form?.data?.mammogram_id,
					composition_id: id,
				},
				{
					headers: {
						Authorization: "Bearer " + accessToken,
					},
				}
			)
			.then((response) => {
				const { StatusCode, reference_data } = response.data;
				if (StatusCode === 6000) {
					setCategoryModal(false);
					setAssessmentData(categoryData);
					getFindingResults();
					getComposition();
				} else {
				}
			})
			.catch((error) => {});
	};

	//deleteLesion
	const deleteLesion = (id) => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.get("/mammogram/delete-findings/" + id, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, reference_data } = response.data;
				if (StatusCode === 6000) {
					// setReferences(reference_data);
					getFindings();
					getFindingResults();
				} else {
				}
			})
			.catch((error) => {});
	};

	const FindingBox = () => {
		return (
			<>
				{optionsFinding.map((data) => (
					<FindingDiv>
						<Input
							// contenteditable="true"
							type="checkbox"
							onChange={handleClick}
							onClick={handleClick}
							checked={isCheck.includes(data.id)}
							id={data.id}
						/>
						<OptionText>{data.title}</OptionText>
					</FindingDiv>
				))}
			</>
		);
	};

	return (
		<LeftDetailsContainer>
			<SynopticTemplateContainer>
				{/* <Mamogram>
					<Text>BIRADS REPORTING</Text>
					<Heading>1. MAMMOGRAM</Heading>
					{optionsMammogram.map((data) => (
						<SelectLateralityContainer
							onClick={() => {
								setSelectedMammogram(data.option);
							}}
						>
							<div
								style={{
									display: "flex",
									marginBottom: "10px",
								}}
							>
								<RadioButtonChecked>
									{selectedMammogram === data.option && (
										<InnerCircle />
									)}
								</RadioButtonChecked>
								<OptionText>{data.option}</OptionText>
							</div>
							{selectedMammogram === "Unilateral" && (
								<div
									style={{
										display: "flex",
										marginLeft: "30px",
									}}
								>
									{data.inner?.map((item) => (
										<div
											onClick={() => {
												setSelectedUnilateral(
													item.option
												);
											}}
											style={{
												display: "flex",
												marginLeft: "30px",
											}}
										>
											<RadioButtonChecked>
												{selectedUnilateral ===
													item.option && (
													<InnerCircle />
												)}
											</RadioButtonChecked>
											<OptionText>
												{item.option}
											</OptionText>
										</div>
									))}
								</div>
							)}
						</SelectLateralityContainer>
					))}
				</Mamogram> */}
				<Indication>
					<Text>BIRADS REPORTING</Text>
					<Heading>1. INDICATION FOR EXAMINATION</Heading>
					<TextArea
						value={indication}
						onChange={(e) => {
							setIndication(e.target.value);
						}}
						placeholder="Write Here..."
					/>
					<ButtonDiv>
						<SubmitButton
							onClick={() => {
								if (indication === "") {
									alert("Fill indication field");
								} else {
									submitAssessment();
								}
							}}
						>
							Save
						</SubmitButton>
					</ButtonDiv>
				</Indication>
				<BreastComposition>
					<Heading>2. BREAST COMPOSITION</Heading>
					{optionsComposition.map((data) => (
						<SelectContainer
							onClick={() => {
								// toggleActiveOption();
								setSelectedComposition(data.title);
								setCompositionCategory(data.category);
								saveComposition(data.id);
							}}
						>
							<RadioButtonChecked>
								{data.active && <InnerCircle></InnerCircle>}
							</RadioButtonChecked>
							<OptionText>{data.title}</OptionText>
						</SelectContainer>
					))}
					<Bottom>
						<Output>OUTPUT:</Output>
						<Category>{compositionCategory}</Category>
					</Bottom>
				</BreastComposition>
				<Findings>
					<Heading>3. FINDINGS</Heading>
					{/* {optionsFinding.map((data) => (
						<Select
							onClick={() => {
								setSelectedFindings([
									...selectedFindings,
									data?.id,
								]);
							}}
							onChange={handleClick}
							isChecked={isCheck.includes(data.id)}
						>
							<SelectContainer>
								<CheckboxChecked>
									{selectedModal === data.option && (
										<InnerSquare />
									)}
									{data.value && <InnerSquare />}
								</CheckboxChecked>
								<OptionText>{data.title}</OptionText>
							</SelectContainer>
						</Select>
					))} */}
					{/* {FindingBox()} */}

					<>
						{findingsResults?.laterality_name === "Bilateral" ? (
							<>
								<LateralityHeading>Left</LateralityHeading>
								<>
									{optionsFinding?.left_findings?.map(
										(item, index) => (
											<FindingList
												item={item}
												key={item.id}
												setSelectedModal={
													setSelectedModal
												}
												// active={active}
												// setActive={setActive}
												// toggleActiveSize={toggleActiveSize}
												setGlobalFindCheckbox={
													setGlobalFindCheckbox
												}
												globalFindCheckbox={
													globalFindCheckbox
												}
												setSelectedFindings={
													setSelectedFindings
												}
												selectedFindings={
													selectedFindings
												}
												// onClick={() => {
												// 	setFindingData(item);
												// }}
												setSelectedFindingsID={
													setSelectedFindingsID
												}
												setFindingData={setFindingData}
												deleteLesion={deleteLesion}
												getFindingResults={
													getFindingResults
												}
												setSelectedEditModal={
													setSelectedEditModal
												}
												setEditModalData={
													setEditModalData
												}
												position={"left"}
												setAssessmentPositions={
													setAssessmentPositions
												}
												setSelectedLesionDetails={
													setSelectedLesionDetails
												}
											/>
										)
									)}
								</>
								<LateralityHeading>Right</LateralityHeading>
								<>
									{optionsFinding?.findings?.map(
										(item, index) => (
											<FindingList
												item={item}
												key={item.id}
												setSelectedModal={
													setSelectedModal
												}
												// active={active}
												// setActive={setActive}
												// toggleActiveSize={toggleActiveSize}
												setGlobalFindCheckbox={
													setGlobalFindCheckbox
												}
												globalFindCheckbox={
													globalFindCheckbox
												}
												setSelectedFindings={
													setSelectedFindings
												}
												selectedFindings={
													selectedFindings
												}
												// onClick={() => {
												// 	setFindingData(item);
												// }}
												setSelectedFindingsID={
													setSelectedFindingsID
												}
												setFindingData={setFindingData}
												deleteLesion={deleteLesion}
												getFindingResults={
													getFindingResults
												}
												setSelectedEditModal={
													setSelectedEditModal
												}
												setEditModalData={
													setEditModalData
												}
												position={"right"}
												setAssessmentPositions={
													setAssessmentPositions
												}
												setSelectedLesionDetails={
													setSelectedLesionDetails
												}
											/>
										)
									)}
								</>
							</>
						) : (
							<>
								{optionsFinding?.findings?.map(
									(item, index) => (
										<FindingList
											item={item}
											key={item.id}
											setSelectedModal={setSelectedModal}
											// active={active}
											// setActive={setActive}
											// toggleActiveSize={toggleActiveSize}
											setGlobalFindCheckbox={
												setGlobalFindCheckbox
											}
											globalFindCheckbox={
												globalFindCheckbox
											}
											setSelectedFindings={
												setSelectedFindings
											}
											selectedFindings={selectedFindings}
											// onClick={() => {
											// 	setFindingData(item);
											// }}
											setSelectedFindingsID={
												setSelectedFindingsID
											}
											setFindingData={setFindingData}
											deleteLesion={deleteLesion}
											getFindingResults={
												getFindingResults
											}
											setSelectedEditModal={
												setSelectedEditModal
											}
											setEditModalData={setEditModalData}
											position={"right"}
											setAssessmentPositions={
												setAssessmentPositions
											}
											setSelectedLesionDetails={
												setSelectedLesionDetails
											}
										/>
									)
								)}
							</>
						)}
					</>
				</Findings>

				<PreviousComparison>
					<Heading>
						4. PREVIOUS EXAM COMPARISON / USG CORRELATION
					</Heading>
					<TextArea
						placeholder="Write Here..."
						onChange={(e) => {
							setExamComparison(e.target.value);
						}}
						value={examComparison}
					/>
					<ButtonDiv>
						<SubmitButton
							onClick={() => {
								if (examComparison === "") {
									alert("Fill comparison field");
								} else {
									submitExamComparison();
								}
							}}
						>
							Save
						</SubmitButton>
					</ButtonDiv>
				</PreviousComparison>
				<AssessmentManagement>
					<Heading>5. ASSESMENT AND MANAGEMENT</Heading>
					{/* <Div
						onClick={() => {
							setCategoryModal("Category0");
						}}
					>
						<RadioButtonChecked>
							<InnerCircle></InnerCircle>
						</RadioButtonChecked>
						<OptionText>
							Category 0: <Highlight>Incomplete</Highlight>
						</OptionText>
					</Div>
					<Div
						onClick={() => {
							setCategoryModal("Category1");
						}}
					>
						<RadioButtonChecked>
							<InnerCircle></InnerCircle>
						</RadioButtonChecked>
						<OptionText>
							Category 1: <Highlight>Negative</Highlight>
						</OptionText>
					</Div>
					<Div
						onClick={() => {
							setCategoryModal("Category2");
						}}
					>
						<RadioButtonChecked>
							<InnerCircle></InnerCircle>
						</RadioButtonChecked>
						<OptionText>
							Category 2: <Highlight>Benign</Highlight>
						</OptionText>
					</Div>
					<Div
						onClick={() => {
							setCategoryModal("Category3");
						}}
					>
						<RadioButtonChecked>
							<InnerCircle></InnerCircle>
						</RadioButtonChecked>
						<OptionText>
							Category 3: <Highlight>Probably Benign</Highlight>
						</OptionText>
					</Div>
					<Div
						onClick={() => {
							setCategoryModal("Category4");
						}}
					>
						<RadioButtonChecked>
							<InnerCircle></InnerCircle>
						</RadioButtonChecked>
						<OptionText>
							Category 4: <Highlight>Suspicious</Highlight>
						</OptionText>
					</Div>
					<Div
						onClick={() => {
							setCategoryModal("Category5");
						}}
					>
						<RadioButtonChecked>
							<InnerCircle></InnerCircle>
						</RadioButtonChecked>
						<OptionText>
							Category 5:{" "}
							<Highlight>
								Highly Suggestive of Malignancy
							</Highlight>
						</OptionText>
					</Div>
					<Div
						onClick={() => {
							setCategoryModal("Category6");
						}}
					>
						<RadioButtonChecked>
							<InnerCircle></InnerCircle>
						</RadioButtonChecked>
						<OptionText>
							Category 6:{" "}
							<Highlight>
								Known Biopsy-proven Malignancy
							</Highlight>
						</OptionText>
					</Div> */}
					{/* <RadioContainer>
						{position.map((item) => (
							<RadioDiv
								onClick={() => {
									setSelectedManagmentPosition(item.value);
								}}
							>
								<RadioButtonChecked>
									{selectedManagmentPosition ===
										item.value && <InnerCircle />}
								</RadioButtonChecked>
								<OptionText>{item.name}</OptionText>
							</RadioDiv>
						))}
					</RadioContainer> */}

					{/* <ReferanceHeadDiv>
						{position.map((item) => (
							<HeadContainer
								onClick={() => {
									setSelectedManagmentPosition(item.value);
									// setLexicon(true);
								}}
								style={{
									backgroundColor:
										selectedManagmentPosition === item.value
											? "#343434"
											: "#222222",
								}}
							>
								<HeadText>{item.name}</HeadText>
							</HeadContainer>
						))}
					</ReferanceHeadDiv> */}
					{findingsResults?.laterality_name === "Bilateral" ? (
						<>
							<LateralityHeading>Left</LateralityHeading>
							{assessment?.left_assessment?.map((data) => (
								<Div
									onClick={() => {
										setCategoryModal(true);
										setCategoryData(data);
										setAssessmentPositions("left");
									}}
								>
									<RadioButtonChecked>
										{data.active && <InnerCircle />}
									</RadioButtonChecked>
									<OptionText>
										{data.category} :
										<Highlight> {data.title}</Highlight>
									</OptionText>
								</Div>
							))}

							<LateralityHeading>Right</LateralityHeading>
							{assessment?.assessment?.map((data) => (
								<Div
									onClick={() => {
										setCategoryModal(true);
										setCategoryData(data);
										setAssessmentPositions("right");
									}}
								>
									<RadioButtonChecked>
										{data.active && <InnerCircle />}
									</RadioButtonChecked>
									<OptionText>
										{data.category} :
										<Highlight> {data.title}</Highlight>
									</OptionText>
								</Div>
							))}
						</>
					) : (
						<>
							{assessment?.assessment?.map((data) => (
								<Div
									onClick={() => {
										setCategoryModal(true);
										setCategoryData(data);
									}}
								>
									<RadioButtonChecked>
										{data.active && <InnerCircle />}
									</RadioButtonChecked>
									<OptionText>
										{data.category} :
										<Highlight> {data.title}</Highlight>
									</OptionText>
								</Div>
							))}
						</>
					)}
				</AssessmentManagement>
				{/* <SubmitContainer>
					<InnerContainer>
						<Cancel
							onClick={() => {
								setGlobalFindCheckbox(false);
							}}
						>
							Cancel Report
						</Cancel>
						<SubmitButton
						// onClick={() => {
						// 	if (selectedComposition === "") {
						// 		alert("select a composition");
						// 	} else if (indication === "") {
						// 		alert("Fill indication field");
						// 	} else if (examComparison === "") {
						// 		alert("Fill comparison field");
						// 	} else {
						// 		submitAssessment();
						// 	}
						// }}
						>
							Submit
						</SubmitButton>
					</InnerContainer>
				</SubmitContainer> */}
			</SynopticTemplateContainer>
			{selectedModal === "Mass" ? (
				<NoMassPopup
					setSelectedModal={setSelectedModal}
					assessmentPositions={assessmentPositions}
					selectedFindings={selectedFindings}
					setSelectedFindings={setSelectedFindings}
					selectedFindingsID={selectedFindingsID}
					handleFindingFormValues={handleFindingFormValues}
					findingData={findingData}
					// handleFindings={handleFindings}
					findingFormValues={findingFormValues}
					getFindingResults={getFindingResults}
					setFindingFormValues={setFindingFormValues}
					getFindings={getFindings}
					report_id={report_id}
					findingsResults={findingsResults}
					setSelectedLesionDetails={setSelectedLesionDetails}
					selectedLesionDetails={selectedLesionDetails}
				/>
			) : selectedModal === "Calcifications" ? (
				<NoCalcificationPopup
					setSelectedModal={setSelectedModal}
					assessmentPositions={assessmentPositions}
					selectedFindingsID={selectedFindingsID}
					selectedFindings={selectedFindings}
					setSelectedFindings={setSelectedFindings}
					findingData={findingData}
					handleCalcificationFormValues={
						handleCalcificationFormValues
					}
					getFindingResults={getFindingResults}
					calcificationFormValues={calcificationFormValues}
					setFindingFormValues={setCalcificationFormValues}
					report_id={report_id}
					getFindings={getFindings}
					setSelectedLesionDetails={setSelectedLesionDetails}
					selectedLesionDetails={selectedLesionDetails}
				/>
			) : selectedModal === "Architectural Distortion" ? (
				<NoDistortionPopup
					setSelectedModal={setSelectedModal}
					assessmentPositions={assessmentPositions}
					selectedFindingsID={selectedFindingsID}
					selectedFindings={selectedFindings}
					setSelectedFindings={setSelectedFindings}
					findingData={findingData}
					architecturalFormValues={architecturalFormValues}
					handleArchitecturalFormValues={
						handleArchitecturalFormValues
					}
					report_id={report_id}
					getFindingResults={getFindingResults}
					setFindingFormValues={setArchitecturalFormValues}
					getFindings={getFindings}
					setSelectedLesionDetails={setSelectedLesionDetails}
					selectedLesionDetails={selectedLesionDetails}
				/>
			) : selectedModal === "Asymmetry" ? (
				<NoAsymmetryPopup
					setSelectedModal={setSelectedModal}
					assessmentPositions={assessmentPositions}
					selectedFindingsID={selectedFindingsID}
					selectedFindings={selectedFindings}
					setSelectedFindings={setSelectedFindings}
					findingData={findingData}
					report_id={report_id}
					getFindingResults={getFindingResults}
					handleAsymmetryFormValues={handleAsymmetryFormValues}
					asymmetryFormValues={asymmetryFormValues}
					setFindingFormValues={setAsymmetryFormValues}
					getFindings={getFindings}
					setSelectedLesionDetails={setSelectedLesionDetails}
					selectedLesionDetails={selectedLesionDetails}
				/>
			) : selectedModal === "Intramammary nodes" ? (
				<NoIntramammaryNodesPopup
					setSelectedModal={setSelectedModal}
					assessmentPositions={assessmentPositions}
					selectedFindingsID={selectedFindingsID}
					selectedFindings={selectedFindings}
					setSelectedFindings={setSelectedFindings}
					findingData={findingData}
					report_id={report_id}
					getFindingResults={getFindingResults}
					intramammaryFormValues={intramammaryFormValues}
					handleIntramammaryFormValues={handleIntramammaryFormValues}
					setFindingFormValues={setIntramammaryFormValues}
					getFindings={getFindings}
					setSelectedLesionDetails={setSelectedLesionDetails}
					selectedLesionDetails={selectedLesionDetails}
				/>
			) : selectedModal === "Lymph Nodes" ? (
				<LymphNodesPopup
					setSelectedModal={setSelectedModal}
					assessmentPositions={assessmentPositions}
					selectedFindingsID={selectedFindingsID}
					selectedFindings={selectedFindings}
					setSelectedFindings={setSelectedFindings}
					lymphComment={lymphComment}
					setLymphComment={setLymphComment}
					report_id={report_id}
					getFindingResults={getFindingResults}
					getFindings={getFindings}
					setSelectedLesionDetails={setSelectedLesionDetails}
					selectedLesionDetails={selectedLesionDetails}
				/>
			) : selectedModal === "Skin lesions" ? (
				<NoSkinLesionsPopup
					setSelectedModal={setSelectedModal}
					assessmentPositions={assessmentPositions}
					selectedFindingsID={selectedFindingsID}
					selectedFindings={selectedFindings}
					setSelectedFindings={setSelectedFindings}
					report_id={report_id}
					getFindingResults={getFindingResults}
					skinComment={skinComment}
					setSkinComment={setSkinComment}
					getFindings={getFindings}
					setSelectedLesionDetails={setSelectedLesionDetails}
					selectedLesionDetails={selectedLesionDetails}
				/>
			) : null}

			{categoryModal === true ? (
				<CategoryCommonPopup
					setCategoryModal={setCategoryModal}
					categoryData={categoryData}
					setCategoryData={setCategoryData}
					setAssessmentData={setAssessmentData}
					categorySave={categorySave}
					selectedCategory={selectedCategory}
					setSelectedCategory={setSelectedCategory}
					assessmentPositions={assessmentPositions}
				/>
			) : null}
			{/* {selectedEditModal.name === "Mass" ? (
				<MassEditModal
					setSelectedModal={setSelectedEditModal}
					selectedFindings={selectedFindings}
					setSelectedFindings={setSelectedFindings}
					selectedFindingsID={selectedFindingsID}
					handleFindingFormValues={handleFindingFormValues}
					findingData={selectedEditModal}
					// handleFindings={handleFindings}
					findingFormValues={findingFormValues}
					form={form}
					getFindingResults={getFindingResults}
					setFindingFormValues={setFindingFormValues}
					getFindings={getFindings}
					editModalData={editModalData}
				/>
			) : selectedEditModal.name === "Calcifications" ? (
				<CalcificationEditModal
					setSelectedModal={setSelectedEditModal}
					selectedFindings={selectedFindings}
					setSelectedFindings={setSelectedFindings}
					selectedFindingsID={selectedFindingsID}
					handleCalcificationFormValues={
						handleCalcificationFormValues
					}
					findingData={selectedEditModal}
					form={form}
					getFindingResults={getFindingResults}
					editModalData={editModalData}
					calcificationFormValues={calcificationFormValues}
					setFindingFormValues={setCalcificationFormValues}
					getFindings={getFindings}
				/>
			) : selectedEditModal.name === "Architectural Distortion" ? (
				<DistortionEditModal
					setSelectedModal={setSelectedEditModal}
					selectedFindingsID={selectedFindingsID}
					selectedFindings={selectedFindings}
					setSelectedFindings={setSelectedFindings}
					findingData={selectedEditModal}
					architecturalFormValues={architecturalFormValues}
					handleArchitecturalFormValues={
						handleArchitecturalFormValues
					}
					form={form}
					getFindingResults={getFindingResults}
					setFindingFormValues={setArchitecturalFormValues}
					getFindings={getFindings}
					editModalData={editModalData}
				/>
			) : selectedEditModal.name === "Skin lesions" ? (
				<SkinLesionsEditModal
					setSelectedModal={setSelectedEditModal}
					selectedFindingsID={selectedFindingsID}
					selectedFindings={selectedFindings}
					setSelectedFindings={setSelectedFindings}
					form={form}
					getFindingResults={getFindingResults}
					skinComment={skinComment}
					setSkinComment={setSkinComment}
					getFindings={getFindings}
					findingData={selectedEditModal}
					editModalData={editModalData}
				/>
			) : selectedEditModal.name === "Lymph Nodes" ? (
				<LympNodeEditModal
					setSelectedModal={setSelectedEditModal}
					selectedFindingsID={selectedFindingsID}
					selectedFindings={selectedFindings}
					setSelectedFindings={setSelectedFindings}
					form={form}
					findingData={selectedEditModal}
					editModalData={editModalData}
					lymphComment={lymphComment}
					setLymphComment={setLymphComment}
					getFindingResults={getFindingResults}
					getFindings={getFindings}
				/>
			) : selectedEditModal.name === "Intramammary nodes" ? (
				<IntramammaryNodeEditModal
					selectedFindingsID={selectedFindingsID}
					selectedFindings={selectedFindings}
					setSelectedFindings={setSelectedFindings}
					form={form}
					getFindingResults={getFindingResults}
					intramammaryFormValues={intramammaryFormValues}
					handleIntramammaryFormValues={handleIntramammaryFormValues}
					setFindingFormValues={setIntramammaryFormValues}
					getFindings={getFindings}
					setSelectedModal={setSelectedEditModal}
					findingData={selectedEditModal}
					editModalData={editModalData}
				/>
			) : selectedEditModal.name === "Asymmetry" ? (
				<AsymmetricEditModal
					setSelectedModal={setSelectedEditModal}
					selectedFindingsID={selectedFindingsID}
					selectedFindings={selectedFindings}
					setSelectedFindings={setSelectedFindings}
					form={form}
					getFindingResults={getFindingResults}
					handleAsymmetryFormValues={handleAsymmetryFormValues}
					asymmetryFormValues={asymmetryFormValues}
					setFindingFormValues={setAsymmetryFormValues}
					getFindings={getFindings}
					findingData={selectedEditModal}
					editModalData={editModalData}
				/>
			) : null} */}
		</LeftDetailsContainer>
	);
}
const LeftDetailsContainer = styled.div`
	width: 25%;
	// background: #222222;
	height: 100vh;
	position: fixed;
	left: 0px;
	overflow-y: scroll;
	::-webkit-scrollbar {
		display: none;
	}

	::-webkit-scrollbar-track {
		display: none;
	}

	::-webkit-scrollbar-thumb {
		display: none;
	}
`;
const SynopticTemplateContainer = styled.div`
	background: #222222;
	border-radius: 8px;
	padding: 10px;
`;
const Text = styled.h4`
	background: #222222;
	text-align: center;
	padding: 15px 0;
	font-size: 15px;
	margin-bottom: 20px;
	border-radius: 8px;
	color: #22ade5;
`;
const Mamogram = styled.div`
	margin-bottom: 4px;
	background: #171617;
	padding: 25px 20px 15px;
`;
const SelectLateralityContainer = styled.div`
	// display: flex;
	margin-bottom: 10px;
	cursor: pointer;
	:last-child {
		margin-bottom: 0;
	}
`;

const SelectContainer = styled.div`
	display: flex;
	margin-bottom: 10px;
	cursor: pointer;
	:last-child {
		margin-bottom: 0;
	}
`;
const Heading = styled.h5`
	color: #22ade5;
	font-size: 15px;
	margin-bottom: 10px;
`;
const RadioButtonChecked = styled.div`
	background-color: #203035;
	width: 16px;
	height: 16px;
	min-width: 16px;
	min-height: 16px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
`;
const InnerCircle = styled.div`
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: #00ace2;
`;
const OptionText = styled.h6`
	color: #a3a3a3;
	font-size: 15px;
	margin-bottom: -3px;
`;
const TextArea = styled.textarea`
	border: 1px solid #5d5c5d;
	border-radius: 8px;
	padding: 10px;
	width: 100%;
	resize: vertical;
	margin-bottom: 10px;
	color: #fff;
	min-height: 100px;
	::placeholder {
		font-family: "poppinsregular";
		color: #4d595d;
	}
`;
const ButtonDiv = styled.div`
	display: flex;
	justify-content: flex-end;
`;
const Indication = styled(Mamogram)``;
const BreastComposition = styled(Mamogram)``;
const Bottom = styled.div`
	display: flex;
	align-items: baseline;
`;
const Output = styled(Heading)`
	margin-right: 10px;
`;
const Category = styled.p`
	font-size: 15px;
`;
const Findings = styled(Mamogram)``;
const Select = styled.div`
	margin-bottom: 10px;
	cursor: pointer;
	:last-child {
		margin-bottom: 0;
	}
`;
const CheckboxChecked = styled(RadioButtonChecked)`
	border-radius: 20%;
`;
const InnerSquare = styled(InnerCircle)`
	border-radius: 20%;
`;
const Highlight = styled.small`
	color: #f44b4b;
	font-size: 15px;
`;
const PreviousComparison = styled(Mamogram)``;
const AssessmentManagement = styled(Mamogram)``;
const SubmitContainer = styled(Mamogram)`
	display: flex;
	justify-content: flex-end;
	// width: 95%;
`;
const InnerContainer = styled.div`
	display: flex;
	align-items: center;
	/* margin-right: 20px; */
`;
const Cancel = styled.div`
	color: #22ade5;
	margin-right: 15px;
	font-size: 16px;
	cursor: pointer;
`;
const SubmitButton = styled.div`
	padding: 0 22px;
	height: 40px;
	color: #fff;
	background: #00ace2;
	border-radius: 5px;
	font-size: 16px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const FindingDiv = styled.div`
	display: flex;
`;
const LateralityHeading = styled.h4`
	color: #fff;
	font-size: 15px;
	margin-bottom: 5px;
`;
const Div = styled.div`
	display: flex;
	margin-bottom: 10px;
	cursor: pointer;
	align-items: center;
`;
const Input = styled.span`
	background: red;
	height: 10px;
	width: 10px;
`;
const RadioContainer = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	justify-content: center;
`;

const RadioDiv = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	margin-right: 20px;
	:last-child {
		margin-right: 0;
	}
`;

const ReferanceHeadDiv = styled.div`
	/* margin: 10px; */
	background: #222222;
	border-radius: 8px;
	margin-bottom: 10px;
	display: flex;
	/* justify-content: space-between; */
	align-items: center;
	height: 50px;
	padding: 0 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const HeadContainer = styled.div`
	width: 48%;
	height: 35px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	background-color: #343434;
	cursor: pointer;
	border-radius: 4px;
`;
const HeadText = styled.h4`
	color: #22ade5;
	font-size: 15px;
	text-align: center;
	user-select: none;
	@media (max-width: 1280px) {
		font-size: 14px;
	}
`;
