import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

export default function RadioButton({
	choice,
	handleFindingFormValues,
	sub,
	findingFormValues,
}) {
	return (
		<SelectContainer
			style={
				{
					// marginBottom: "0px",
				}
			}
			onClick={() => {
				handleFindingFormValues(sub.key_name, choice.id);
			}}
		>
			<RadioButtonChecked>
				{findingFormValues?.[sub.key_name] === choice.id && (
					<InnerCircle />
				)}
			</RadioButtonChecked>
			<OptionText>{choice.name}</OptionText>
		</SelectContainer>
	);
}

const SelectContainer = styled.div`
	display: flex;
	margin-bottom: 10px;
	cursor: pointer;
	width: 48%;
	align-items: center;
	:last-child {
		margin-bottom: 0;
	}
`;
const RadioButtonChecked = styled.div`
	background-color: #203035;
	width: 16px;
	height: 16px;
	min-width: 16px;
	min-height: 16px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
`;
const InnerCircle = styled.div`
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: #00ace2;
`;
const OptionText = styled.h6`
	color: #a3a3a3;
	font-size: 14px;
	// margin-bottom: 10px;
`;
