import React, { useState, useContext, useEffect } from "react";
import { baseConfig } from "./../../../axiosConfig";
import { Context } from "./../../../contexts/Store";

//packages
import styled from "styled-components";
import moment from "moment";
import { useParams } from "react-router-dom";

export default function ReportHeaders({ patientPersonalData, isLoading }) {
	// const { template, report_id, synoptic_id } = useParams();
	// const { state } = useContext(Context);
	// const [isLoading, setLoading] = useState(true);

	// //patient personal details
	// const [patientPersonalData, setPatientPersonalData] = useState({});

	// //get patient details
	// const getPatientDetails = () => {
	// 	setLoading(true);
	// 	let accessToken = state.user_details.access_token;
	// 	baseConfig
	// 		.get(`/dashboard/report-data/${report_id}/`, {
	// 			headers: {
	// 				Authorization: "Bearer " + accessToken,
	// 			},
	// 		})
	// 		.then((response) => {
	// 			const { StatusCode, data } = response.data;
	// 			if (StatusCode === 6000) {
	// 				setPatientPersonalData(data);
	// 				setLoading(false);
	// 			} else {
	// 				setLoading(false);
	// 			}
	// 		})
	// 		.catch((error) => {
	// 			setLoading(false);
	// 		});
	// };

	// useEffect(() => {
	// 	getPatientDetails();
	// }, []);

	return (
		!isLoading && (
			<ReportHead>
				<>
					<Left>
						<LogoImage
							src={
								require("../../../assets/images/logo.svg")
									.default
							}
						/>
						<MainText>
							RADIOLOGY
							<br />
							REPORTING
							<br />
							REDEFINED
						</MainText>
					</Left>
					<Right>
						<LeftDetails>
							<List>
								<LeftSection>Patient:</LeftSection>
								<RightSection>
									{patientPersonalData?.patient_details?.name}
								</RightSection>
							</List>
							<List>
								<LeftSection>Report ID:</LeftSection>
								<RightSection>
									{patientPersonalData?.study_id}
								</RightSection>
							</List>
							<List>
								<LeftSection>Age:</LeftSection>
								<RightSection>
									{patientPersonalData?.patient_details?.age}
								</RightSection>
							</List>
							<List>
								<LeftSection>Date:</LeftSection>
								<RightSection>
									{moment(patientPersonalData.date).format(
										"YYYY-MM-DD"
									)}
								</RightSection>
							</List>
						</LeftDetails>
					</Right>
				</>
			</ReportHead>
		)
	);
}

const ReportHead = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 65px 20px 50px;
`;
const Left = styled.div`
	display: flex;
	align-items: center;
	// width: 30%;
`;
const LogoImage = styled.img`
	height: 90px;
	width: 90px;
	margin-right: 15px;
`;
const MainText = styled.h3`
	color: #fff;
	font-size: 17px;
	line-height: 24px;
	font-family: "ramblaregular";
	@media (max-width: 1280px) {
		font-size: 16px;
	}
`;
const Right = styled.div`
	width: 55%;
`;

const LeftDetails = styled.ul`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
`;
const List = styled.li`
	width: 48%;
	display: flex;
	margin-bottom: 10px;
	:nth-last-child(-n + 2) {
		margin-bottom: 0px;
	}
	:nth-last-child(odd) {
		margin-left: 5px;
	}
`;
const LeftSection = styled.h3`
	font-size: 14px;
	font-family: "poppinsregular";
	color: #8d8d8d;
	width: 40%;
	margin-right: 5px;
	@media (max-width: 1280px) {
		font-size: 13px;
	}
`;
const RightSection = styled.h4`
	font-size: 14px;
	color: #fff;
	font-family: "poppinsregular";
	width: 47%;
	text-align: left;
	text-transform: capitalize;
	@media (max-width: 1280px) {
		font-size: 13px;
	}
`;
