import axios from "axios";

//demo
// export const baseConfig = axios.create({
// 	baseURL: "https://radiology.talrop.works/api/v1",
// });

// export const BASE_URL = "https://radiology.talrop.works/api/v1";

// http://192.168.0.139:8001/api/v1

// local
// export const baseConfig = axios.create({
// 	baseURL: "http://192.168.1.140:8001/api/v1",
// });

// export const BASE_URL = "http://192.168.1.139:8001/api/v1";

// live
export const baseConfig = axios.create({
	baseURL: "https://backend.rcube.ai/api/v1",
});

export const BASE_URL = "https://backend.rcube.ai/api/v1";
