import React, {
	useState,
	useContext,
	useEffect,
	useRef,
	useCallback,
	useLayoutEffect,
} from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { baseConfig } from "./../../../../axiosConfig";
import Draggable from "react-draggable";
import { Context } from "./../../../../contexts/Store";
import UnitDropDown from "../../UnitDropDown";
import SynopticUnitDropDown from "../SynopticUnitDropDown";
import FindingDropDown from "../../dropdown/FindingDropDown";
import SynopticDropDown from "./SynopticDropDown";
import RadioButton from "../../components/RadioButton";
import SizeInput from "../../components/SizeInput";
import LocationField from "../../components/LocationField";

export default function FindingModal({
	setFindingModal,
	selectedFindingData,
	setSelectedFindingData,
	selectedPositions,
	getFindings,
	selectedLesion,
	setSelectedLesion,
	position,
	getSynopticReport,
	setPosition,
	isFindingModal,
	submitUrl,
}) {
	const [category, setCategory] = useState({});
	const { template, report_id, synoptic_id } = useParams();
	const { state } = useContext(Context);
	const [isBalance, setBalance] = useState(false);
	const [isLoading, setLoading] = useState(true);

	//form value
	const [findingFormValues, setFindingFormValues] = useState({
		id: "",
		width: "",
		length: "",
		unit: "",
	});

	const handleFindingFormValues = (key, value) => {
		setFindingFormValues({
			...findingFormValues,
			[key]: value,
		});
	};

	const closeModal = () => {
		setSelectedFindingData({});
		setSelectedLesion({});
		setTimeout(() => {
			setFindingModal(false);
		}, 50);
	};

	const handleFindings = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.post(
				// "/mammogram/findings-text-format/",
				submitUrl,
				{
					findings_id: selectedFindingData.id,
					position: selectedPositions,
					report_id: report_id,
					...findingFormValues,
					patient_finding_id: selectedLesion ? selectedLesion.id : "",
				},
				{
					headers: {
						Authorization: "Bearer " + accessToken,
					},
				}
			)
			.then((response) => {
				const { StatusCode, message } = response.data;
				if (StatusCode === 6000) {
					closeModal();
					getFindings();
					getSynopticReport();
				} else {
					// alert(message);
				}
			})
			.catch((error) => {});
	};

	useEffect(() => {
		if (selectedLesion) {
			setFindingFormValues(selectedLesion);
		}

		return () => {
			// setSelectedLesion({});
		};
	}, []);

	//modal height
	const modalHeight = useRef(null);

	const [height, setHeight] = useState(0);

	useEffect(() => {
		// setHeight(modalHeight.current.clientHeight);
	});

	// 	const height = modalHeight.current.clientHeight;

	// 	setLoading(true);
	// 	if (position.balanceY > height) {
	// 		setBalance(true);
	// 		setPosition((prevState) => ({
	// 			...prevState,
	// 			y: position.y,
	// 		}));

	// 		// setLoading(false);
	// 	} else {
	// 		setBalance(false);
	// 		setPosition((prevState) => ({
	// 			...prevState,
	// 			y: 50,
	// 		}));

	// 		// setLoading(false);
	// 	}
	// 	return height;
	// }, [modalHeight, height]);

	//toggle
	const [isToggle, setToggle] = useState(false);

	const toggleButton = () => {
		setToggle(!isToggle);
	};
	// const val = { x: 400, y: position.y - 70 };
	const val = { x: 400, y: position.y };

	const targetRef = useRef();
	const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

	const [isActivate, setActivate] = useState(false);

	useLayoutEffect(() => {
		if (targetRef.current) {
			setDimensions({
				width: targetRef.current.offsetWidth,
				height: targetRef.current.offsetHeight,
			});
		}

		// if (position.balanceY < targetRef.current.offsetHeight) {
		// 	setBalance(false);
		// 	const result = window.innerHeight - targetRef.current.offsetHeight;
		// 	setPosition((prevState) => ({
		// 		...prevState,
		// 		y: result,
		// 	}));
		// }
	}, []);

	useLayoutEffect(() => {
		// setTimeout(() => {
		if (position.balanceY < dimensions.height) {
			const result = window.innerHeight - dimensions.height;
			setPosition((prevState) => ({
				...prevState,
				y: result,
			}));
			setActivate(false);
		} else {
			setActivate(true);
			// const result = window.innerHeight - dimensions.height;
			// setPosition((prevState) => ({
			// 	...prevState,
			// 	y: result,
			// }));
		}
		// }, 500);
	}, [dimensions, targetRef]);

	// useEffect(() => {
	// 	if (position.balanceY > dimensions.height) {
	// 		setBalance(true);
	// 		setPosition((prevState) => ({
	// 			...prevState,
	// 			y: position.y,
	// 		}));

	// 		// setLoading(false);
	// 	} else {
	// 		setBalance(false);
	// 		const result = window.innerHeight - dimensions.height;
	// 		setPosition((prevState) => ({
	// 			...prevState,
	// 			y: result,
	// 		}));

	// 		// setLoading(false);
	// 	}
	// }, [dimensions]);

	useEffect(() => {
		return () => {
			setActivate(false);
		};
	}, []);

	const [newPosition, setNewPosition] = useState({ x: 0, y: 0 });

	const activeDefaultValues = () => {
		selectedFindingData?.parameters?.map((data) => {
			if (
				data.field_type == "radio" ||
				data.field_type == "multiple_choice"
			) {
				data?.choices?.map((choice) => {
					if (choice.is_default) {
						setFindingFormValues((prev) => ({
							...prev,
							...findingFormValues,
							[data.key_name]: choice.id,
						}));
					}
				});
			}
		});
	};

	useEffect(() => {
		if (selectedFindingData && Object.keys(selectedLesion).length === 0) {
			activeDefaultValues();
		}
	}, [selectedFindingData]);

	return (
		selectedFindingData && (
			<BackContainer>
				{/* <Overlay
					onClick={() => {
						closeModal();
					}}
				></Overlay> */}
				<NewDiv
					isActivate={isActivate}
					newPosition={newPosition}
					position={position}
					className={isActivate ? "normal" : "balance"}
					style={
						{
							// position: "relative",
							// zIndex: "10000",
							// bottom: position.y,
							// left: 300,
							// top: position.y,
							// marginBottom: "30px",
							// visibility: ;
						}
					}
				>
					<Draggable
						handle="#handle"
						// defaultPosition={{
						// 	x: 300,
						// 	y: position.y,
						// }}
						bounds="body"

						// style={{
						// 	position: "absolute",
						// 	zIndex: "10000",
						// 	bottom: 0,
						// }}
					>
						<Modal
							// ref={modalHeight}
							newPosition={newPosition}
							ref={targetRef}
						>
							<MovementBar id="handle">
								<DotDiv>
									<Dot />
									<Dot />
									<Dot />
									<Dot />
									<Dot />
								</DotDiv>
							</MovementBar>
							<Container>
								<Head>
									<MainHeading>
										{selectedFindingData.name}
									</MainHeading>
									<ButtonDiv>
										<Cancel
											onClick={() => {
												closeModal();
											}}
										>
											Cancel
										</Cancel>
										<SaveButton
											onClick={() => {
												handleFindings();
											}}
										>
											Save
										</SaveButton>
									</ButtonDiv>
								</Head>
								<BodyDiv height={height}>
									{selectedFindingData?.parameters?.map(
										(data, index) => (
											<>
												<Line />

												{data.field_type === "size" ? (
													<SizeContainer>
														<SubHeading>
															{index + 1}.{" "}
															{data.name}
														</SubHeading>
														<Dimension
															style={{
																color: "#a3a3a3",
															}}
														>
															{data.sub_parameters.map(
																(sub, i) => (
																	<>
																		{sub.field_type ===
																		"text" ? (
																			<DimensionDiv>
																				<DimensionBox
																					type={
																						"number"
																					}
																					value={
																						findingFormValues[
																							sub
																								.key_name
																						]
																					}
																					onChange={(
																						e
																					) => {
																						handleFindingFormValues(
																							sub.key_name,
																							e
																								.target
																								.value
																						);
																					}}
																					onWheel={(
																						e
																					) =>
																						e.target.blur()
																					}
																				/>
																				<Cross
																					className={
																						i ===
																						0
																							? ""
																							: "none"
																					}
																				>
																					x
																				</Cross>
																			</DimensionDiv>
																		) : sub.field_type ===
																		  "unit" ? (
																			<SynopticUnitDropDown
																				items={
																					sub.choices
																				}
																				selectedValue={
																					findingFormValues[
																						sub
																							.key_name
																					]
																				}
																				setSelectedValue={
																					handleFindingFormValues
																				}
																				label={
																					sub.key_name
																				}
																			/>
																		) : null}
																	</>
																)
															)}

															{/* <DimensionBox
														type={"number"}
														value={
															findingFormValues?.length
														}
														onChange={(e) => {
															handleFindingFormValues(
																"length",
																e.target.value
															);
														}}
														onWheel={(e) =>
															e.target.blur()
														}
													/>
													x
													<DimensionBox
														style={{
															marginLeft: "10px",
														}}
														type={"number"}
														value={
															findingFormValues?.width
														}
														onChange={(e) => {
															handleFindingFormValues(
																"width",
																e.target.value
															);
														}}
														onWheel={(e) =>
															e.target.blur()
														}
													/>
													<SynopticUnitDropDown
														items={data.choices}
														selectedValue={
															findingFormValues?.unit
														}
														setSelectedValue={
															handleFindingFormValues
														}
													/> */}
														</Dimension>
													</SizeContainer>
												) : data.field_type ===
												  "location" ? (
													<SizeContainer>
														<SubHeading>
															{index + 1}.{" "}
															{data.name}
														</SubHeading>
														<OptionContainer>
															{data.sub_parameters.map(
																(subParams) => (
																	<Unit>
																		{subParams?.field_type ===
																		"select" ? (
																			<SynopticDropDown
																				items={
																					subParams?.choices
																				}
																				selectedValue={
																					findingFormValues
																				}
																				setSelectedValue={
																					handleFindingFormValues
																				}
																				label={
																					subParams.key_name
																				}
																			/>
																		) : (
																			<DistanceDiv>
																				{subParams?.field_type ===
																				"text" ? (
																					<InputContainer>
																						<DimensionBox
																							type={
																								"number"
																							}
																							value={
																								findingFormValues[
																									subParams
																										.key_name
																								]
																							}
																							onChange={(
																								e
																							) => {
																								handleFindingFormValues(
																									subParams.key_name,
																									e
																										.target
																										.value
																								);
																							}}
																							onWheel={(
																								e
																							) =>
																								e.target.blur()
																							}
																						/>
																						{subParams.is_local_parameters && (
																							<SynopticUnitDropDown
																								items={
																									subParams
																										.local_parameters
																										.choices
																								}
																								selectedValue={
																									findingFormValues[
																										subParams
																											.local_parameters
																											.key_name
																									]
																								}
																								setSelectedValue={
																									handleFindingFormValues
																								}
																								label={
																									subParams
																										.local_parameters
																										.key_name
																								}
																							/>
																						)}
																					</InputContainer>
																				) : // subParams?.field_type ===
																				//   "unit" ? (
																				// 	<SynopticUnitDropDown
																				// 		items={
																				// 			subParams.choices
																				// 		}
																				// 		selectedValue={
																				// 			findingFormValues[
																				// 				subParams
																				// 					.key_name
																				// 			]
																				// 		}
																				// 		setSelectedValue={
																				// 			handleFindingFormValues
																				// 		}
																				// 		label={
																				// 			subParams.key_name
																				// 		}
																				// 	/>
																				// ) :

																				null}
																			</DistanceDiv>
																		)}
																		<UnitText>
																			{
																				subParams.name
																			}
																		</UnitText>
																	</Unit>
																)
															)}
														</OptionContainer>
													</SizeContainer>
												) : data.field_type ===
												  "radio" ? (
													<SizeContainer>
														<SubHeading>
															{index + 1}.{" "}
															{data.name}
														</SubHeading>
														<BottomContainer>
															{data?.choices?.map(
																(choice) => (
																	<SelectContainer
																		onClick={() => {
																			handleFindingFormValues(
																				data.key_name,
																				choice.id
																			);
																		}}
																		key={
																			choice.id
																		}
																	>
																		<RadioButtonChecked>
																			{findingFormValues?.[
																				data
																					.key_name
																			] ===
																				choice.id && (
																				<InnerCircle />
																			)}
																		</RadioButtonChecked>
																		<OptionText>
																			{
																				choice.name
																			}
																		</OptionText>
																	</SelectContainer>
																)
															)}
														</BottomContainer>
													</SizeContainer>
												) : data.field_type ===
												  "comment" ? (
													<Comment>
														{/* <Line /> */}
														<SubHeading>
															{index + 1}.{" "}
															{data.name}
														</SubHeading>
														<TextArea
															placeholder="Write Here..."
															onChange={(e) => {
																handleFindingFormValues(
																	data.key_name,
																	e.target
																		.value
																);
															}}
															value={
																findingFormValues[
																	data
																		.key_name
																]
															}
														/>
													</Comment>
												) : data.field_type ===
												  "choice" ? (
													<>
														<HeadingDiv>
															<SubHeading>
																{index + 1}.{" "}
																{data.name}
															</SubHeading>
															{/* <ToggleContainer
														onClick={() => {
															// toggleButton();
															handleFindingFormValues(
																data.key_name,



																// e.target.value
															);
														}}
													>
														<ToggleDiv>
															<Round
																className={
																	isToggle
																		? "toggle"
																		: "non-toggle"
																}
															/>
														</ToggleDiv>
													</ToggleContainer> */}
															{/* <ToggleBox>
														<ToggleInput
															type="checkbox"
															onChange={(e) => {
																
																setToggle(
																	e.target
																		.checked
																);
																handleFindingFormValues(
																	data.key_name,
																	e.target
																		.value
																);
															}}
															checked={isToggle}
														/>
													</ToggleBox> */}
														</HeadingDiv>
														<BottomContainer>
															{data?.choices?.map(
																(choice, i) => (
																	<SelectContainer
																		key={i}
																		onClick={() => {
																			handleFindingFormValues(
																				data.key_name,
																				choice.id
																			);
																		}}
																	>
																		<RadioButtonChecked>
																			{findingFormValues?.[
																				data
																					.key_name
																			] ===
																				choice.id && (
																				<InnerCircle />
																			)}
																		</RadioButtonChecked>
																		<OptionText>
																			{
																				choice.name
																			}
																		</OptionText>
																	</SelectContainer>
																)
															)}
														</BottomContainer>
														{data?.choices?.map(
															(item, i) => (
																<div key={i}>
																	{item.id ===
																		findingFormValues[
																			data
																				.key_name
																		] && (
																		<>
																			{item.name ===
																				"Yes" && (
																				<BottomSubContainer>
																					{data?.sub_parameters.map(
																						(
																							sub,
																							i
																						) => (
																							<>
																								<SubHeading>
																									{i +
																										1}

																									.{" "}
																									{
																										sub.name
																									}
																								</SubHeading>

																								<BottomContainer>
																									{sub.field_type ===
																									"radio" ? (
																										<>
																											{sub?.choices?.map(
																												(
																													choice,
																													choice_index
																												) => (
																													<RadioButton
																														sub={
																															sub
																														}
																														choice={
																															choice
																														}
																														handleFindingFormValues={
																															handleFindingFormValues
																														}
																														findingFormValues={
																															findingFormValues
																														}
																													/>
																													// <SelectContainer
																													// 	style={{
																													// 		marginBottom:
																													// 			"0px",
																													// 	}}
																													// 	onClick={() => {
																													// 		handleFindingFormValues(
																													// 			sub.key_name,
																													// 			choice.id
																													// 		);
																													// 	}}
																													// >
																													// 	<RadioButtonChecked>
																													// 		{findingFormValues?.[
																													// 			sub
																													// 				.key_name
																													// 		] ===
																													// 			choice.id && (
																													// 			<InnerCircle />
																													// 		)}
																													// 	</RadioButtonChecked>
																													// 	<OptionText>
																													// 		{
																													// 			choice.name
																													// 		}
																													// 	</OptionText>
																													// </SelectContainer>
																												)
																											)}
																										</>
																									) : sub.field_type ===
																									  "size" ? (
																										<>
																											{sub?.local_parameters?.map(
																												(
																													choice,
																													choice_index
																												) => (
																													<SizeInput
																														sub={
																															sub
																														}
																														choice={
																															choice
																														}
																														handleFindingFormValues={
																															handleFindingFormValues
																														}
																														findingFormValues={
																															findingFormValues
																														}
																														i={
																															choice_index
																														}
																													/>
																												)
																											)}
																										</>
																									) : sub.field_type ===
																									  "other" ? (
																										<OptionContainer>
																											{sub?.local_parameters?.map(
																												(
																													choice,
																													choice_index
																												) => (
																													<Unit>
																														<LocationField
																															key={
																																choice_index
																															}
																															choice={
																																choice
																															}
																															handleFindingFormValues={
																																handleFindingFormValues
																															}
																															findingFormValues={
																																findingFormValues
																															}
																														/>
																													</Unit>
																												)
																											)}
																										</OptionContainer>
																									) : sub.field_type ==
																									  "comment" ? (
																										<>
																											<TextArea
																												placeholder="Write Here..."
																												onChange={(
																													e
																												) => {
																													handleFindingFormValues(
																														sub.key_name,
																														e
																															.target
																															.value
																													);
																												}}
																												value={
																													findingFormValues[
																														sub
																															.key_name
																													]
																												}
																											/>
																										</>
																									) : null}
																									<Line
																										className={
																											data
																												.sub_parameters
																												.length ===
																												i +
																													1 &&
																											"last"
																										}
																									/>
																								</BottomContainer>
																							</>
																						)
																					)}
																				</BottomSubContainer>
																			)}
																		</>
																	)}
																</div>
															)
														)}

														{/* findingFormValues[ data.key_name ] */}
													</>
												) : data.field_type ==
												  "multiple_choice" ? (
													<>
														<HeadingDiv>
															<SubHeading>
																{index + 1}.{" "}
																{data.name}
															</SubHeading>
														</HeadingDiv>

														<BottomContainer>
															{data?.choices?.map(
																(choice) => (
																	<SelectContainer
																		onClick={() => {
																			handleFindingFormValues(
																				data.key_name,
																				choice.id
																			);
																		}}
																	>
																		<RadioButtonChecked>
																			{findingFormValues?.[
																				data
																					.key_name
																			] ===
																				choice.id && (
																				<InnerCircle />
																			)}
																		</RadioButtonChecked>
																		<OptionText>
																			{
																				choice.name
																			}
																		</OptionText>
																	</SelectContainer>
																)
															)}
														</BottomContainer>
														{data?.choices?.map(
															(item, i) => (
																<div>
																	{item.id ===
																		findingFormValues[
																			data
																				.key_name
																		] && (
																		<>
																			{item.is_sub_choices && (
																				<BottomSubContainer>
																					<BottomContainer>
																						{item?.choices?.map(
																							(
																								choice,
																								choice_index
																							) => (
																								<RadioButton
																									sub={
																										item
																									}
																									choice={
																										choice
																									}
																									handleFindingFormValues={
																										handleFindingFormValues
																									}
																									findingFormValues={
																										findingFormValues
																									}
																								/>
																							)
																						)}
																					</BottomContainer>
																				</BottomSubContainer>
																			)}
																		</>
																	)}
																</div>
															)
														)}
													</>
												) : null}
											</>
										)
									)}
									{/* <Comment>
								<Line />
								<SubHeading>
									{selectedFindingData?.parameters?.length +
										1}
									. Comment
								</SubHeading>
								<TextArea
									placeholder="Write Here..."
									// onChange={(e) => {
									// 	handleFindingFormValues(
									// 		"MassComment",
									// 		e.target.value
									// 	);
									// }}
									// value={findingFormValues?.MassComment}
								/>
							</Comment> */}
								</BodyDiv>
							</Container>
						</Modal>
					</Draggable>
				</NewDiv>
			</BackContainer>
		)
	);
}

const BackContainer = styled.div`
	position: fixed;
	transition: 0.3s;
	/* width: 100%; */
	/* height: 100vh; */
	/* z-index: 1000; */
	left: 0;
	top: 0px;
	/* background: rgba(0, 0, 0, 0.4); */
`;
const Overlay = styled.div`
	position: fixed;
	left: 0;
	top: 0px;
	width: 100%;
	cursor: pointer;
	height: 100vh;
`;
const NewDiv = styled.div`
	/* height: 100%; */
	height: ${(props) => (props.isActivate ? `auto` : `100%`)};
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	z-index: -1;

	//
	/* display: flex;
	align-items: center;
	justify-content: center; */
	/* newPosition */

	//
	display: flex;
	align-items: center;
	justify-content: start;
	/* margin-left: 420px; */
	margin: ${(props) =>
		props.isActivate
			? `${props.position.y}px 0px 0px 420px`
			: `0px 0px 0px 0px`};

	&.normal {
	}
	&.balance {
		margin-left: 420px;
	}

	/* left: 300; */
`;
const Modal = styled.div`
	margin-bottom: 10px;
	background: #222222;
	border-radius: 12px;
	transition: 0.1s;
	z-index: 101;
	width: 500px;
	/* transform: ${(props) =>
		props.newPosition
			? `translateX(${props.newPosition.x}px)`
			: `translateX(10px)`} !important; */
	/* transform: translate(200px, 200px) !important; */
	/* transform: ${(props) =>
		props.newPosition
			? `translate(${props.newPosition.x}px,${props.newPosition.y}px)`
			: `translate(10px,10px)`} !important; */
	/* max-height: calc(100vh - 100px); */
	/* overflow-y: auto; */
	user-select: none;

	/* &::-webkit-scrollbar {
		display: none;
		-ms-overflow-style: none;
		scrollbar-width: none;
	} */
	scrollbar-width: 16px;
	border: 1px solid #c1c1c1;
`;
const Container = styled.div`
	/* &::after {
		content: "";
		position: absolute;
		top: 50px;
		left: -45px;
		margin-top: -5px;
		border-width: 25px;
		border-style: solid;
		border-color: transparent #222222 transparent transparent;
	} */
`;
const ButtonDiv = styled.div`
	display: flex;
`;
const MovementBar = styled.div`
	background: linear-gradient(360deg, #292929 0%, #4b4b4b 100%);
	height: 30px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: move;
	border-radius: 12px 12px 0px 0px;
`;
const DotDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;
const Dot = styled.div`
	height: 8px;
	width: 8px;
	border-radius: 50%;
	background-color: #212121;
	margin-right: 5px;
`;
const Head = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 20px;
	height: 30px;
	margin-bottom: 10px;
`;
const BodyDiv = styled.div`
	max-height: calc(100vh - 200px);
	/* max-height: ${(props) =>
		props.active ? `calc(${props.height}px - 110px)` : "500px"}; */
	overflow-y: scroll;
	/* padding-right: 10px; */
	margin: 20px;
	margin-right: 5px;
	padding-right: 5px;
	padding-bottom: 10px;
	&::-webkit-scrollbar {
		width: 8px;
		border: 1px solid #222;
		background: #222;
		border-radius: 5px;
	}
	::-webkit-scrollbar-thumb:hover {
		background: #00ace2;
	}

	::-webkit-scrollbar-thumb {
		background: #00ace2;
		border-radius: 10px;
	}
`;
const Line = styled.div`
	height: 1px;
	background: #555555;
	width: 100%;
	margin: 10px 0;
	&.last {
		display: none;
	}
`;
const MainHeading = styled.h3`
	font-family: "poppinsmedium";
	color: #ff4c51;
	font-size: 18px;
`;
const SaveButton = styled.div`
	color: #00ace2;
	font-size: 13px;
	background: #203035;
	padding: 8px 10px;
	border-radius: 8px;
	cursor: pointer;
`;
const Cancel = styled.div`
	color: #00ace2;
	font-size: 13px;
	// background: #203035;
	padding: 8px 10px;
	border-radius: 8px;
	cursor: pointer;
	margin-right: 10px;
`;
const SizeContainer = styled.div`
	margin-bottom: 5px;
`;

const SubHeading = styled.h4`
	font-family: "poppinsmedium";
	color: #00ace2;
	font-size: 14px;
	margin-bottom: 5px;
	text-align: left;
`;
const Dimension = styled.div`
	display: flex;
	/* justify-content: space-between; */
	align-items: center;
	width: 65%;
	padding-left: 20px;
`;
const DimensionDiv = styled.div`
	display: flex;
	align-items: center;
	/* justify-content: center; */
`;
const Cross = styled.div`
	margin-right: 10px;
	&.none {
		display: none;
	}
`;

const DimensionBox = styled.input`
	padding: 0px 10px;
	width: 60px;
	background: #223035;
	border: 1px solid #30434a;
	border-radius: 5px;
	color: #fff;
	font-size: 14px;
	height: 40px;
	margin-right: 10px;
	&:after {
		content: "x";
	}
	&:focus {
		border-color: #aaa;
	}
`;

const Comment = styled.div``;
const TextArea = styled.textarea`
	border: 1px solid #5d5c5d;
	border-radius: 8px;
	padding: 10px;
	color: #fff;
	background: #203035;
	width: 100%;
	resize: vertical;
	height: 100px;
	::placeholder {
		font-family: "poppinsregular";
		color: #4d595d;
	}
	&:focus {
		border-color: #aaa;
	}
`;

const OptionContainer = styled.div`
	/* display: flex;
	justify-content: space-between;
	flex-wrap: wrap; */
	padding-left: 20px;

	display: grid !important;
	grid-template-columns: 1fr 1fr;
	/* grid-gap: 10px; */
`;
const Unit = styled.div`
	display: flex;
	align-items: center;
	color: #a3a3a3;
	/* width: 50%; */
	margin-bottom: 10px;
	position: relative;
	:nth-last-child(-n + 2) {
		margin-bottom: 0;
	}
`;
const DistanceDiv = styled.div`
	display: flex;
`;
const UnitText = styled.h6`
	color: #a3a3a3;
	font-size: 14px;
	margin-left: 10px;
	white-space: nowrap;
`;
const InputContainer = styled.div`
	display: flex;
	align-items: center;
`;
const BottomContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	/* justify-content: space-between; */
`;
const SelectContainer = styled.div`
	display: flex;
	margin-bottom: 10px;
	cursor: pointer;
	width: 48%;
	/* align-items: center; */
	:last-child {
		margin-bottom: 0;
	}
`;
const RadioButtonChecked = styled.div`
	background-color: #203035;
	width: 16px;
	height: 16px;
	min-width: 16px;
	min-height: 16px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
	margin-top: 2px;
`;
const InnerCircle = styled.div`
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: #00ace2;
`;
const OptionText = styled.h6`
	color: #a3a3a3;
	font-size: 14px;
	text-align: left;
	// margin-bottom: 10px;
`;
const HeadingDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const ToggleContainer = styled.div``;
const ToggleDiv = styled.div`
	height: 22px;
	width: 40px;
	padding: 2px;
	background: red;
	border-radius: 56px;
	position: relative;
	display: block;
	&:hover {
		cursor: pointer;
	}

	/* transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms; */
`;
const Round = styled.div`
	height: 18px;
	width: 18px;
	background: #22ade5;
	border-radius: 50%;
	position: absolute;
	transition: all 0.5s;
	transition: width 1s ease-in, height 1s ease-in;
	/* transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms; */

	&.toggle {
		right: 2px;
	}
	&.non-toggle {
		left: 2px;
	}
`;

const ToggleBox = styled.div`
	/* transform: scale(1); */
`;
const ToggleLabel = styled.label`
	display: block;
	position: relative;
	padding-left: 40px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	user-select: none;
`;
const ToggleInput = styled.input`
	position: relative;
	appearance: none;
	width: 40px;
	height: 22px;
	background: #223035;
	border-radius: 56px;
	/* box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); */
	cursor: pointer;
	transition: 0.4s;
	padding: 2px;

	&:checked {
		background: #223035;
		:after {
			left: 50%;
			background: #22ade5;
		}
	}

	:after {
		position: absolute;
		content: "";
		width: 18px;
		height: 18px;
		/* top: 0; */
		/* left: 0; */
		background: #ccc;
		border-radius: 50%;
		/* box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); */
		/* transform: scale(1.1); */
		transition: 0.4s;
	}
`;
const ToggleSpan = styled.span``;
const BottomSubContainer = styled.div`
	background: #272727;
	padding: 15px;
	border-radius: 8px;
`;
