import React, { useState } from "react";
import styled from "styled-components";
// import "./styles.css";

export default function CategoryCommonPopup({
	item,
	setSelectedModal,
	setGlobalFindCheckbox,
	globalFindCheckbox,
	setSelectedFindings,
	selectedFindings,
	setSelectedFindingsID,
	handleFindings,
	setFindingData,
	deleteLesion,
	setSelectedEditModal,
	setEditModalData,
	position,
	setAssessmentPositions,
	setSelectedLesionDetails,
}) {
	const [active, setActive] = useState(false);

	const toggleActiveSize = () => setActive(!active);

	return (
		<MainContainer>
			<TopDiv
				onClick={() => {
					toggleActiveSize();
					setSelectedModal(item.name);
					// setGlobalFindCheckbox(active);
					setSelectedFindingsID(item.id);
					// setSelectedFindings([...selectedFindings, item?.id]);
					// handleFindings(item.id);
					setFindingData(item);

					setAssessmentPositions(position);
				}}
			>
				{/* {active ? ( */}
				{/* <Select
				onClick={() => {
					toggleActiveSize();
					setSelectedModal("");
					setSelectedFindings(
						selectedFindings.filter((data) => data !== item?.id)
					);
				}}
			>
				<SelectContainer>
					<CheckboxChecked>
						<InnerSquare />
					</CheckboxChecked>
					<OptionText>
						{item.title.substring(0, item.title.indexOf("!!"))}
						<Highlight>
							{item.title.substring(item.title.indexOf("!!"))}
						</Highlight>
					</OptionText>
				</SelectContainer>
			</Select> */}
				{/* // ) : ( */}
				<Select
				// onClick={() => {
				// 	toggleActiveSize();
				// 	setSelectedModal(item.name);
				// 	// setGlobalFindCheckbox(active);
				// 	setSelectedFindingsID(item.id);
				// 	// setSelectedFindings([...selectedFindings, item?.id]);
				// 	// handleFindings(item.id);
				// 	setFindingData(item);
				// }}
				>
					<SelectContainer>
						<CheckboxChecked>
							{/* {selectedFindings.find(
								(element) => element == item?.id
							) && <InnerSquare />} */}

							{item.patient_findings && <InnerSquare />}
						</CheckboxChecked>
						{/* <OptionText>
							{item.title.substring(item.title.indexOf("!!"))}
							<OptionText color="red">
								{item.title.substring(item.title.indexOf("!!"))}
							</OptionText>
						</OptionText> */}

						<OptionText>
							{item.title.substring(0, item.title.indexOf("!!"))}
							<Highlight>
								{item.title.substring(item.title.indexOf("!!"))}
							</Highlight>
						</OptionText>
					</SelectContainer>
				</Select>
				<AddDiv>
					<AddImage
						src={
							require("./../../../assets/icons/add-icon.svg")
								.default
						}
						alt="icon"
					/>
				</AddDiv>
				{/* // )} */}
			</TopDiv>
			{item?.patient_findings?.length > 0 && (
				<>
					{item?.patient_findings?.map((data, index) => (
						<BottomDiv>
							<Highlight style={{ width: "70px" }}>
								Lesion {index + 1}
							</Highlight>
							<LessDiv
								onClick={() => {
									deleteLesion(data.id);
								}}
							>
								<LessImage
									src={
										require("./../../../assets/icons/hide-icon.svg")
											.default
									}
								/>
							</LessDiv>
							<LessDiv
								style={{ marginLeft: "15px" }}
								onClick={() => {
									// setSelectedEditModal(item);
									// setEditModalData(data);
									setSelectedFindingsID(item.id);
									setSelectedLesionDetails(data);
									setSelectedModal(item.name);
									setFindingData(item);
								}}
							>
								<LessImage
									src={
										require("./../../../assets/icons/edit.svg")
											.default
									}
								/>
							</LessDiv>
						</BottomDiv>
					))}
				</>
			)}
		</MainContainer>
	);
}
const MainContainer = styled.div`
	margin-bottom: 10px;
	:last-child {
		margin-bottom: 0;
	}
`;
const TopDiv = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 8px;
`;
const Select = styled.div`
	// margin-bottom: 10px;
	// cursor: pointer;
	// :last-child {
	// 	margin-bottom: 0;
	// }
`;
const CheckboxChecked = styled.div`
	border-radius: 20%;
	background-color: #203035;
	width: 16px;
	height: 16px;
	min-width: 16px;
	min-height: 16px;
	border-radius: 20%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
`;
const SelectContainer = styled.div`
	display: flex;
	margin-bottom: 10px;
	cursor: pointer;
	align-items: center;
	:last-child {
		margin-bottom: 0;
	}
`;
const OptionText = styled.h6`
	color: #a3a3a3;
	font-size: 15px;
	// margin-bottom: 10px;
	color: ${(props) => (props.color ? props.color : "#a3a3a3")};
`;
const InnerSquare = styled.div`
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: #00ace2;
	border-radius: 20%;
`;
const Highlight = styled.small`
	color: #f44b4b;
	font-size: 15px;
`;
const AddDiv = styled.div`
	margin-left: 10px;
	cursor: pointer;

	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	&:hover {
		background-color: #333;
	}
`;
const AddImage = styled.img``;
const BottomDiv = styled.div`
	margin-left: 30px;
	display: flex;
	align-items: center;
	margin-top: 3px;
	margin-bottom: px;
`;
const LessDiv = styled.div`
	margin-left: 10px;
	cursor: pointer;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	&:hover {
		background-color: #333;
	}
`;
const LessImage = styled.img``;
