import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

function OptionModal({
	optionsItem,
	toggleActiveOption,
	setSelectedOption,
	top,
	setSelectedOptionId,
	title,
}) {
	return (
		<BackContainer>
			<Overlay
				onClick={() => {
					toggleActiveOption();
				}}
			></Overlay>
			<Modal top={top}>
				<Container>
					{optionsItem.map((data) => (
						<Option
							key={data.id}
							onClick={() => {
								toggleActiveOption();
								setSelectedOption(`${title} : ${data.name}`);
								setSelectedOptionId(data.id);
							}}
						>
							{data.name}
						</Option>
					))}
				</Container>
			</Modal>
		</BackContainer>
	);
}
export default OptionModal;

const BackContainer = styled.div`
	position: fixed;
	transition: 0.3s;
	width: 100%;
	height: 100vh;
	z-index: 1000;
	left: 0;
	top: 0px;
	background: rgba(0, 0, 0, 0);
`;
const Overlay = styled.div`
	position: fixed;
	left: 0;
	top: 0px;
	width: 100%;
	cursor: pointer;
	height: 100vh;
`;
const Modal = styled.div`
	margin: 0 auto;
	background: #333333;
	width: calc(25% - 40px);
	left: 20px;
	top: ${(props) => (props.top ? props.top : "50%")};
	position: absolute;
	border-radius: 12px;
	transition: 0.5s;
	z-index: 101;
`;
const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;
const Option = styled.h4`
	color: #fff;
	font-size: 15px;
	font-family: "poppinsregular";
	height: 50px;
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 0 15px;
	:hover {
		background-color: #424242;
	}
	:first-child {
		border-top-left-radius: 12px;
		border-top-right-radius: 12px;
	}
	:last-child {
		border-bottom-left-radius: 12px;
		border-bottom-right-radius: 12px;
	}
	@media (max-width: 1280px) {
		font-size: 14px;
	}
`;
