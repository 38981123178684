import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { baseConfig } from "./../../../axiosConfig";
import { Context } from "./../../../contexts/Store";

export default function Composition({ getSynopticReport }) {
	const [output, setOutPut] = useState({});
	const [optionsComposition, setOptionsComposition] = useState([]);
	const [title, setTitle] = useState("");

	const { template, report_id, synoptic_id } = useParams();
	const { state } = useContext(Context);

	const getComposition = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`/mammogram/composition/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
				params: {
					mammogram_id: synoptic_id,
				},
			})
			.then((response) => {
				const { StatusCode, data, title } = response.data;
				if (StatusCode === 6000) {
					setOptionsComposition(data);
					setTitle(title);
					data.map((item) => {
						item.active && setOutPut(item);
					});
				} else {
				}
			})
			.catch((error) => {});
	};

	const saveComposition = (id) => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.post(
				"/mammogram/composition-save/",
				{
					mammogram_id: synoptic_id,
					composition_id: id,
				},
				{
					headers: {
						Authorization: "Bearer " + accessToken,
					},
				}
			)
			.then((response) => {
				const { StatusCode, reference_data } = response.data;
				if (StatusCode === 6000) {
					getComposition();
					getSynopticReport();
				} else {
					alert("Submition failed");
				}
			})
			.catch((error) => {});
	};

	useEffect(() => {
		getComposition();
	}, []);

	return (
		<MainContainer>
			<Heading>2. {title}</Heading>
			{optionsComposition.map((data, i) => (
				<SelectContainer
					key={i}
					onClick={() => {
						// toggleActiveOption();
						// setSelectedComposition(data.title);
						// setCompositionCategory(data.category);
						saveComposition(data.id);
					}}
				>
					<RadioButtonChecked>
						{data.active && <InnerCircle></InnerCircle>}
					</RadioButtonChecked>
					<OptionText>{data.title}</OptionText>
				</SelectContainer>
			))}
			{output.category && (
				<Bottom>
					<Output>OUTPUT:</Output>
					<Category>{output.category}</Category>
				</Bottom>
			)}
		</MainContainer>
	);
}

const MainContainer = styled.section`
	z-index: 0;
	width: 100%;
`;

const Heading = styled.h5`
	color: #22ade5;
	font-size: 15px;
	margin-bottom: 10px;
`;

const SelectContainer = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	cursor: pointer;
	:last-child {
		margin-bottom: 0;
	}
`;
const RadioButtonChecked = styled.div`
	background-color: #203035;
	width: 16px;
	height: 16px;
	min-width: 16px;
	min-height: 16px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
`;
const InnerCircle = styled.div`
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: #00ace2;
`;
const OptionText = styled.h6`
	color: #a3a3a3;
	font-size: 15px;
	margin-bottom: -3px;
`;

const Output = styled(Heading)`
	margin-right: 10px;
	margin-bottom: 0px;
`;
const Category = styled.p`
	font-size: 15px;
`;
const Bottom = styled.div`
	display: flex;
	align-items: baseline;
`;
