import React, { useEffect, useState, useContext, useRef } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PrimaryLeftContainer from "./PrimaryLeftContainer";
import PatientDetails from "./PatientDetails";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./TextEditor.css";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import { baseConfig } from "./../../../axiosConfig";
import { Context } from "./../../../contexts/Store";
// import {
// 	Document,
// 	Page,
// 	Text,
// 	View,
// 	StyleSheet,
// 	PDFDownloadLink,
// } from "@react-pdf/renderer";
import moment from "moment";
import AlertBox from "../../includes/AlertBox";
import CKEditorClassic from "../../includes/home/CKEditorClassic";

import SimpleDraftjs from "../../includes/home/SimpleDraftjs";

// import { Document, Page } from "react-pdf";

import jsPDF from "jspdf";
import ReactDOMServer from "react-dom/server";
import domtoimage from "dom-to-image";
import html2canvas from "html2canvas";

export default function PrimaryPage({ ...props }) {
	// const location = useLocation();
	// const [singlePatientData] = useState(location?.state);
	const [selectedField, setSelectedField] = useState("Subtitle");
	const [isFieldActive, setFieldActive] = useState(false);
	const { state } = useContext(Context);
	// const [reportId, setReportId] = useState({});
	const [patientPersonalData, setPatientPersonalData] = useState({});
	const [urlPdf, setUrlPdf] = useState("");
	const [optionsItem, setOptionItem] = useState([
		{
			id: 1,
			title: "Subtitle",
		},
		{
			id: 2,
			title: "Paragraph",
		},
	]);
	const [pdfdata, setPdfData] = useState();

	// useParams
	const { report_Id } = useParams();

	const history = useHistory();
	const [fieldAlignment, setFieldAlignment] = useState("");

	const toggleActiveOption = () => {
		setFieldActive(!isFieldActive);
	};
	const [note, setNote] = useState([]);
	const [inputValue, setInputValue] = useState("");
	const [changedValue, setChangedValue] = useState("");
	const [patientDetailsModal, setPatientDetailsModal] = useState(false);
	const [drawerModal, setDrawerModal] = useState(false);
	const toggleDrawer = () => {
		setDrawerModal(!drawerModal);
	};

	const [pdfText, setPDFText] = useState("");

	const quill = useRef();
	// const editor = quill.current?.getEditor();
	//editor
	const [value, setValue] = useState("");

	//alert states
	const [alertStatus, setAlertStatus] = useState("");
	const [alertMessage, setAlertMessage] = useState("");
	const [isAlert, setIsAlert] = useState(false);

	const handleSubmit = (
		e,
		note,
		setNote,
		inputValue,
		setInputValue,
		selectedField,
		setSelectedField
	) => {
		if (e.keyCode === 13) {
			e.preventDefault();
			const id = note.length ? note[note.length - 1].id + 1 : 0;
			setNote([
				...note,
				{ id: id, text: inputValue, type: selectedField },
			]);
			setInputValue("");
		}
	};

	const handleChangedValue = (e, item) => {
		if (e.keyCode === 13) {
			e.preventDefault();
			note.find((v) => v.id === item).text = changedValue;
		}
		// if (e.keyCode === 13) {
		// 	e.preventDefault();
		// 	note.find((v) => v.id ===   id).text = changedValue;
		// }
	};

	// const getReportID = () => {
	// 	let accessToken = state.user_details.access_token;
	// 	baseConfig
	// 		.get(`/dashboard/new-report/${report_Id}`, {
	// 			headers: {
	// 				Authorization: "Bearer " + accessToken,
	// 			},
	// 		})
	// 		.then((response) => {
	// 			const { StatusCode, data } = response.data;
	// 			if (StatusCode === 6000) {
	// 				setReportId(data);
	// 			} else {
	// 			}
	// 		})
	// 		.catch((error) => {});
	// };

	const getPatientDetails = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`/dashboard/report-data/${report_Id}/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setPatientPersonalData(data);
				} else {
				}
			})
			.catch((error) => {});
	};

	const submitTextEditor = () => {
		let accessToken = state.user_details.access_token;
		let input = {
			report_data: note,
		};
		baseConfig
			.post(`/dashboard/download-text-report/${report_Id}/`, input, {
				headers: {
					Authorization: "Bearer " + accessToken,
					Accept: "application/json",
					"Content-Type": "application/json",
				},
			})
			.then((response) => {
				const { StatusCode, url } = response.data;
				if (StatusCode === 6000) {
					setUrlPdf(url);
				} else {
				}
			})
			.catch((error) => {});
	};

	useEffect(() => {
		// getReportID();
	}, []);

	useEffect(() => {
		getPatientDetails();
	}, []);

	const [userInfo, setUserInfo] = useState({
		information: "",
	});

	const onInformation = (value) => {
		setUserInfo({ ...userInfo, information: value });
	};

	// const styles = StyleSheet.create({
	// 	page: {
	// 		flexDirection: "row",
	// 		backgroundColor: "#E4E4E4",
	// 	},
	// 	section: {
	// 		margin: 10,
	// 		padding: 10,
	// 		flexGrow: 1,
	// 	},
	// 	text: {
	// 		fontSize: "14px",
	// 		color: "red",
	// 	},
	// });

	// error props object
	let propsObject = {
		status: alertStatus,
		isAlert: isAlert,
		setIsAlert: setIsAlert,
		setAlertStatus: setAlertStatus,
		alertMessage: alertMessage,
	};

	//active errors
	const activeError = (status, message) => {
		setAlertStatus(status);
		setAlertMessage(message);
		setIsAlert(true);
	};

	const [ckEditor, setCkEditor] = useState(null);

	const download = () => {
		let pdf = new jsPDF("p", "pt", "letter");
		pdf.html(document.getElementById("ids"), {
			callback: function () {
				pdf.save("myDocument.pdf");
				window.open(pdf.output("bloburl")); // To debug.
			},
		});
	};

	const exportToPDF = () => {
		const pdf = new jsPDF();
		const text = pdfText; // get the contents of the text editor here
		pdf.text(text, 10, 10);
		pdf.save("text.pdf");
	};

	const downloadPDF = () => {
		// const doc = new jsPDF();
		// let html = pdfText;
		// html = html.replace(
		// 	/<p>/g,
		// 	'<p style="color: black; font-size: 10px;">'
		// );

		// doc.html(html, {
		// 	callback: (doc) => {
		// 		doc.save("document.pdf");
		// 	},
		// });

		// const html = stateToHTML(editorState.getCurrentContent());

		const element = document.querySelector(".editor-class");
		element.style.color = "#000";
		const h3 = element.querySelector("h3");

		// if (h3) {
		// 	h3.style.color = "#000";
		// }

		// html2canvas(element).then((newCanvas) => {
		// 	// const doc = new jsPDF();
		// 	// doc.addImage(newCanvas, "PNG", 50, 50);
		// 	// doc.addPage();
		// 	// doc.save("document.pdf");
		// 	// element.style.color = "#fff";

		// 	// if (h3) {
		// 	// 	h3.style.color = "#fff";
		// 	// }

		// 	const imgWidth = 208;
		// 	const pageHeight = 295;
		// 	const imgHeight = (newCanvas.height * imgWidth) / newCanvas.width;
		// 	let heightLeft = imgHeight;
		// 	let position = 0;
		// 	heightLeft -= pageHeight;
		// 	const doc = new jsPDF("p", "mm");
		// 	doc.addImage(
		// 		newCanvas,
		// 		"PNG",
		// 		0,
		// 		position,
		// 		imgWidth,
		// 		imgHeight,
		// 		"",
		// 		"FAST"
		// 	);
		// 	while (heightLeft >= 0) {
		// 		position = heightLeft - imgHeight;
		// 		doc.addPage();
		// 		doc.addImage(
		// 			newCanvas,
		// 			"PNG",
		// 			0,
		// 			position,
		// 			imgWidth,
		// 			imgHeight,
		// 			"",
		// 			"FAST"
		// 		);
		// 		heightLeft -= pageHeight;
		// 	}
		// 	doc.save("Download.pdf");
		// 	element.style.color = "#fff";
		// });

		html2canvas(element, {
			useCORS: true,
			allowTaint: true,
			scrollY: 0,
		}).then((canvas) => {
			const image = { type: "jpeg", quality: 0.98 };
			const margin = [0.5, 0.5];
			const filename = "myfile.pdf";

			var imgWidth = 8.5;
			var pageHeight = 11;

			var innerPageWidth = imgWidth - margin[0] * 2;
			var innerPageHeight = pageHeight - margin[1] * 2;

			// Calculate the number of pages.
			var pxFullHeight = canvas.height;
			var pxPageHeight = Math.floor(
				canvas.width * (pageHeight / imgWidth)
			);
			var nPages = Math.ceil(pxFullHeight / pxPageHeight);

			// Define pageHeight separately so it can be trimmed on the final page.
			var pageHeight = innerPageHeight;

			// Create a one-page canvas to split up the full image.
			var pageCanvas = document.createElement("canvas");
			var pageCtx = pageCanvas.getContext("2d");
			pageCanvas.width = canvas.width;
			pageCanvas.height = pxPageHeight;

			// Initialize the PDF.
			var pdf = new jsPDF("p", "in", [8.5, 11]);

			for (var page = 0; page < nPages; page++) {
				// Trim the final page to reduce file size.
				if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
					pageCanvas.height = pxFullHeight % pxPageHeight;
					pageHeight =
						(pageCanvas.height * innerPageWidth) / pageCanvas.width;
				}

				// Display the page.
				var w = pageCanvas.width;
				var h = pageCanvas.height;
				pageCtx.fillStyle = "white";
				pageCtx.fillRect(0, 0, w, h);
				pageCtx.drawImage(
					canvas,
					0,
					page * pxPageHeight,
					w,
					h,
					0,
					0,
					w,
					h
				);

				// Add the page to the PDF.
				if (page > 0) pdf.addPage();
				debugger;
				var imgData = pageCanvas.toDataURL(
					"image/" + image.type,
					image.quality
				);
				pdf.addImage(
					imgData,
					image.type,
					margin[1],
					margin[0],
					innerPageWidth,
					pageHeight
				);
			}

			pdf.save(`report_${patientPersonalData?.study_id}.pdf`);
			element.style.color = "#fff";
		});
	};

	const exportPDF = () => {
		// let element = (
		// 	<html>
		// 		<body>
		// 			<div style={{ display: "flex", flexWrap: "wrap" }}>
		// 				<h1 style={{ color: "red" }}>4444jcjncncn</h1>
		// 				{ckEditor}
		// 			</div>
		// 		</body>
		// 	</html>
		// );
		// let element = (
		// 	<div style={{ display: "flex", flexWrap: "wrap" }}>
		// 		<h1 style={{ color: "red" }}>4444jcjncncn</h1>
		// 	</div>
		// );

		let element = ckEditor;
		const doc = new jsPDF();
		doc.html(element, {
			callback: function (doc) {
				doc.save("sample.pdf");
			},
		});
		// 	 {
		// 	callback: function (doc) {
		// 		doc.save("sample.pdf");
		// 	},
		// });

		// const input = document.querySelector("ck-editor__main");
		// const pdf = new jsPDF({ unit: "px", format: "letter", userUnit: "px" });
		// pdf.html(input, { html2canvas: { scale: 0.57 } }).then(() => {
		// 	pdf.save("test.pdf");
		// });

		// printDocument() {
		// const input = document.getElementById("edit");
		// const pdf = new jsPDF();
		// if (pdf) {
		// 	domtoimage.toPng(input).then((imgData) => {
		// 		pdf.addImage(imgData, "PNG", 2, 2);
		// 		pdf.save("download.pdf");
		// 	});
		// }
		// }

		// const content = ckEditor;

		// const doc = new jsPDF();
		// doc.html(content, {
		// 	callback: function (doc) {
		// 		doc.save("sample.pdf");
		// 	},
		// });
	};

	const AddTitle = () => {
		return (
			<div>
				{note.map((item, i) => (
					<div key={i}>
						{item.type == "Subtitle" ? (
							<TitleTextDiv>
								<TitleText
									autofocus
									type="text"
									defaultValue={item.text}
									onChange={(e) => {
										setChangedValue(e.target.value);
									}}
									onKeyDown={(e) =>
										handleChangedValue(e, item.id)
									}
								/>
							</TitleTextDiv>
						) : item.type == "Paragraph" ? (
							<ParagraphTextDiv>
								<ParagraphText
									autofocus
									type="text"
									defaultValue={item.text}
									onChange={(e) => {
										setChangedValue(e.target.value);
									}}
									onKeyDown={(e) =>
										handleChangedValue(e, item.id)
									}
								/>
							</ParagraphTextDiv>
						) : null}
					</div>
				))}
			</div>
		);
	};

	// const MyDocument = () => (
	// 	<Document>
	// 		<Page size="A4" style={styles.page}>
	// 			<View style={styles.section}>
	// 				<Text style={styles.text}>RADIOLOGY</Text>
	// 				<Text style={styles.text}>REPORTING</Text>
	// 				<Text style={styles.text}>REDEFINED</Text>
	// 			</View>
	// 			<View>
	// 				<Text style={styles.text}>{userInfo.information}</Text>
	// 			</View>
	// 		</Page>
	// 	</Document>
	// );

	return (
		<>
			<AlertBox propsObject={propsObject} />
			<MainContainer>
				<PrimaryLeftContainer
					report_Id={report_Id}
					patientPersonalData={patientPersonalData}
					activeError={activeError}
				/>

				{patientDetailsModal ? (
					<ReportContainer></ReportContainer>
				) : (
					<ReportContainer>
						<InnerContainer>
							<ReportHead>
								<Left>
									<LogoImage
										src={
											require("../../../assets/images/logo.svg")
												.default
										}
									/>
									<MainText>
										RADIOLOGY
										<br />
										REPORTING
										<br />
										REDEFINED
									</MainText>
								</Left>
								<Right>
									<LeftDetails>
										<List>
											<LeftSection>Patient:</LeftSection>
											<RightSection>
												{
													patientPersonalData
														?.patient_details?.name
												}
											</RightSection>
										</List>
										<List>
											<LeftSection>
												Report ID:
											</LeftSection>
											<RightSection>
												{patientPersonalData?.study_id}
											</RightSection>
										</List>
										<List>
											<LeftSection>Age:</LeftSection>
											<RightSection>
												{
													patientPersonalData
														?.patient_details?.age
												}
											</RightSection>
										</List>
										<List>
											<LeftSection>Date:</LeftSection>
											<RightSection>
												{/* {patientPersonalData?.date} */}
												{moment(
													patientPersonalData?.date
												).format("YYYY-MM-DD")}
											</RightSection>
										</List>
										{/* <List>
										<LeftSection>Disease:</LeftSection>
										<RightSection>
											Breast Cancer
										</RightSection>
									</List>
									<List>
										<LeftSection>Exam:</LeftSection>
										<RightSection>MRI Brain</RightSection>
									</List> */}
									</LeftDetails>
								</Right>
							</ReportHead>
							<ReportContent>
								<ReportInnerContainer id="toPdf">
									<SimpleDraftjs setPDFText={setPDFText} />
									{/* <CKEditorClassic
										handleChange={(data) => {
											setCkEditor(data);
										}}
										data={ckEditor}
										{...props}
									/> */}
								</ReportInnerContainer>
							</ReportContent>
							{/* <ReportContent>
							<ReportInnerContainer>
								<EditorToolbar toolbarId={"t1"} />
								
								<ReactQuill
									theme="snow"
									// value={value}
									// onChange={setValue}
									modules={modules("t1")}
									formats={formats}
									value={value}
									onChange={setValue}
									ref={quill}
									// readOnly={true}
								/>
							</ReportInnerContainer>
						</ReportContent> */}
							{/* <ReportContent>
								<ReportInnerContainer>
									<TopBar>
										<ContentAlignControl>
											<ControlIcon>
												<TitleDiv
													onClick={() => {
														toggleActiveOption();
													}}
												>
													<Subtitle>
														{selectedField}
													</Subtitle>
													<Icon
														src={
															require("../../../assets/icons/arrow-down.svg")
																.default
														}
													/>
												</TitleDiv>
												{isFieldActive && (
													<FieldOption>
														{optionsItem.map(
															(data) => (
																<Option
																	onClick={() => {
																		toggleActiveOption();
																		setSelectedField(
																			data.title
																		);
																	}}
																>
																	{data.title}
																</Option>
															)
														)}
													</FieldOption>
												)}
											</ControlIcon>
											<ControlIcon>
												<div>
													<Icon
														src={
															require("../../../assets/icons/bold.svg")
																.default
														}
													/>
												</div>
											</ControlIcon>
											<ControlIcon>
												<div>
													<Icon
														src={
															require("../../../assets/icons/italic.svg")
																.default
														}
													/>
												</div>
											</ControlIcon>
											<ControlIcon>
												<div>
													<Icon
														src={
															require("../../../assets/icons/under-line.svg")
																.default
														}
													/>
												</div>
											</ControlIcon>
											<ControlIcon>
												<div>
													<Icon
														opacity={
															fieldAlignment ===
															"left"
																? "1"
																: "flex-start"
														}
														onClick={() => {
															setFieldAlignment(
																"left"
															);
														}}
														src={
															require("../../../assets/icons/left-align.svg")
																.default
														}
													/>
												</div>
											</ControlIcon>
											<ControlIcon>
												<div>
													<Icon
														opacity={
															fieldAlignment ===
															"center"
																? "1"
																: "flex-start"
														}
														onClick={() => {
															setFieldAlignment(
																"center"
															);
														}}
														src={
															require("../../../assets/icons/center-align.svg")
																.default
														}
													/>
												</div>
											</ControlIcon>
											<ControlIcon>
												<div>
													<Icon
														opacity={
															fieldAlignment ===
															"right"
																? "1"
																: "flex-start"
														}
														onClick={() => {
															setFieldAlignment(
																"right"
															);
														}}
														src={
															require("../../../assets/icons/right-align.svg")
																.default
														}
													/>
												</div>
											</ControlIcon>
											<ControlIcon>
												<div>
													<Icon
														src={
															require("../../../assets/icons/cover-align.svg")
																.default
														}
													/>
												</div>
											</ControlIcon>
											<ControlIcon>
												<div>
													<Icon
														src={
															require("../../../assets/icons/Image.svg")
																.default
														}
													/>
												</div>
											</ControlIcon>
											<ControlIcon>
												<div>
													<Icon
														src={
															require("../../../assets/icons/Hyperlink.svg")
																.default
														}
													/>
												</div>
											</ControlIcon>
										</ContentAlignControl>
									</TopBar>
									<EditorField
										align={
											fieldAlignment === "left"
												? "flex-start"
												: fieldAlignment === "center"
												? "center"
												: fieldAlignment === "right"
												? "flex-end"
												: "flex-start"
										}
									>
										<AddTitle />
										<TextInput
											onKeyDown={(e) =>
												handleSubmit(
													e,
													note,
													setNote,
													inputValue,
													setInputValue,
													selectedField
												)
											}
											onChange={(e) =>
												setInputValue(e.target.value)
											}
											value={inputValue}
											placeholder={`${selectedField}...`}
											type="text"
										/>
									</EditorField>
								</ReportInnerContainer>
							</ReportContent> */}
							<ReportBottom>
								<BottomLeft>
									<SignedText>
										Electronically signed by
									</SignedText>

									<DoctorName>
										{
											patientPersonalData?.doctor_details
												?.name
										}
									</DoctorName>
								</BottomLeft>
								<PdfSave>
									{/* <ButtonDiv
										onClick={() => {
											// submitTextEditor();
											// download();
											exportPDF();
										}}
									>
										<SubmitButton>Save</SubmitButton>
									</ButtonDiv> */}
									<DownloadButton
										onClick={() => {
											downloadPDF();
										}}
									>
										<DownloadImage
											src={
												require("../../../assets/icons/Download.svg")
													.default
											}
										/>
										Download now!
										{/* <PDFDownloadLink
									document={<MyDocument />}
									fileName="mypdf.pdf"
									style={{ color: "#A3A3A3" }}
								>
									{({ blob, url, loading, error }) =>
										loading
											? "Loading document..."
											: "Download now!"
									}
								</PDFDownloadLink> */}
									</DownloadButton>
								</PdfSave>
							</ReportBottom>
							<div></div>
						</InnerContainer>
					</ReportContainer>
				)}
				<RightReferenceContainer>
					<MenuContainer>
						<MenuDiv
							onClick={() => {
								toggleDrawer();
							}}
						>
							<MenuBar
								src={
									drawerModal
										? require("../../../assets/icons/cancel.svg")
												.default
										: require("../../../assets/icons/menu-bar.svg")
												.default
								}
								alt="menu"
							/>
						</MenuDiv>
					</MenuContainer>
					{drawerModal && (
						<LateralityOption>
							<OptionDrawer
								onClick={() => {
									history.push("/patient-report/");
								}}
							>
								Patient History
							</OptionDrawer>
							<OptionDrawer
								onClick={(e) => {
									e.preventDefault();
									localStorage.clear();
									window.location = "/";
								}}
							>
								Log Out
							</OptionDrawer>
						</LateralityOption>
					)}

					<ReferanceInnerContainer>
						<Head>REFERENCES</Head>
					</ReferanceInnerContainer>
				</RightReferenceContainer>
				{patientDetailsModal && (
					<PatientDetails
						patientDetailsModal={patientDetailsModal}
						setPatientDetailsModal={setPatientDetailsModal}
					/>
				)}
			</MainContainer>
		</>
	);
}

const MainContainer = styled.section`
	display: flex;
	background-color: #171617;
	height: 100vh;
	position: relative;
	z-index: 0;
	width: 100%;
`;
const ReportContainer = styled.div`
	width: 58%;
	background: #171617;
	margin-left: 25%;
`;
const InnerContainer = styled.div`
	background: #222222;
	// height: 100vh;
	border-radius: 8px;
	margin: 10px;
`;
const ReportHead = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 65px 20px 50px;
`;
const Left = styled.div`
	display: flex;
	align-items: center;
	// width: 30%;
`;
const LogoImage = styled.img`
	height: 90px;
	width: 90px;
	margin-right: 15px;
`;
const MainText = styled.h3`
	color: #fff;
	font-size: 17px;
	line-height: 24px;
	font-family: "ramblaregular";
	@media (max-width: 1280px) {
		font-size: 16px;
	}
`;
const Right = styled.div`
	width: 55%;
`;

const LeftDetails = styled.ul`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
`;
const List = styled.li`
	width: 48%;
	display: flex;
	/* margin-bottom: 10px; */
	:nth-last-child(-n + 2) {
		margin-bottom: 0px;
	}
	:nth-last-child(odd) {
		margin-left: 5px;
	}
`;
const LeftSection = styled.h3`
	font-size: 14px;
	font-family: "poppinsregular";
	color: #8d8d8d;
	width: 40%;
	margin-right: 5px;
	@media (max-width: 1280px) {
		font-size: 13px;
	}
`;
const RightSection = styled.h4`
	font-size: 14px;
	color: #fff;
	font-family: "poppinsregular";
	width: 47%;
	text-align: left;
	text-transform: capitalize;
	@media (max-width: 1280px) {
		font-size: 13px;
	}
`;

const ReportContent = styled.div`
	background: #222222;
	padding: 10px 30px;
	height: calc(100vh - 230px);
`;
const ReportInnerContainer = styled.div`
	border-radius: 8px;
	background: #171617;
	/* min-height: calc(100vh - 170px); */
`;
const TopBar = styled.div`
	display: flex;
	width: 100%;
	justify-content: right;
	margin-bottom: 20px;
	// background: #fff;
`;
const ContentAlignControl = styled.ul`
	display: flex;
	align-items: center;
	height: 40px;
	border: 1px solid grey;
	border-radius: 5px;
	margin-top: 20px;
	margin-right: 20px;
	position: relative;
`;
const ControlIcon = styled.li`
	margin-right: 20px;
	&:first-child {
		margin-left: 20px;
	}
`;
const TitleDiv = styled.div`
	// color: #c4c4c4;
	display: flex;
	align-items: center;
	cursor: pointer;
`;
const Subtitle = styled.h4`
	color: #c4c4c4;
	margin-right: 7px;
	font-family: "poppinsregular";
	font-size: 16px;
`;
const FieldOption = styled.div`
	background-color: #333333;
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: absolute;
	top: 40px;
	left: 0;
`;
const Option = styled.h4`
	color: #fff;
	font-size: 15px;
	font-family: "poppinsregular";
	height: 50px;
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 0 15px;
	:hover {
		background-color: #424242;
	}
	:first-child {
		border-top-left-radius: 12px;
		border-top-right-radius: 12px;
	}
	:last-child {
		border-bottom-left-radius: 12px;
		border-bottom-right-radius: 12px;
	}
	@media (max-width: 1280px) {
		font-size: 14px;
	}
`;

const Icon = styled.img`
	opacity: 0.5;

	opacity: ${(props) => (props.opacity ? props.opacity : ".5")};
	cursor: pointer;
	&:hover {
		opacity: 1;
	}
`;
const EditorField = styled.div`
	overflow-y: scroll;
	height: calc(100vh - 330px);
	display: flex;
	flex-direction: column;
	::-webkit-scrollbar {
		width: 10px;
		background: #171617;
		border: 0;
	}
	/* align-items: flex-start; */

	align-items: ${(props) => (props.align ? props.align : "flex-start")};
	/* justify-content:  */
`;
const TextInput = styled.input`
	margin: 10px 0;
	padding: 0 30px;
	color: #fff;
	// background-color: red;
	width: 100%;
	line-height: 1.5em;
`;
const Buttons = styled.div`
	padding-left: 30px;
	margin-top: 20px;
`;
const ButtonSubtitle = styled(Link)`
	margin-right: 20px;
	padding: 10px;
	border: 1px solid grey;
	border-radius: 5px;
	color: grey;
	font-size: 15px;
	@media (max-width: 1280px) {
		font-size: 14px;
	}
`;
const ButtonParagraph = styled(ButtonSubtitle)``;

const RightReferenceContainer = styled.div`
	width: 25%;
	padding: 0 10px 10px;
	background: #222222;
	// overflow-y: scroll;
	// position: fixed;
	position: relative;
`;
const MenuContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	height: 70px;
	align-items: center;
`;
const MenuDiv = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background: #171617;
	border-radius: 50%;
	height: 40px;
	width: 40px;
	cursor: pointer;
`;
const MenuBar = styled.img`
	height: 15px;
	width: 15px;
`;
const EditingDiv = styled.div`
	height: calc(100vh - 210px) !important;
`;
const ReportBottom = styled.div`
	background: #222222;
	padding: 0px 60px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 80px;
`;
const BottomLeft = styled.div`
	display: flex;
	align-items: center;
`;
const SignedText = styled.h5`
	font-family: "poppinsregular";
	font-style: italic;
	margin-right: 10px;
	color: #a3a3a3;
`;
const DoctorName = styled.h4`
	font-family: "poppinsregular";
	color: #f44b4b;
	font-size: 15px;
`;
const DownloadButton = styled.div`
	display: flex;
	align-items: center;
	border: 1px solid grey;
	padding: 10px;
	border-radius: 5px;
	color: #a3a3a3;
	font-size: 15px;
	height: 40px;
	cursor: pointer;
`;
const DownloadImage = styled.img`
	height: 15px;
	margin-right: 10px;
`;

const ReferanceInnerContainer = styled.div`
	background: #171617;
	border-radius: 8px;
	padding: 10px;
	height: calc(100vh - 80px);
`;

const Image = styled.img`
	width: 32%;
	:hover {
		border: 10px solid;
		border-image-slice: 1;
		border-width: 5px;
		border-image-source: linear-gradient(to bottom, #00eed2, #00ade5);
	}
`;
const Head = styled.h4`
	background: #222222;
	text-align: center;
	padding: 12px 0;
	margin-bottom: 10px;
	border-radius: 8px;
	color: #22ade5;
	font-size: 15px;
	@media (max-width: 1280px) {
		font-size: 14px;
	}
`;
const Radiographic = styled.ul``;
const Titletext = styled.h5`
	color: #22ade5;
	font-size: 15px;
	margin-bottom: 10px;
	@media (max-width: 1280px) {
		font-size: 14px;
	}
`;
const ListItem = styled.li`
	color: #a3a3a3;
	list-style-type: disc;
	margin-left: 20px;
	line-height: 25px;
	font-size: 15px;
	:last-child {
		margin-bottom: 10px;
	}
	@media (max-width: 1280px) {
		font-size: 14px;
	}
`;
const MRI = styled(Radiographic)``;
const DifferentialDiagnosis = styled(Radiographic)``;
const TitleTextDiv = styled.div`
	padding: 0 30px;
	margin-bottom: 10px;
`;
const TitleText = styled.input`
	color: #fff;
	font-family: "poppinsregular";
	font-size: 16px;
`;
const ParagraphTextDiv = styled.div`
	padding: 0 60px;
`;
const ParagraphText = styled.input`
	color: #a3a3a3;
	font-family: "poppinsregular";
	font-size: 14px;
	margin-bottom: 5px;
	line-height: 16px;
`;
const LateralityOption = styled.div`
	// display: flex;
	// flex-wrap: wrap;
	// justify-content: space-between;
	// margin-bottom: 10px;
	padding: 8px;
	background: #0e0d0e;
	border-radius: 10px;
	position: absolute;
	width: 95%;
`;
const OptionDrawer = styled.h4`
	color: #fff;
	font-size: 15px;
	font-family: "poppinsregular";
	height: 50px;
	display: flex;
	align-items: center;
	cursor: pointer;
	border-radius: 10px;
	padding: 0 15px;
	:hover {
		background-color: #404040;
	}
	// :first-child {
	// 	border-top-left-radius: 10px;
	// 	border-top-right-radius: 10px;
	// }
	// :last-child {
	// 	border-bottom-left-radius: 10px;
	// 	border-bottom-right-radius: 10px;
	// }
	@media (max-width: 1280px) {
		font-size: 14px;
	}
`;
const PdfSave = styled.div`
	display: flex;
	align-items: center;
`;
const ButtonDiv = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-right: 10px;
	height: 40px;
`;
const SubmitButton = styled.div`
	padding: 10px 22px;
	color: #fff;
	background: #00ace2;
	border-radius: 5px;
	font-size: 16px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
`;
