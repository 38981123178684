import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
// import "./styles.css";
import { useParams } from "react-router-dom";
import { baseConfig } from "./../../../axiosConfig";
import { Context } from "./../../../contexts/Store";
import FindingModal from "./modals/FindingModal";
import FindingItem from "./FindingItem";

export default function Findings({ getSynopticReport }) {
	const [active, setActive] = useState(false);

	const toggleActiveSize = () => setActive(!active);
	const [isLoading, setLoading] = useState(true);

	const { template, report_id, synoptic_id } = useParams();
	const { state } = useContext(Context);
	const [findingsArray, setFindingArray] = useState({});
	const [isFindingModal, setFindingModal] = useState(false);
	const [selectedFindingData, setSelectedFindingData] = useState({});
	const [selectedPositions, setSelectedPositions] = useState("");
	const [selectedLesion, setSelectedLesion] = useState({});
	const [submitUrl, setSubmitUrl] = useState("");

	const getFindings = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`/mammogram/attributes/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
				params: {
					mammogram_id: synoptic_id,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setFindingArray(data);
					setSubmitUrl(data.submit_url);
					setLoading(false);
				} else {
					setLoading(false);
				}
			})
			.catch((error) => {});
	};

	useEffect(() => {
		getFindings();
	}, []);

	//props
	const propsObject = {
		setFindingModal: setFindingModal,
		setSelectedFindingData: setSelectedFindingData,
		setSelectedPositions: setSelectedPositions,
		setSelectedLesion: setSelectedLesion,
		getSynopticReport: getSynopticReport,
		getFindings: getFindings,
	};

	//position
	const [coords, setCoords] = useState({ x: 0, y: 0, balanceY: 0 });

	const handleMouseMove = (event) => {
		setCoords({
			x: event.clientX,
			y: event.clientY,
			balanceY: event.view.innerHeight - event.clientY,
			clicked: event.clientY,
		});
	};

	//deleteLesion
	// const deleteLesion = (id) => {
	// 	let accessToken = state.user_details.access_token;
	// 	baseConfig
	// 		.get("/mammogram/delete-findings/" + id, {
	// 			headers: {
	// 				Authorization: "Bearer " + accessToken,
	// 			},
	// 		})
	// 		.then((response) => {
	// 			const { StatusCode, reference_data } = response.data;
	// 			if (StatusCode === 6000) {
	// 				// setReferences(reference_data);
	// 				// getFindings();
	// 				// getFindingResults();
	// 			} else {
	// 			}
	// 		})
	// 		.catch((error) => {});
	// };

	return (
		<div>
			<div>
				<Heading>3. Findings</Heading>

				{!isLoading && (
					<>
						{findingsArray?.left_findings ? (
							<>
								<>
									<LateralityHeadingDiv>
										<LateralityHeading>
											Left
										</LateralityHeading>
									</LateralityHeadingDiv>
									<FindingDiv>
										{findingsArray?.left_findings?.map(
											(item, i) => (
												<MainContainer
													key={i}
													className={
														findingsArray
															?.left_findings[
															i + 1
														]?.is_sub_attribute
															? ""
															: "border-div"
													}
												>
													{item.is_sub_attribute ? (
														<ContainerDiv
															style={{
																paddingLeft:
																	"15px",
															}}
														>
															<SubHeading>
																{item.title}
															</SubHeading>
															<ChildDiv>
																{item.sub_attributes.map(
																	(
																		sub_item,
																		index
																	) => (
																		<MainContainer
																			key={
																				index
																			}
																		>
																			<FindingItem
																				propsObject={
																					propsObject
																				}
																				item={
																					sub_item
																				}
																				position="left"
																				handleMouseMove={
																					handleMouseMove
																				}
																			/>
																		</MainContainer>
																	)
																)}
															</ChildDiv>
														</ContainerDiv>
													) : (
														<FindingItem
															propsObject={
																propsObject
															}
															item={item}
															position="left"
															handleMouseMove={
																handleMouseMove
															}
														/>
													)}
													{/* <TopDiv
											onClick={() => {
												setFindingModal(true);
												setSelectedFindingData(item);
												setSelectedPositions("left");
											}}
										>
											<Select>
												<SelectContainer>
													<CheckboxChecked>
														{item.patient_findings && (
															<InnerSquare />
														)}
													</CheckboxChecked>
													<OptionText>
														{item.title.substring(
															0,
															item.title.indexOf(
																"!!"
															)
														)}
														<Highlight>
															{item.title.substring(
																item.title.indexOf(
																	"!!"
																)
															)}
														</Highlight>
													</OptionText>
												</SelectContainer>
											</Select>
											<AddDiv>
												<AddImage
													src={
														require("./../../../assets/icons/add-icon.svg")
															.default
													}
													alt="icon"
												/>
											</AddDiv>
										</TopDiv>
										{item?.patient_findings?.length > 0 && (
											<>
												{item?.patient_findings?.map(
													(data, index) => (
														<BottomDiv key={index}>
															<Highlight
																style={{
																	width: "70px",
																}}
															>
																Lesion{" "}
																{index + 1}
															</Highlight>
															<LessDiv
																onClick={() => {
																	// deleteLesion(data.id);
																}}
															>
																<LessImage
																	src={
																		require("./../../../assets/icons/hide-icon.svg")
																			.default
																	}
																/>
															</LessDiv>
															<LessDiv
																style={{
																	marginLeft:
																		"15px",
																}}
																onClick={() => {
																	// setSelectedEditModal(item);
																	// setEditModalData(data);
																	// setSelectedFindingsID(item.id);
																	// setSelectedLesionDetails(data);
																	// setSelectedModal(item.name);
																	// setFindingData(item);
																}}
															>
																<LessImage
																	src={
																		require("./../../../assets/icons/edit.svg")
																			.default
																	}
																/>
															</LessDiv>
														</BottomDiv>
													)
												)}
											</>
										)} */}
												</MainContainer>
											)
										)}
									</FindingDiv>
								</>

								<>
									<LateralityHeadingDiv>
										<LateralityHeading>
											Right
										</LateralityHeading>
									</LateralityHeadingDiv>
									<FindingDiv>
										{findingsArray?.findings?.map(
											(item, i) => (
												<MainContainer
													key={i}
													// className="border-div"
													className={
														findingsArray?.findings[
															i + 1
														]?.is_sub_attribute
															? ""
															: "border-div"
													}
												>
													{item.is_sub_attribute ? (
														<ContainerDiv
															style={{
																paddingLeft:
																	"15px",
															}}
														>
															<SubHeading>
																{item.title}
															</SubHeading>
															<ChildDiv>
																{item.sub_attributes.map(
																	(
																		sub_item,
																		index
																	) => (
																		<MainContainer
																			key={
																				index
																			}
																		>
																			<FindingItem
																				propsObject={
																					propsObject
																				}
																				item={
																					sub_item
																				}
																				position="right"
																				handleMouseMove={
																					handleMouseMove
																				}
																			/>
																		</MainContainer>
																	)
																)}
															</ChildDiv>
														</ContainerDiv>
													) : (
														<FindingItem
															propsObject={
																propsObject
															}
															item={item}
															position="right"
															handleMouseMove={
																handleMouseMove
															}
														/>
													)}
													{/* <TopDiv
											onClick={() => {
												setFindingModal(true);
												setSelectedFindingData(item);
												setSelectedPositions("right");
											}}
										>
											<Select>
												<SelectContainer>
													<CheckboxChecked>
														{item.patient_findings && (
															<InnerSquare />
														)}
													</CheckboxChecked>
													<OptionText>
														{item.title.substring(
															0,
															item.title.indexOf(
																"!!"
															)
														)}
														<Highlight>
															{item.title.substring(
																item.title.indexOf(
																	"!!"
																)
															)}
														</Highlight>
													</OptionText>
												</SelectContainer>
											</Select>
											<AddDiv>
												<AddImage
													src={
														require("./../../../assets/icons/add-icon.svg")
															.default
													}
													alt="icon"
												/>
											</AddDiv>
										</TopDiv>
										{item?.patient_findings?.length > 0 && (
											<>
												{item?.patient_findings?.map(
													(data, index) => (
														<BottomDiv key={index}>
															<Highlight
																style={{
																	width: "70px",
																}}
															>
																Lesion{" "}
																{index + 1}
															</Highlight>
															<LessDiv
																onClick={() => {
																	// deleteLesion(data.id);
																}}
															>
																<LessImage
																	src={
																		require("./../../../assets/icons/hide-icon.svg")
																			.default
																	}
																/>
															</LessDiv>
															<LessDiv
																style={{
																	marginLeft:
																		"15px",
																}}
																onClick={() => {
																	setFindingModal(
																		true
																	);
																	// setSelectedEditModal(item);
																	// setEditModalData(data);
																	// setSelectedFindingsID(item.id);
																	// setSelectedLesionDetails(data);
																	// setSelectedModal(item.name);
																	// setFindingData(item);
																}}
															>
																<LessImage
																	src={
																		require("./../../../assets/icons/edit.svg")
																			.default
																	}
																/>
															</LessDiv>
														</BottomDiv>
													)
												)}
											</>
										)} */}
												</MainContainer>
											)
										)}
									</FindingDiv>
								</>
							</>
						) : (
							<>
								{findingsArray?.findings?.map((item, i) => (
									<MainContainer
										key={i}
										// className="border-div"
										className={
											findingsArray?.findings[i + 1]
												?.is_sub_attribute
												? ""
												: "border-div"
										}
									>
										{item.is_sub_attribute ? (
											<ContainerDiv
												style={{
													paddingLeft: "15px",
												}}
											>
												<SubHeading>
													{item.title}
												</SubHeading>
												<ChildDiv>
													{item.sub_attributes.map(
														(sub_item, index) => (
															<MainContainer
																key={index}
															>
																<FindingItem
																	propsObject={
																		propsObject
																	}
																	item={
																		sub_item
																	}
																	position="right"
																	handleMouseMove={
																		handleMouseMove
																	}
																/>
															</MainContainer>
														)
													)}
												</ChildDiv>
											</ContainerDiv>
										) : (
											<FindingItem
												propsObject={propsObject}
												item={item}
												position="right"
												handleMouseMove={
													handleMouseMove
												}
											/>
										)}
										{/* <>
										{item.is_sub_attribute ? (
											<ContainerDiv style={{
															paddingLeft: "15px",
														}} >
												<SubHeading>
													{item.title}
												</SubHeading>
												<ChildDiv>
													{item.sub_attributes.map(
														(sub_item) => (
															<TopDiv
																onClick={(
																	e
																) => {
																	setFindingModal(
																		true
																	);
																	setSelectedFindingData(
																		sub_item
																	);
																	setSelectedPositions(
																		"right"
																	);
																	handleMouseMove(
																		e
																	);
																}}
															>
																<Select>
																	<SelectContainer>
																		<CheckboxChecked>
																			{sub_item.patient_findings && (
																				<InnerSquare />
																			)}
																		</CheckboxChecked>
																		<OptionText>
																			{sub_item.title.substring(
																				0,
																				sub_item.title.indexOf(
																					"!!"
																				)
																			)}
																			<Highlight>
																				{sub_item.title.substring(
																					sub_item.title.indexOf(
																						"!!"
																					)
																				)}
																			</Highlight>
																		</OptionText>
																	</SelectContainer>
																</Select>
																<AddDiv>
																	<AddImage
																		src={
																			require("./../../../assets/icons/add-icon.svg")
																				.default
																		}
																		alt="icon"
																	/>
																</AddDiv>
															</TopDiv>
														)
													)}
												</ChildDiv>
											</ContainerDiv>
										) : (
											<>
												<TopDiv
													onClick={(e) => {
														setFindingModal(true);
														setSelectedFindingData(
															item
														);
														setSelectedPositions(
															"right"
														);
														handleMouseMove(e);
													}}
												>
													<Select>
														<SelectContainer>
															<CheckboxChecked>
																{item.patient_findings && (
																	<InnerSquare />
																)}
															</CheckboxChecked>
															<OptionText>
																{item.title.substring(
																	0,
																	item.title.indexOf(
																		"!!"
																	)
																)}
																<Highlight>
																	{item.title.substring(
																		item.title.indexOf(
																			"!!"
																		)
																	)}
																</Highlight>
															</OptionText>
														</SelectContainer>
													</Select>
													<AddDiv>
														<AddImage
															src={
																require("./../../../assets/icons/add-icon.svg")
																	.default
															}
															alt="icon"
														/>
													</AddDiv>
												</TopDiv>
											</>
										)}
										{item?.patient_findings?.length > 0 && (
											<>
												{item?.patient_findings?.map(
													(data, index) => (
														<BottomDiv key={index}>
															<Highlight
																style={{
																	width: "70px",
																}}
															>
																Lesion{" "}
																{index + 1}
															</Highlight>
															<LessDiv
																onClick={() => {
																	// deleteLesion(data.id);
																}}
															>
																<LessImage
																	src={
																		require("./../../../assets/icons/hide-icon.svg")
																			.default
																	}
																/>
															</LessDiv>
															<LessDiv
																style={{
																	marginLeft:
																		"15px",
																}}
																onClick={() => {
																	setFindingModal(
																		true
																	);
																	setSelectedFindingData(
																		item
																	);

																	setSelectedLesion(
																		data
																	);
																}}
															>
																<LessImage
																	src={
																		require("./../../../assets/icons/edit.svg")
																			.default
																	}
																/>
															</LessDiv>
														</BottomDiv>
													)
												)}
											</>
										)}
									</> */}
									</MainContainer>
								))}
							</>
						)}
					</>
				)}
			</div>
			{isFindingModal && (
				<FindingModal
					setFindingModal={setFindingModal}
					selectedFindingData={selectedFindingData}
					setSelectedFindingData={setSelectedFindingData}
					selectedPositions={selectedPositions}
					getFindings={getFindings}
					selectedLesion={selectedLesion}
					setSelectedLesion={setSelectedLesion}
					getSynopticReport={getSynopticReport}
					position={coords}
					setPosition={setCoords}
					isFindingModal={isFindingModal}
					submitUrl={submitUrl}
				/>
			)}
		</div>
	);
}
const MainContainer = styled.div`
	margin-bottom: 8px;
	:last-child {
		margin-bottom: 0;
	}
	&.border-div {
		border-bottom: 2px solid #222;
	}
`;
const Heading = styled.h5`
	color: #22ade5;
	font-size: 15px;
	margin-bottom: 10px;
`;
const LateralityHeadingDiv = styled.div`
	margin-bottom: 8px;
	height: 30px;
	background: rgba(255, 255, 255, 0.05);
	border-radius: 6px;
	padding: 0 16px;
	display: flex;
	align-items: center;
	margin-top: 12px;
`;
const LateralityHeading = styled.h4`
	color: #fff;
	font-size: 15px;
	text-transform: uppercase;
	margin-bottom: -3px;
`;
const FindingDiv = styled.div`
	padding-left: 15px;
`;
const TopDiv = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 8px;
`;
const Select = styled.div`
	// margin-bottom: 10px;
	// cursor: pointer;
	// :last-child {
	// 	margin-bottom: 0;
	// }
`;
const CheckboxChecked = styled.div`
	border-radius: 20%;
	background-color: #203035;
	width: 16px;
	height: 16px;
	min-width: 16px;
	min-height: 16px;
	border-radius: 20%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
	margin-top: 3px;
`;
const SelectContainer = styled.div`
	display: flex;
	align-items: start;
	margin-bottom: 10px;
	cursor: pointer;
	/* align-items: center; */
	:last-child {
		margin-bottom: 0;
	}
`;
const OptionText = styled.h6`
	color: #a3a3a3;
	font-size: 15px;
	// margin-bottom: 10px;
	color: ${(props) => (props.color ? props.color : "#a3a3a3")};
`;
const InnerSquare = styled.div`
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: #00ace2;
	border-radius: 20%;
`;
const Highlight = styled.small`
	color: #f44b4b;
	font-size: 15px;
`;
const AddDiv = styled.div`
	margin-left: 10px;
	cursor: pointer;

	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	&:hover {
		background-color: #333;
	}
`;
const AddImage = styled.img``;
const BottomDiv = styled.div`
	margin-left: 30px;
	display: flex;
	align-items: center;
	margin-top: 3px;
	margin-bottom: px;
`;
const LessDiv = styled.div`
	margin-left: 10px;
	cursor: pointer;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	&:hover {
		background-color: #333;
	}
`;
const LessImage = styled.img``;
const ContainerDiv = styled.div``;
const SubHeading = styled.h4`
	color: #fff;
	font-size: 16px;
`;
const ChildDiv = styled.div`
	/* padding-left: 15px; */
`;
