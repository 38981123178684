const Reducer = (state, action) => {
	switch (action.type) {
		case "UPDATE_USER":
			const user_details = {
				...state.user_details,
				...action.user_details,
			};
			localStorage.setItem("user_details", JSON.stringify(user_details));
			return {
				...state,
				user_details: user_details,
			};
		default:
			return state;
	}
};

export default Reducer;
