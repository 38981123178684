import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
// import "./styles.css";
import { useParams } from "react-router-dom";
import { baseConfig } from "./../../../axiosConfig";
import { Context } from "./../../../contexts/Store";

export default function FindingItem({
	propsObject,
	item,
	position,
	handleMouseMove,
}) {
	const { state } = useContext(Context);

	const deleteLesion = (id) => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.get("/mammogram/delete-findings/" + id, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, reference_data } = response.data;
				if (StatusCode === 6000) {
					// setReferences(reference_data);
					// getFindings();
					// getFindingResults();
					propsObject.getSynopticReport();
					propsObject.getFindings();
				} else {
				}
			})
			.catch((error) => {});
	};

	return (
		<div>
			{/* {findingsArray.left_findings.map((item, i) => ( */}
			{/* <MainContainer> */}
			<TopDiv
				onClick={(e) => {
					if (item.multiple_lesion) {
						setTimeout(() => {
							propsObject.setFindingModal(true);
						}, 200);
						propsObject.setSelectedFindingData(item);
						propsObject.setSelectedPositions(position);
						handleMouseMove(e);
					} else {
						if (!item?.patient_findings) {
							setTimeout(() => {
								propsObject.setFindingModal(true);
							}, 200);
							propsObject.setSelectedFindingData(item);
							propsObject.setSelectedPositions(position);
							handleMouseMove(e);
						}
					}
				}}
			>
				<Select>
					<SelectContainer>
						<CheckboxChecked>
							{item.patient_findings && <InnerSquare />}
						</CheckboxChecked>
						<OptionText>
							{item.title.substring(0, item.title.indexOf("!!"))}
							<Highlight>
								{item.title.substring(item.title.indexOf("!!"))}
							</Highlight>
						</OptionText>
					</SelectContainer>
				</Select>
				{item?.patient_findings?.length > 0 && item.multiple_lesion && (
					<AddDiv>
						<AddImage
							src={
								require("./../../../assets/icons/add-new.svg")
									.default
							}
							alt="icon"
						/>
					</AddDiv>
				)}
			</TopDiv>
			{item?.patient_findings?.length > 0 && (
				<>
					{item?.patient_findings?.map((data, index) => (
						<BottomDiv key={index}>
							<Highlight
								style={{
									width: "70px",
								}}
							>
								Lesion {index + 1}
							</Highlight>
							<LessDiv
								onClick={() => {
									deleteLesion(data.id);
								}}
							>
								<LessImage
									src={
										require("./../../../assets/icons/remove-new.svg")
											.default
									}
								/>
							</LessDiv>
							<LessDiv
								style={{
									marginLeft: "15px",
								}}
								onClick={() => {
									propsObject.setFindingModal(true);
									propsObject.setSelectedFindingData(item);
									propsObject.setSelectedLesion(data);
								}}
							>
								<LessImage
									src={
										require("./../../../assets/icons/edit-new.svg")
											.default
									}
								/>
							</LessDiv>
						</BottomDiv>
					))}
				</>
			)}
			{/* </MainContainer> */}
			{/* ))} */}
		</div>
	);
}
const MainContainer = styled.div`
	margin-bottom: 10px;
	:last-child {
		margin-bottom: 0;
	}
`;
const Heading = styled.h5`
	color: #22ade5;
	font-size: 15px;
	margin-bottom: 10px;
`;
const LateralityHeading = styled.h4`
	color: #fff;
	font-size: 15px;
	margin-bottom: 5px;
`;
const TopDiv = styled.div`
	display: flex;
	align-items: center;
	/* margin-bottom: 8px; */
`;
const Select = styled.div`
	// margin-bottom: 10px;
	// cursor: pointer;
	// :last-child {
	// 	margin-bottom: 0;
	// }
`;
const CheckboxChecked = styled.div`
	border-radius: 20%;
	background-color: #203035;
	width: 16px;
	height: 16px;
	min-width: 16px;
	min-height: 16px;
	border-radius: 20%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
	margin-top: 3px;
`;
const SelectContainer = styled.div`
	display: flex;
	align-items: start;
	margin-bottom: 10px;
	cursor: pointer;
	/* align-items: center; */
	:last-child {
		margin-bottom: 0;
	}
`;
const OptionText = styled.h6`
	color: #a3a3a3;
	font-size: 15px;
	// margin-bottom: 10px;
	color: ${(props) => (props.color ? props.color : "#a3a3a3")};
`;
const InnerSquare = styled.div`
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: #00ace2;
	border-radius: 20%;
`;
const Highlight = styled.small`
	color: #f44b4b;
	font-size: 15px;
`;
const AddDiv = styled.div`
	margin-left: 10px;
	cursor: pointer;

	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	&:hover {
		background-color: #333;
	}
`;
const AddImage = styled.img``;
const BottomDiv = styled.div`
	margin-left: 30px;
	display: flex;
	align-items: center;
	/* margin-top: 3px; */
	margin-bottom: 5px;
	:last-child {
		margin-bottom: 0px;
	}
`;
const LessDiv = styled.div`
	margin-left: 10px;
	cursor: pointer;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	&:hover {
		background-color: #333;
	}
`;
const LessImage = styled.img``;
const ContainerDiv = styled.div``;
const SubHeading = styled.h4`
	color: #fff;
`;
const ChildDiv = styled.div`
	padding-left: 15px;
`;
