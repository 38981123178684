import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";

export function ReportBody() {
	return (
		<MainContainer>
			<>hh</>
		</MainContainer>
	);
}

export const RenderLateralityView = ({ data }) => {
	return (
		<>
			<TitleTextDiv>
				<FormTitleText>VIEWS</FormTitleText>
			</TitleTextDiv>

			<ParagraphTextDiv>
				<ParagraphText>{data?.laterality_text}</ParagraphText>
				<ParagraphText>{data?.laterality_text_view}</ParagraphText>
			</ParagraphTextDiv>
		</>
	);
};
export const RenderIndication = ({ data }) => {
	return (
		data.indication_for_examination && (
			<>
				<TitleTextDiv>
					<FormTitleText>INDICATION FOR EXAMINATION</FormTitleText>
				</TitleTextDiv>

				<ParagraphTextDiv>
					<ParagraphText>
						{data?.indication_for_examination}
					</ParagraphText>
				</ParagraphTextDiv>
			</>
		)
	);
};
export const RenderComposition = ({ data }) => {
	return (
		data.composition && (
			<>
				<TitleTextDiv>
					<FormTitleText>{data.composition_title}</FormTitleText>
				</TitleTextDiv>

				<ParagraphTextDiv
					style={{ display: "flex", alignItems: "baseline" }}
				>
					{data.composition?.category && (
						<FormTitleText>
							{data?.composition?.category} :
						</FormTitleText>
					)}
					<ParagraphText>
						&nbsp;{data?.composition?.title}
					</ParagraphText>
				</ParagraphTextDiv>
			</>
		)
	);
};

export const RenderFindings = ({ data }) => {
	return (
		<div style={{ marginTop: "10px" }}>
			{/* {val && (
                <TitleTextDiv>
                    <FormTitleText
                        style={{
                            fontWeight: 600,
                            textDecoration: "underline",
                        }}
                    >
                        {val}
                    </FormTitleText>
                </TitleTextDiv>
            )} */}

			<TitleTextDiv>
				<FormTitleText>FINDINGS</FormTitleText>
			</TitleTextDiv>
			<ParagraphTextDiv>
				{data?.map((item, index) =>
					item?.texts?.map((item, ind) => (
						<ParagraphText key={ind}>{item}</ParagraphText>
					))
				)}
			</ParagraphTextDiv>
		</div>
	);
};

export const RenderExamComparison = ({ data }) => {
	return (
		data.exam_comparison && (
			<>
				<TitleTextDiv style={{ marginTop: "10px" }}>
					<FormTitleText>
						PREVIOUS EXAM COMPARISON / USG CORRELATION
					</FormTitleText>
				</TitleTextDiv>

				<ParagraphTextDiv>
					<ParagraphText>{data?.exam_comparison}</ParagraphText>
				</ParagraphTextDiv>
			</>
		)
	);
};

export const RenderAssessments = ({ data }) => {
	return (
		data.category && (
			<div style={{ marginTop: "10px" }}>
				{/* {val && (
                <TitleTextDiv>
                    <FormTitleText
                        style={{
                            fontWeight: 600,
                            textDecoration: "underline",
                        }}
                    >
                        {val}
                    </FormTitleText>
                </TitleTextDiv>
            )} */}

				<TitleTextDiv>
					<FormTitleText>ASSESSMENTS</FormTitleText>
				</TitleTextDiv>

				<ParagraphTextDiv
					style={{ display: "flex", alignItems: "center" }}
				>
					<ParagraphText
						style={{
							fontSize: "16px",
							color: "#fff",
							whiteSpace: "nowrap",
							// width: "18%",
						}}
					>
						{data?.category_name} -
					</ParagraphText>
					<ParagraphText>
						&nbsp;
						{data?.assessment}
					</ParagraphText>
				</ParagraphTextDiv>

				<TitleTextDiv style={{ marginTop: "10px" }}>
					<FormTitleText>MANAGEMENT</FormTitleText>
				</TitleTextDiv>

				<ParagraphTextDiv>
					<ParagraphText>{data?.management}</ParagraphText>
				</ParagraphTextDiv>

				<TitleTextDiv style={{ marginTop: "10px" }}>
					<FormTitleText>Likelihood of Cancer</FormTitleText>
				</TitleTextDiv>

				<ParagraphTextDiv>
					<ParagraphText>{data?.likelihood_of_cancer}</ParagraphText>
				</ParagraphTextDiv>
			</div>
		)
	);
};

const MainContainer = styled.section`
	z-index: 0;
	width: 100%;
`;

const TitleTextDiv = styled.div`
	padding: 0 30px;
	margin-bottom: 10px;
`;
const FormTitleText = styled.h5`
	color: #fff;
	font-family: "poppinsregular";
	font-size: 16px;
`;
const ParagraphTextDiv = styled.div`
	padding: 0 60px;
`;
const ParagraphText = styled.h6`
	color: #a3a3a3;
	font-family: "poppinsregular";
	font-size: 14px;
	margin-bottom: 5px;
`;
