import React, {
	useState,
	useContext,
	forwardRef,
	useRef,
	useEffect,
} from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { baseConfig } from "./../../../axiosConfig";
import { Context } from "./../../../contexts/Store";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.css";
import moment from "moment";

export default function EditPatientDetails({
	setPatientDetailsModal,
	getPatientDetails,
	editPatientData,
}) {
	const { state } = useContext(Context);
	const [value, setValue] = useState("");
	const [singlePatient, setSinglePatient] = useState({});

	const [name, setName] = useState("");
	const [phone, setPhone] = useState("");
	const [DOB, setDOB] = useState();
	const [doctorName, setDoctorName] = useState("");
	const [address, setAddress] = useState("");
	const [address2, setAddress2] = useState("");
	const [city, setCity] = useState("");
	const [states, setStates] = useState("");
	const [district, setDistrict] = useState("");
	const [pin, setPin] = useState("");
	const [studyId, setStudyId] = useState("");
	const [studyValue, setStudyValue] = useState("");
	const [studyDate, setStudyDate] = useState();

	const getSinglePatient = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`/patients/patient-details/${editPatientData?.id}/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setSinglePatient(data);
					setName(data.name);
					setPhone(data.phone);
					setDOB(data.date_of_birth);
					setDoctorName(data.doctor_name);
					setStudyId(data.study_id);
					setStudyDate(data.study_date);
					setAddress(data.address);
					setAddress2(data.address2);
					setCity(data.city);
					setStates(data.state);
					setDistrict(data.district);
					setPin(data.pin);
				} else {
				}
			})
			.catch((error) => {});
	};
	useEffect(() => {
		getSinglePatient();
	}, []);

	useEffect(() => {
		setDOB(moment(value).format("YYYY-MM-DD"));
	}, [value]);

	useEffect(() => {
		setStudyDate(moment(studyValue).format("YYYY-MM-DD"));
	}, [studyValue]);

	const editPatient = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.post(
				`/patients/edit-patient/${editPatientData.id}/`,
				{
					name: name,
					phone: phone,
					// email: "sam@gmail.com",
					date_of_birth: DOB,
					doctor_name: doctorName,
					city: city,
					district: district,
					state: states,
					pin: pin,
					address: address,
					address2: address2,
					study_date: studyDate,
					study_id: studyId,
				},
				{
					headers: {
						Authorization: "Bearer " + accessToken,
					},
				}
			)
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setPatientDetailsModal(false);
					getPatientDetails();
				} else {
				}
			})
			.catch((error) => {});
	};

	const inputRef = useRef(null);

	const CustomInput = forwardRef((props: any, ref) => {
		return (
			<Input
				style={{ width: "100%" }}
				{...props}
				ref={ref}
				placeholder={DOB}
			/>
		);
	});

	const CustomInputStudyID = forwardRef((props: any, ref) => {
		return (
			<Input
				style={{ width: "100%" }}
				{...props}
				ref={ref}
				placeholder={studyDate}
			/>
		);
	});

	return (
		<BackContainer>
			<Overlay
				onClick={() => {
					// toggleActiveOption();
				}}
			></Overlay>
			<Modal>
				<Container>
					<LogoDiv>
						<LogoImage
							src={
								require("../../../assets/images/logo.svg")
									.default
							}
						/>
					</LogoDiv>
					<DetailsForm>
						<Title>Edit Patient Details</Title>
						<Text>PATIENT DETAILS</Text>
						<div>
							<InputDiv>
								<Input
									placeholder="Patient Name"
									onChange={(e) => setName(e.target.value)}
									value={name}
								/>
								<Input
									placeholder="Phone no."
									type="number"
									onChange={(e) => setPhone(e.target.value)}
									value={phone}
								/>
								{/* <Input placeholder="Patient ID" /> */}
							</InputDiv>
							<InputDiv>
								{/* <Input
									placeholder="DOB"
									type="text"
									onFocus={(e) => {
										e.currentTarget.type = "date";
										e.currentTarget.focus();
									}}
									onChange={(e) => setDOB(e.target.value)}
								/> */}
								<DatePicker
									selected={value}
									onChange={(date) => setValue(date)}
									// customInput={<ExampleCustomInput />}
									customInput={
										<CustomInput inputRef={inputRef} />
									}
									peekNextMonth
									showMonthDropdown
									showYearDropdown
									dropdownMode="select"
									value={DOB}
									// dateFormat="yyyy-MM-dd"
								/>
								<Input
									placeholder="Doctor Name"
									onChange={(e) =>
										setDoctorName(e.target.value)
									}
									value={doctorName}
								/>
							</InputDiv>
							<InputDiv>
								<DatePicker
									selected={studyValue}
									onChange={(date) => setStudyValue(date)}
									customInput={
										<CustomInputStudyID
											inputRef={inputRef}
										/>
									}
									peekNextMonth
									showMonthDropdown
									showYearDropdown
									dropdownMode="select"
									dateFormat="dd-MM-yyyy"
								/>
								<Input
									placeholder="Study Id"
									onChange={(e) => setStudyId(e.target.value)}
									value={studyId}
								/>
							</InputDiv>
						</div>
						<Text>ADDRESS</Text>
						<div>
							<InputDiv>
								<Input
									placeholder="Address 1"
									onChange={(e) => setAddress(e.target.value)}
									value={address}
								/>
								<Input
									placeholder="Address 2"
									onChange={(e) =>
										setAddress2(e.target.value)
									}
									value={address2}
								/>
							</InputDiv>
							<InputDiv>
								<Input
									placeholder="City"
									onChange={(e) => setCity(e.target.value)}
									value={city}
								/>
								<Input
									placeholder="State"
									onChange={(e) => setStates(e.target.value)}
									value={states}
								/>
							</InputDiv>
							<InputDiv>
								<Input
									placeholder="District"
									onChange={(e) =>
										setDistrict(e.target.value)
									}
									value={district}
								/>
								<Input
									type="number"
									placeholder="PIN"
									onChange={(e) => setPin(e.target.value)}
									value={pin}
								/>
							</InputDiv>
						</div>
						<ButtonDiv>
							<SubmitButton
								onClick={() => {
									editPatient();
								}}
							>
								Submit
							</SubmitButton>
							<CancelButton
								onClick={() => {
									setPatientDetailsModal(false);
								}}
							>
								Cancel
							</CancelButton>
						</ButtonDiv>
					</DetailsForm>
				</Container>
			</Modal>
		</BackContainer>
	);
}

const BackContainer = styled.div`
	position: fixed;
	transition: 0.3s;
	width: 100%;
	height: 100vh;
	z-index: 1000;
	left: 0;
	top: 0px;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
	// backdrop-filter: blur(1px);
`;
const Overlay = styled.div`
	position: fixed;
	left: 0;
	top: 0px;
	width: 100%;
	cursor: pointer;
	height: 100vh;
`;
const Modal = styled.div`
	padding: 20px 60px;
	margin: 0 auto;
	background: #141414;
	width: 41%;
	// left: 50%;
	// top: ${(props) => (props.top ? props.top : "50%")};
	position: absolute;
	// border-radius: 12px;
	transition: 0.5s;
	z-index: 101;
	// top: 50px;
	// bottom: 0;
	// display: flex;
	overflow: scroll;
	height: 80vh;
	border-radius: 8px;
	&::-webkit-scrollbar {
		display: none;
		-ms-overflow-style: none;
		scrollbar-width: none;
	}
	scrollbar-width: none;
	display: flex;
	align-items: center;
	flex-direction: column;
	@media (max-width: 1400px) {
		width: 60%;
	}
`;
const Container = styled.div`
	width: 100%;
	// display: flex;
	// flex-direction: column;
	// justify-content: center;
	align-items: center;
`;

const LogoDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 40px;
`;
const LogoImage = styled.img`
	height: 90px;
	width: 90px;
	// margin-right: 15px;
`;
const DetailsForm = styled.div``;
const Title = styled.h3`
	color: #fff;
	margin-bottom: 40px;
	font-size: 20px;
`;
const Text = styled.h3`
	color: #909090;
	margin-bottom: 20px;
	font-size: 16px;
`;
const InputDiv = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 15px;
	&:last-child {
		margin-bottom: 40px;
	}
	// display: grid;
	// grid-template-columns: 1fr 1fr;
	// grid-gap: 30px;
`;
const Input = styled.input`
	height: 50px;
	background: #222222;
	width: 49%;
	border-radius: 5px;
	font-size: 16px;
	padding: 10px;
	color: #909090;
	border: 1px solid #222222;
	line-height: 60px;
	text-transform: capitalize;
	&:focus {
		border: 1px solid #4e4e4e;
	}
`;
const Option = styled.h4`
	color: #fff;
	font-size: 15px;
	font-family: "poppinsregular";
	height: 50px;
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 0 15px;
	:hover {
		background-color: #424242;
	}
	:first-child {
		border-top-left-radius: 12px;
		border-top-right-radius: 12px;
	}
	:last-child {
		border-bottom-left-radius: 12px;
		border-bottom-right-radius: 12px;
	}
	@media (max-width: 1280px) {
		font-size: 14px;
	}
`;
const ButtonDiv = styled.div`
	color: #fff;
	display: flex;
`;
const SubmitButton = styled.div`
	color: #fff;
	font-size: 16px;
	height: 50px;
	background: #00ace2;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 20px;
	width: 150px;
	border-radius: 5px;
	cursor: pointer;
`;
const CancelButton = styled.div`
	color: #00ace2;
	font-size: 16px;
	height: 50px;
	// background: #00ace2;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100px;
	cursor: pointer;
`;
