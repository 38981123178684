import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { baseConfig } from "../../../axiosConfig";
import { Context } from "./../../../contexts/Store";

//components
import Referance from "./Referance";
import Indication from "./../../includes/synoptic-templates/Indication";
import Composition from "../../includes/synoptic-templates/Composition";
import Findings from "./../../includes/synoptic-templates/Findings";
import ExamComparison from "../../includes/synoptic-templates/ExamComparison";
import Report from "./Report";
import AssessmentAndManagment from "../../includes/synoptic-templates/AssessmentAndManagment";

export default function SynopticTemplates() {
	const { template, report_id, synoptic_id } = useParams();
	const { state } = useContext(Context);
	const [synopticReport, setSynopticReport] = useState({});
	const [isLoading, setLoading] = useState(true);

	const getSynopticReport = () => {
		// setLoading(true);
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`/mammogram/mammogram-report/${synoptic_id}`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setSynopticReport(data);
					setLoading(false);
				} else {
					setLoading(false);
				}
			})
			.catch((error) => {});
	};

	useEffect(() => {
		getSynopticReport();
		return () => {};
	}, []);

	return (
		!isLoading && (
			<MainContainer>
				<LeftContainer>
					<SynopticContainer>
						<Div>
							<Text>BIRADS REPORTING</Text>
						</Div>
						<IndicationCard>
							<Indication
								getSynopticReport={getSynopticReport}
								synopticReport={synopticReport}
							/>
						</IndicationCard>
						<IndicationCard>
							<Composition
								getSynopticReport={getSynopticReport}
								synopticReport={synopticReport}
							/>
						</IndicationCard>
						<IndicationCard>
							<Findings
								getSynopticReport={getSynopticReport}
								synopticReport={synopticReport}
							/>
						</IndicationCard>
						<IndicationCard>
							<ExamComparison
								getSynopticReport={getSynopticReport}
								synopticReport={synopticReport}
							/>
						</IndicationCard>
						{/* <IndicationCard> */}
						<AssessmentAndManagment
							getSynopticReport={getSynopticReport}
							synopticReport={synopticReport}
						/>
						{/* </IndicationCard> */}
					</SynopticContainer>
				</LeftContainer>
				<ReportContainer>
					<Report
						synopticReport={synopticReport}
						isLoading={isLoading}
					/>
				</ReportContainer>
				<ReferenceDiv>
					<Referance />
				</ReferenceDiv>
			</MainContainer>
		)
	);
}

const MainContainer = styled.section`
	display: flex;
	background: #171617;
	height: 100vh;
	z-index: 0;
	width: 100%;
`;
const LeftContainer = styled.div`
	width: 25%;
	background: #222222;
	padding: 10px;
	overflow-y: scroll;
	::-webkit-scrollbar {
		display: none;
	}

	::-webkit-scrollbar-track {
		display: none;
	}

	::-webkit-scrollbar-thumb {
		display: none;
	}
`;
const SynopticContainer = styled.div``;
const Div = styled.div`
	background: #171617;
	padding: 10px 20px;
`;

const ReportContainer = styled.div`
	width: 58%;
	background: #171617;
`;
const ReferenceDiv = styled.div`
	width: 25%;
	background: #171617;
`;
const IndicationCard = styled.div`
	background: #171617;
	padding: 25px 20px 15px;
	margin-bottom: 5px;
`;

const Text = styled.h4`
	border-radius: 8px;
	padding: 10px 20px;
	color: #22ade5;
	background: #222222;
	text-align: center;
`;
