import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import SynopticDropDown from "../synoptic-templates/modals/SynopticDropDown";
import SynopticUnitDropDown from "../synoptic-templates/SynopticUnitDropDown";

export default function LocationField({
	choice,
	handleFindingFormValues,
	sub,
	findingFormValues,
}) {
	return (
		<>
			{choice?.field_type === "select" ? (
				<SynopticDropDown
					items={choice?.choices}
					selectedValue={findingFormValues}
					setSelectedValue={handleFindingFormValues}
					label={choice.key_name}
				/>
			) : (
				<DistanceDiv>
					{/* {choice?.field_type === "text" ? (
						<InputContainer>
							<DimensionBox
								type={"number"}
								value={findingFormValues[choice.key_name]}
								onChange={(e) => {
									handleFindingFormValues(
										choice.key_name,
										e.target.value
									);
								}}
								onWheel={(e) => e.target.blur()}
							/>
							{choice.is_local_parameters && (
								<SynopticUnitDropDown
									items={choice.local_parameters.choices}
									selectedValue={
										findingFormValues[
											choice.local_parameters.key_name
										]
									}
									setSelectedValue={handleFindingFormValues}
									label={choice.local_parameters.key_name}
								/>
							)}
						</InputContainer>
					) : null} */}
				</DistanceDiv>
			)}
			<UnitText>{choice.name}</UnitText>
		</>
	);
}

const DistanceDiv = styled.div`
	display: flex;
`;
const UnitText = styled.h6`
	color: #a3a3a3;
	font-size: 14px;
	margin-left: 10px;
	white-space: nowrap;
`;
const InputContainer = styled.div`
	display: flex;
	align-items: center;
`;

const DimensionBox = styled.input`
	padding: 0px 10px;
	width: 60px;
	background: #223035;
	border: 1px solid #30434a;
	border-radius: 5px;
	color: #fff;
	font-size: 14px;
	height: 40px;
	margin-right: 10px;
	&:after {
		content: "x";
	}
	&:focus {
		border-color: #aaa;
	}
`;
